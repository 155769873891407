import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import StyledSelect from '../../StyledSelect';
import { getDefaultValueForStyledSelect } from '../../../utils/options';

export default class Form extends React.Component {
  render() {
    const { handleSubmit, locationItem } = this.props;

    return (
      <Formik
        initialValues={{
          name: locationItem ? locationItem.name : '',
          address: locationItem ? locationItem.address : '',
          city: locationItem ? locationItem.city : '',
          province: locationItem ? locationItem.province : '',
          postalCode: locationItem ? locationItem.postalCode : '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255, 'is too long').required('is required'),
          address: Yup.string().max(255, 'is too long').required('is required'),
          city: Yup.string().max(50, 'is too long').required('is required'),
          province: Yup.string().required('is required'),
          postalCode: Yup.string().required('is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          } = props;

          const states = [
            { label: 'AB', value: 'AB' }, // Alberta
            { label: 'BC', value: 'BC' }, // British Columbia
            { label: 'MB', value: 'MB' }, // Manitoba
            { label: 'NB', value: 'NB' }, // New Brunswick
            { label: 'NL', value: 'NL' }, // Newfoundland and Labrador
            { label: 'NS', value: 'NS' }, // Nova Scotia
            { label: 'ON', value: 'ON' }, // Ontario
            { label: 'PE', value: 'PE' }, // Prince Edward Island
            { label: 'QC', value: 'QC' }, // Quebec
            { label: 'SK', value: 'SK' }, // Saskatchewan
            { label: 'NT', value: 'NT' }, // Northwest Territories
            { label: 'NU', value: 'NU' }, // Nunavut
            { label: 'YT', value: 'YT' }, // Yukon
          ];

          return (
            <form id="signup-form" noValidate="" onSubmit={handleSubmit}>
              <div
                className={
                  errors.name && touched.name
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="name">Location name</label>
                <input
                  id="name"
                  value={values.name}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <Error className="form-error-message">
                    Location name {errors.name}
                  </Error>
                )}
              </div>

              <div
                className={
                  errors.address && touched.address
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="address">Address</label>
                <input
                  id="address"
                  value={values.address}
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                    // handleRemoveError('address');
                  }}
                  onBlur={handleBlur}
                />
                {errors.address && touched.address && (
                  <Error className="form-error-message">
                    Address {errors.address}
                  </Error>
                )}
              </div>

              <div className="form-row flex">
                <div className="city">
                  <label htmlFor="city">City</label>
                  <input
                    className="city"
                    id="city"
                    value={values.city}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.city && touched.city && (
                    <Error className="form-error-message">
                      City {errors.city}
                    </Error>
                  )}
                </div>

                <div className="province">
                  <label htmlFor="province">Province</label>
                  <StyledSelect
                    value={getDefaultValueForStyledSelect(values.province)}
                    id="province"
                    name="province"
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    options={states}
                    field="name"
                    menuPosition="fixed"
                  />
                  {errors.province && touched.province && (
                    <Error className="form-error-message">
                      Province {errors.province}
                    </Error>
                  )}
                </div>
                <div className="postal-code">
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    defaultValue={values.postalCode}
                    type="text"
                    name="postalCode"
                    maxLength="6"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.postalCode && touched.postalCode && (
                    <Error className="form-error-message">
                      Postal Code {errors.postalCode}
                    </Error>
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const Error = styled.div`
  color: var(--alert);
`;
