import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import StyledMultiSelect from '../../components/StyledMultiSelect';
import { getOptionsLocations } from '../../utils/getOptionsLocations';
import { isEqual } from 'lodash';
import { ReactSVG } from 'react-svg';
import PlusGrey from '../../assets/svgs/plus-grey.svg';

const Menu = (props) => {
  return <div {...props} />;
};
const Blanket = (props) => <div {...props} />;
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default class LocationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      assignedLocations: null,
      options: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    const { locations, listAllLocations } = this.props;
    if (locations) {
      const fromatLocations = listAllLocations.filter(({ id }) =>
        locations.includes(id),
      );
      this.setState({
        assignedLocations: getOptionsLocations(fromatLocations),
      });
    }

    if (listAllLocations && locations) {
      let options = [];
      if (listAllLocations.length) {
        options = getOptionsLocations(listAllLocations);
      }

      if (locations.length && !listAllLocations.length) options = locations;

      let onlyValuesAssign = locations.map(({ id }) => id);
      this.setState({
        options: options.sort(
          (a, b) =>
            onlyValuesAssign.indexOf(b.value) -
            onlyValuesAssign.indexOf(a.value),
        ),
      });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { locations, listAllLocations } = this.props;

    if (prevProps.locations !== locations && locations) {
      const fromatLocations = listAllLocations.filter(({ id }) =>
        locations.includes(id),
      );
      this.setState({
        assignedLocations: getOptionsLocations(fromatLocations),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { listAllLocations, handleSetlocations } = this.props;
    const { assignedLocations, isOpen } = this.state;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && isOpen) {
      this.setState({ isOpen: false });
      if (!isEqual(listAllLocations, assignedLocations)) {
        const locationsIds = assignedLocations.map(({ value }) => value);
        handleSetlocations(locationsIds);
      }
    }
  }

  handleDeleteById = (id) => {
    const assignedLocations = [...this.state.assignedLocations];
    const formatLocations = assignedLocations.map(({ value }) => value);
    const index = formatLocations.indexOf(id);

    if (index !== -1) {
      assignedLocations.splice(index, 1);
      this.setState({ assignedLocations: assignedLocations });
    }
  };

  toggleOpen = () => {
    const { listAllLocations } = this.props;
    const { assignedLocations } = this.state;
    if (listAllLocations.length) {
      let onlyValuesAssign = assignedLocations;

      this.setState({
        options: getOptionsLocations(listAllLocations).sort(function (a, b) {
          return (
            onlyValuesAssign.indexOf(b.value) -
            onlyValuesAssign.indexOf(a.value)
          );
        }),
      });
    }
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  onSelectChange = (value) => {
    const onlyValues = value.map((value) => value);
    this.setState({
      assignedLocations: onlyValues,
    });
  };

  render() {
    const { listAllLocations, isLoading } = this.props;
    const { isOpen, assignedLocations, options } = this.state;

    const locationSelected =
      assignedLocations === null
        ? null
        : listAllLocations.find(
            (location) => location.id === assignedLocations,
          );

    return (
      <Wrap ref={this.setWrapperRef}>
        <Dropdown
          isOpen={isOpen}
          onClose={this.toggleOpen}
          target={
            <Button
              style={{ backgroundColor: 'transparent', height: 'auto' }}
              onClick={this.toggleOpen}
              isSelected={isOpen}
            >
              <div>
                <WrapperLocations locationSelected={locationSelected}>
                  {/*<Avatar>*/}
                  {/*  {locationSelected &&*/}
                  {/*    getInitials({*/}
                  {/*      name: locationSelected.name + ' ' + locationSelected.lastName,*/}
                  {/*    })}*/}
                  {/*</Avatar>*/}
                  <AddLocation id="add">
                    <Circle>
                      <ReactSVG src={PlusGrey} />
                    </Circle>
                    <div>Add location</div>
                  </AddLocation>
                  <div>{locationSelected && locationSelected.name}</div>
                  <span className="address">
                    {locationSelected && locationSelected.address}
                  </span>
                </WrapperLocations>
              </div>
            </Button>
          }
        >
          <StyledMultiSelect
            id="eventTypeName"
            name="eventTypeName"
            onChange={this.onSelectChange}
            isLoading={isLoading}
            data={options}
            value={assignedLocations}
            // menuPortalTarget={document.body.querySelector('#add')}
          />
        </Dropdown>
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  width: 100%;
`;

const WrapperLocations = styled.div`
  display: block;
  font-family: Roboto;
  font-size: 18px;
  text-align: initial;
  line-height: ${({ locationSelected }) =>
    locationSelected ? '19px' : 'inherit'};
  color: ${({ locationSelected }) =>
    locationSelected ? '#333333' : '#61933a'};
  .address {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    color: #999999;
  }
  .location {
    display: flex;
  }
  font-weight: ${({ locationSelected }) =>
    locationSelected ? 'bold' : 'inherit'};
`;

const AddLocation = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #777777;
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: 32px;
  height: 32px;
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
`;
