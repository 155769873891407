import React from 'react';
import styled from 'styled-components';
import { getInitials } from '../../utils/initials';
import COVIDQuestionnairePopup from '../../components/Popups/COVIDQuestionnairePopup';
import COVIDUnlockPopup from '../../components/Popups/COVIDUnlockPopup';

export default class UserCard extends React.Component {
  state = {
    openPopup: false,
    openUnlockPopup: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.openUnlockPopup && !this.state.openUnlockPopup) {
      this.props.getUsers(true);
    }
  }

  handleOpenPopup = () => {
    this.setState(({ openPopup }) => ({ openPopup: !openPopup }));
  };

  handleOpenUnlockPopup = () => {
    this.setState(({ openUnlockPopup }) => ({
      openUnlockPopup: !openUnlockPopup,
    }));
  };
  render() {
    const { user, getUsers } = this.props;
    const { openPopup, openUnlockPopup } = this.state;
    return (
      <>
        <Card className="card" onClick={this.handleOpenPopup}>
          <div>
            <div className="worker">
              <div className="avatar" style={{ background: user.color }}>
                {getInitials({
                  name: user.name + ' ' + user.lastName,
                })}
              </div>
              <span>{user.name + ' ' + user.lastName}</span>
            </div>
            <span className="alert">Potential COVID risk</span>
          </div>
          <svg
            className="ml-auto"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.34779 7.04327V3.94573C2.34779 1.76664 4.27446 0 6.65226 0C9.02932 0 10.9567 1.76816 10.9567 3.94573V7.04327H11.8298C12.6442 7.04327 13.3045 7.7454 13.3045 8.60746V16.4358C13.3045 17.2994 12.6442 18 11.8298 18H1.47469C0.66032 18 0 17.2979 0 16.4358V8.60746C0 7.74391 0.660301 7.04327 1.47469 7.04327H2.34779ZM1.56569 8.60892V16.4343H11.7388V8.60892H1.56569ZM9.39104 7.04327V3.94573C9.39104 2.66401 8.19295 1.56567 6.65224 1.56567C5.11007 1.56567 3.91345 2.66251 3.91345 3.94573V7.04327H9.39104ZM5.86941 11.5701C5.86941 11.138 6.21718 10.788 6.65224 10.788C7.08438 10.788 7.43508 11.135 7.43508 11.5701V13.9186C7.43508 14.3508 7.08731 14.7007 6.65224 14.7007C6.22011 14.7007 5.86941 14.3537 5.86941 13.9186V11.5701Z"
              fill="#CD4343"
            />
          </svg>
        </Card>
        {openPopup && (
          <COVIDQuestionnairePopup
            user={user}
            getUsers={getUsers}
            onClose={this.handleOpenPopup}
            handleOpenUnlockPopup={this.handleOpenUnlockPopup}
          />
        )}
        {openUnlockPopup && (
          <COVIDUnlockPopup user={user} onClose={this.handleOpenUnlockPopup} />
        )}
      </>
    );
  }
}

const Card = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  align-items: flex-start;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  border: 1px solid #f1f1f1;
  display: flex;
  & > div {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    flex-direction: column;
  }
  & > div:nth-child(2) {
    align-items: flex-end;
  }
`;
