import { connect } from 'react-redux';
import EquipmentPage from './EquipmentPage';
import {
  getEquipment,
  getEquipmentTypes,
  addEquipment,
  deleteEquipment,
  updateEquipment,
} from '../../store/equipmentStore/actions';

const mapStateToProps = ({
  equipmentStore: { listEquipment, isLoading, equipmentTypes },
}) => ({
  listEquipment,
  isLoading,
  equipmentTypes,
});

const mapDispatchToProps = {
  getEquipment,
  getEquipmentTypes,
  addEquipment,
  deleteEquipment,
  updateEquipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentPage);
