import { connect } from 'react-redux';
import UsersPage from './UsersPage';
import {
  getUsers,
  deleteUsers,
  updateUser,
  addUser,
  restoreUser,
  cleanUser,
} from '../../store/usersStore/actions';
import {
  getWorkersGroup,
  addWorkerGroup,
  updateWorkerGroup,
  deleteWorkerGroup,
} from '../../store/workerGroup/actions';

const mapStateToProps = ({
  usersStore: {
    listUsers,
    isLoading,
    isUserDeleted,
    isUserUpdated,
    isUserAdded,
    isUserRestored,
    error,
  },
  workerGroup: { workersGroups, isLoadingDeleted },
}) => ({
  listUsers,
  isLoading,
  isUserDeleted,
  isUserUpdated,
  isUserAdded,
  isUserRestored,
  error,
  workersGroups,
  isLoadingDeleted,
});

const mapDispatchToProps = {
  getUsers,
  deleteUsers,
  updateUser,
  addUser,
  restoreUser,
  cleanUser,
  getWorkersGroup,
  addWorkerGroup,
  updateWorkerGroup,
  deleteWorkerGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
