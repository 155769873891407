import { connect } from 'react-redux';
import {
  deleteJob,
  updateJob,
  createJob,
  joinJobImg,
  updateNoteOnJob,
} from '../../store/jobsStore/actions';
import {
  getJobsReport,
  getShiftsReport,
} from '../../store/reportsStore/actions';
import {
  getAllLocations,
  getLocationById,
} from '../../store/locationsStore/actions';
import { getUsers } from '../../store/usersStore/actions';
import JobsListAdminPage from './JobsListAdminPage';

const mapStateToProps = ({
  jobsStore: { isJobChanged, isJobCreated },
  locationsStore: { listAllLocations, location },
  usersStore: { listUsers },
  reportsStore: { isLoading, reportJob, reportShift },
}) => ({
  jobByDate: reportJob,
  isLoading,
  isJobChanged,
  listAllLocations,
  listUsers,
  isJobCreated,
  location,
  reportShift,
});

const mapDispatchToProps = {
  getJobsReport,
  getUsers,
  deleteJob,
  createJob,
  updateJob,
  getAllLocations,
  joinJobImg,
  updateNoteOnJob,
  getLocationById,
  getShiftsReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsListAdminPage);
