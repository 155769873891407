import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ user, roles, location, ...rest }) {
  if (!user) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: { pathname: '/' } } }}
      />
    );
  }

  if (roles && roles.length > 0 && !roles.includes(user.role)) {
    return <Redirect to={{ pathname: '/403', state: { from: location } }} />;
  }

  return <Route location={location} {...rest} />;
}

const mapStateToProps = ({ dataStore: { user } }) => ({ user });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
