import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Form from './Form';
import Spinner from '../../components/Spinner';
import ResetPopup from '../../components/Popups/ResetPopup';
import LocationsTab from './LocationsTab';
import validateEmail from '../../utils/validateEmail';
import ExistsPopup from '../../components/Popups/ExistsPopup';
import { getResponseErrors } from '../../utils/response';
import { getErrorsMessageUser } from '../../utils/template';
import StyledAlertMessage from '../../components/StyledAlertMessage';

function deleteEmptyProperties(obj) {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
}

export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class AddUserPage extends React.Component {
  state = {
    popup: false,
    popupExists: false,
    tab: 1,
    locationItems: [],
    formValues: {},
    save: false,
    isOpenWarning: false,
    errorsForm: {},
    showAll: true,
  };
  submitMyForm = null;

  componentDidMount() {
    const {
      getUserById,
      getAllLocations,
      match: { params },
    } = this.props;
    document.body.classList.remove('with-nav');

    if (params.id) {
      getUserById(params.id);
    }
    getAllLocations();
    this.myRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      isUserAdded,
      userItem,
      isUserUpdated,
      isUserRestored,
      history,
      assignLocation,
    } = this.props;
    if (
      (!prevProps.isUserAdded && isUserAdded) ||
      (!prevProps.isUserUpdated && isUserUpdated) ||
      (!prevProps.isUserRestored && isUserRestored)
    ) {
      history.goBack();
      const locationItems = this.getLocationItems();

      assignLocation({
        locationsIds: locationItems.map(({ id }) => id),
        userId: +userItem.userId,
      });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.msg === 'USER IS ARCHIVED'
    ) {
      this.setState({ popup: true });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.msg === 'USER IS EXIST'
    ) {
      this.setState({ popupExists: true });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.errors?.length
    ) {
      const errors = getResponseErrors(this.props.error);
      this.setState({ isLoading: false });
      StyledAlertMessage.error(getErrorsMessageUser(errors));
    }

    if (prevProps.userItem !== userItem && userItem && !userItem.msg) {
      this.setState({
        locationItems: userItem.locations,
        showAll: userItem.seesAllLocations,
      });
    }
  }

  handleLocation = (locations) => {
    const { listAllLocations } = this.props;
    const prevOrder = this.state.locationItems.map(({ id }) => id);

    this.setState({
      locationItems: listAllLocations
        .filter(({ id }) => locations.includes(id))
        .sort(function (a, b) {
          return prevOrder.indexOf(a.id) - prevOrder.indexOf(b.id);
        }),
    });
  };

  handleShowAll = (event) => {
    this.setState({
      showAll: event.target.checked,
    });
  };

  removeLocation = (idDel) => {
    const locations = [...this.state.locationItems];
    const index = locations.findIndex((location) => location.id === idDel);
    locations.splice(index, 1);
    this.setState({ locationItems: locations }, () => {
      this.myRef.current.handleDeleteById(idDel);
    });
  };

  handleChangeTab = (number) => {
    this.setState({
      tab: number,
    });
  };

  handlePopup = () => {
    this.setState({ popup: !this.state.popup });
  };

  handlePopupExists = () => {
    this.setState({ popupExists: !this.state.popupExists });
  };

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  componentWillUnmount() {
    this.props.cleanUser();
  }

  handleWarningPopup = () => {
    this.setState((prevState) => ({
      isOpenWarning: !prevState.isOpenWarning,
    }));
  };

  handleSubmit = (values) => {
    const {
      userItem,
      location: { pathname },
    } = this.props;
    const { popup, save } = this.state;

    if (save) {
      let nameCheck = values.name?.trim();
      if (!nameCheck) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: { ...this.state.errorsForm, name: 'is required' },
        });
      } else if (nameCheck.length > 255) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: { ...this.state.errorsForm, name: 'is too long' },
        });
      } else if (nameCheck.length < 3) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            name: 'must be at least 3 characters',
          },
        });
      }
      if (!values.email) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            email: 'is required',
          },
        });
      } else if (!validateEmail(values.email)) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            email: 'is invalid',
          },
        });
      }
      if (!values.role) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            role: 'is required',
          },
        });
      }

      if (pathname.includes('edit') && values.password && values.password < 8) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            password: 'must be at least 8 characters',
          },
        });
      } else if (!pathname.includes('edit') && !values.password) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            password: 'is required',
          },
        });
      } else if (values.password < 8) {
        this.setState({
          save: false,
          isOpenWarning: true,
          errorsForm: {
            ...this.state.errorsForm,
            password: 'must be at least 8 characters',
          },
        });
      }
    }

    if (userItem && userItem.userId) {
      const allValues = { ...values, id: +userItem.userId };

      if (allValues.password === '') {
        delete allValues.password;
      }

      this.setState({
        formValues: deleteEmptyProperties(allValues),
      });

      // updateUser(deleteEmptyProperties(allValues));
    } else {
      const allValues = deleteEmptyProperties(values);

      this.setState({
        formValues: allValues,
      });

      if (popup) {
        this.setState((state) => ({
          formValues: { ...state.formValues, force: true },
        }));
      }
      // addUser(allValues);
    }
    if (save) {
      this.saveUser();
    }
  };

  saveUser = () => {
    const { userItem, addUser, updateUser } = this.props;
    const { formValues, isOpenWarning, showAll } = this.state;
    if (!isOpenWarning) {
      if (userItem && userItem.userId) {
        updateUser({ ...formValues, seesAllLocations: showAll });
      } else {
        addUser({ ...formValues, seesAllLocations: showAll });
      }
    } else {
      this.setState({ tab: 1, isOpenWarning: false });
    }
    this.setState({ save: false });
  };

  handleRemoveError = (name) => {
    this.setState({
      errorsForm: {
        ...this.state.errorsForm,
        [name]: null,
      },
    });
  };

  onDragEnd = (result) => {
    const { locationItems } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === 'QUESTIONS') {
      const locationItemsNew = Reorder(
        locationItems,
        result.source.index,
        result.destination.index,
      );

      this.setState({
        locationItems: locationItemsNew,
      });
    }
  };

  getLocationItems = () => {
    return this.state.locationItems;
  };

  render() {
    const {
      history,
      userItem,
      addUser,
      updateUser,
      restoreUser,
      isUserLoading,
      assignLocation,
      error,
      location: { pathname },
      listAllLocations,
      isLoading,
    } = this.props;
    const {
      popup,
      tab,
      formValues,
      errorsForm,
      popupExists,
      showAll,
    } = this.state;

    return (
      <Wrapper>
        <Header>
          <Button onClick={() => history.goBack()}>CANCEL</Button>
          {pathname.includes('edit') ? (
            <h2>Edit Worker</h2>
          ) : (
            <h2>Add Worker</h2>
          )}
          <Button
            onClick={(event) => {
              this.handleSubmitMyForm(event);
              this.setState({ save: true });
            }}
          >
            SAVE
          </Button>
        </Header>
        <div className="tabs">
          <div
            className={tab === 1 && 'active'}
            onClick={() => this.handleChangeTab(1)}
          >
            Details
          </div>
          <div
            className={tab === 2 && 'active'}
            onClick={(event) => {
              this.handleSubmitMyForm(event);
              this.handleChangeTab(2);
            }}
          >
            Locations
          </div>
        </div>
        {tab === 1 && (
          <>
            {isUserLoading ? (
              <Spinner />
            ) : (
              <WrapperForm>
                <Form
                  errorsForm={errorsForm}
                  formValues={formValues}
                  handleSubmit={this.handleSubmit}
                  // locations={locations}
                  assignLocation={assignLocation}
                  pathname={pathname}
                  user={userItem}
                  updateUser={updateUser}
                  addUser={addUser}
                  open={popup}
                  handleRemoveError={this.handleRemoveError}
                  bindSubmitForm={this.bindSubmitForm}
                />
              </WrapperForm>
            )}
          </>
        )}

        {tab === 2 && (
          <>
            {isUserLoading || isLoading ? (
              <Spinner />
            ) : (
              <LocationsTab
                onDragEnd={this.onDragEnd}
                getLocationItems={this.getLocationItems}
                showAll={showAll}
                handleShowAll={this.handleShowAll}
                myRef={this.myRef}
                removeLocation={this.removeLocation}
                handleLocation={this.handleLocation}
                listAllLocations={listAllLocations}
                assignedLocations={userItem ? userItem.locations : []}
              />
            )}
          </>
        )}

        {popup && (
          <ResetPopup
            error={error}
            createNew={this.handleSubmitMyForm}
            restoreUser={restoreUser}
            onClose={this.handlePopup}
          />
        )}

        {popupExists && <ExistsPopup onClose={this.handlePopupExists} />}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .tabs {
    width: 100%;
    display: flex;
    font-size: 18px;
    font-family: 'Montserrat', sans-sans-serif;
    margin: 20px 0 30px;
    color: #777;
    font-weight: 700;
    justify-content: center;
    position: relative;
  }
  .tabs > div:first-child {
    margin-right: 60px;
  }
  .tabs .active {
    color: #333;
  }
  .tabs .active::after {
    content: '';
    height: 2px;
    width: 100%;
    background: #61933a;
    display: block;
    margin-top: 2px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 0px #eeeeee;
  padding: 14px 30px;
  margin-bottom: 30px;

  h2 {
    margin: 0;
  }
`;

const Button = styled.button`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #61933a;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const WrapperForm = styled.div`
  min-height: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 0 30px 30px 30px;
  padding: 20px;
  label {
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
  }
  .two {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 48.7%;
    }
  }
  .three {
    display: flex;
    justify-content: space-between;

    & > div:nth-child(1) {
      width: 45%;
    }
    & > div:nth-child(2) {
      width: 20%;
    }
    & > div:nth-child(3) {
      width: 30%;
    }
  }
`;

export default withRouter(AddUserPage);
