/* eslint-disable */
import React, {useEffect, useRef} from "react";
import styled from 'styled-components';
import {useFormik} from "formik";
import * as yup from 'yup';

import Modal from "../../components/Modal";
import {ReactSVG} from "react-svg";
import CloseSVG from "../../assets/images/close.svg";

const validationSchema = yup.object().shape({
    city: yup
        .string()
        .matches(/([A-Za-z]+(?: [A-Za-z]+)*)/, 'Please enter a valid location')
        .max(100, 'Location name is too long')
        .required('This field is required'),
});

const AddCityModal = ({handleAddCity, onClose, isLoading, error}) => {
    const prevLoadingRef = useRef(isLoading);
    const formik = useFormik({
        validationSchema,
        initialValues: {city: ''},
        onSubmit: (values) => {
            handleAddCity({name: values.city})
        }
    })

    useEffect(() => {
        if (prevLoadingRef.current === true && isLoading === false) {
            if (error) {
                const errorMessage = error.err.message.toLowerCase().includes('exist') ? 'Location already exists' : 'No results found for your request'
                formik.setFieldError('city', errorMessage)
            } else {
                onClose();
            }
        }
    },[isLoading, error])

    useEffect(() => {
        prevLoadingRef.current = isLoading;
    }, [isLoading])

    return (
        <Modal>
            <div className="modal-blur"/>
            <div className="modal-overlay">
                <Wrapper id="add-location-modal" className="modal">
                    <ModalHeader>
                        <div className="modal-title">Add Location</div>
                        <CloseButton>
                            <ReactSVG src={CloseSVG} onClick={onClose}/>
                        </CloseButton>
                    </ModalHeader>
                    <Form onSubmit={formik.handleSubmit}>
                        <FormItem>
                            <StyledLabel htmlFor="city">Location</StyledLabel>
                            <StyledInput
                                id="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.errors.city && formik.touched.city}
                                helperText={formik.touched.city && formik.errors.city}
                                type="text"
                                placeholder="ie. Toronto, CA"
                            />
                        </FormItem>
                        <ErrorText>{formik.touched.city && formik.errors.city}</ErrorText>
                    </Form>
                    <AddCityButton onClick={formik.handleSubmit}>ADD</AddCityButton>
                </Wrapper>
            </div>
        </Modal>
    )
}

const Wrapper = styled.div`
  #province {
    width: 120px;
  }

  .modal-body {
    overflow: visible !important;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
`

const CloseButton = styled.button`
  background-color: #ffffff;
  border: none;
  cursor: pointer;
`

const Form = styled.form`
  padding: 0 30px;
  margin-bottom: 20px;
`

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

const StyledLabel = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #777777;
`

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;

  &:focus {
    border: 2px solid #54A01D;
    outline: none;
  }

  &::placeholder {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #BCBCBC;
  }
`

const ErrorText = styled.p`
  width: 100%;
  text-align: left;
  color: #777777;
`

const AddCityButton = styled.button`
  width: 68px;
  height: 40px;
  border-radius: 5px;
  border: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  background-color: #54A01D;
  cursor: pointer;
`

export default AddCityModal;
