import { connect } from 'react-redux';
import ReportsPage from './ReportsPage';
import { getCities } from '../../store/reportsStore/actions';
import {
  getAllLocations,
  getLocationsTasks,
} from '../../store/locationsStore/actions';
import { getUsers } from '../../store/usersStore/actions';

const mapStateToProps = ({
  reportsStore: { isLoading, listCities, reportWeather },
  locationsStore: { listAllLocations, listTasks },
  usersStore: { listUsers },
}) => ({
  isLoading,
  listCities,
  reportWeather,
  listAllLocations,
  listUsers,
  listTasks,
});

const mapDispatchToProps = {
  getCities,
  getAllLocations,
  getLocationsTasks,
  getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
