import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.GET_LIST_LOCATIONS_REQUEST, handleRequest),
  takeEvery(types.GET_ALL_LOCATIONS_REQUEST, handleRequest),
  takeEvery(types.GET_LOCATION_REQUEST, handleRequest),
  takeEvery(types.DELETE_LOCATION_REQUEST, handleRequest),
  takeEvery(types.ADD_LOCATION_REQUEST, handleRequest),
  takeEvery(types.UPDATE_LOCATION_REQUEST, handleRequest),
  takeEvery(types.ASSIGN_LOCATION_REQUEST, handleRequest),
  takeEvery(types.ADD_COMMENT_TO_LOCATION_REQUEST, handleRequest),
  takeEvery(types.JOIN_LOCATION_TO_IMG_REQUEST, handleRequest),
  takeEvery(types.GET_LIST_TASKS_ON_LOCATIONS_REQUEST, handleRequest),
  takeEvery(types.ADD_TASK_CATEGORY_REQUEST, handleRequest),
  takeEvery(types.GET_TASK_PLACE_REQUEST, handleRequest),
  takeEvery(types.DELETE_TASK_PLACE_REQUEST, handleRequest),
  takeEvery(types.UPDATE_TASK_REQUEST, handleRequest),
  takeEvery(types.UPDATE_TASK_PLACE_REQUEST, handleRequest),
  takeEvery(types.DELETE_TASK_REQUEST, handleRequest),
  takeEvery(types.ADD_TASK_REQUEST, handleRequest),
]);
