import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import StyledSelect from '../../components/StyledSelect';
import {
  getDefaultValueForLocationsSelect,
  getDefaultValueForUsersSelect,
} from '../../utils/options';
const ROADS_DEFAULT = 'Roads';
const SIDEWALKS_DEFAULT = 'Sidewalks';
const OTHER_DEFAULT = 'Other';

class TimeBlock extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    locations: [],
    tasks: [],
    users: [],
  };

  handleChangeStartTime = (event) => {
    if (!event || !event.target || !event.target.value) return;
    this.setState({ error: null });

    this.setState({
      startDate: event.target.value,
    });
  };

  handleChangeEndTime = (event) => {
    if (!event || !event.target || !event.target.value) return;
    this.setState({ error: null });

    this.setState({
      endDate: event.target.value,
    });
  };

  handleLocation = (values) => {
    this.setState({ locations: values });
  };

  handleUser = (values) => {
    this.setState({ users: values });
  };

  handleTasks = (values) => {
    this.setState({ tasks: values });
  };

  handleGetReport = (e) => {
    const { history } = this.props;
    const { startDate, endDate, locations, tasks, users } = this.state;
    e.preventDefault();
    let locationIds = [];
    if (locations.length) {
      locationIds = locations.map((location) => location.value).join();
    } else {
      locationIds = 'all';
    }

    let taskIds = [];
    if (tasks.length) {
      taskIds = tasks.map((task) => task.value).join();
    } else {
      taskIds = 'all';
    }

    let userIds = [];
    if (users.length) {
      userIds = users.map((user) => user.value).join();
    } else {
      userIds = 'all';
    }

    let endFormatDate = null;
    if (endDate) {
      endFormatDate = new Date(endDate);
      endFormatDate.setMinutes(
        endFormatDate.getMinutes() + endFormatDate.getTimezoneOffset(),
      );
      endFormatDate.setDate(endFormatDate.getDate() + 1);
      endFormatDate = endFormatDate.toISOString();
    }

    let startFormatDate = new Date(startDate);
    startFormatDate.setMinutes(
      startFormatDate.getMinutes() + startFormatDate.getTimezoneOffset(),
    );
    startFormatDate = startFormatDate.toISOString();

    history.push(
      `/reports/time/${startDate ? startFormatDate : 'null'}/${
        endFormatDate ? endFormatDate : 'null'
      }/${locationIds}/${taskIds}/${userIds}`,
    );
  };

  render() {
    const { listAllLocations, listUsers, listTasks } = this.props;
    const { startDate, endDate } = this.state;
    let rodTasks = [];
    let sidewalkTasks = [];
    let otherTasks = [];
    let summerTasks = [];
    if (listTasks.length) {
      rodTasks = listTasks.filter(
        (task) => task?.place?.name === ROADS_DEFAULT,
      );
      sidewalkTasks = listTasks.filter(
        (task) => task?.place?.name === SIDEWALKS_DEFAULT,
      );
      otherTasks = listTasks
        .filter((task) => task?.place?.name === OTHER_DEFAULT)
        .sort((taskOne, taskTwo) => taskOne.id - taskTwo.id);
      summerTasks = listTasks.filter(
        (task) =>
          task?.place?.name !== ROADS_DEFAULT &&
          task?.place?.name !== SIDEWALKS_DEFAULT &&
          task?.place?.name !== OTHER_DEFAULT,
      );
    }

    const roadsNames = rodTasks.map((task) => {
      return {
        label: 'Winter: ' + ROADS_DEFAULT + ' ' + task.name,
        value: task.id,
      };
    });

    const sidewalksNames = sidewalkTasks.map((task) => {
      return {
        label: 'Winter: ' + SIDEWALKS_DEFAULT + ' ' + task.name,
        value: task.id,
      };
    });

    const othersNames = otherTasks.map((task) => {
      return {
        label: 'Winter: ' + OTHER_DEFAULT + ' ' + task.name,
        value: task.id,
      };
    });

    const allNames = listTasks.map((task) => {
      return {
        label: task.name,
        value: task.id,
      };
    });

    const arrayTasks = allNames;

    const dayLimit = new Date().toISOString().slice(0, 10);

    const optionsLocationsArray = listAllLocations.map((location) =>
      getDefaultValueForLocationsSelect(location),
    );

    const optionsUsersArray = listUsers.map((location) =>
      getDefaultValueForUsersSelect(location),
    );

    return (
      <Wrapper>
        <div className="card">
          <form>
            <div className="form-row flex">
              <div className="one-half">
                <label htmlFor="">Start Date</label>

                <input
                  type="date"
                  id="start-time"
                  defaultValue={startDate}
                  max={dayLimit}
                  onInput={this.handleChangeStartTime}
                />
              </div>
              <div className="one-half">
                <label htmlFor="">End Date</label>
                <input
                  type="date"
                  id="end-time"
                  defaultValue={endDate}
                  max={dayLimit}
                  min={startDate}
                  onInput={this.handleChangeEndTime}
                />
              </div>
            </div>
            <div className="form-row">
              <label htmlFor="">Locations</label>
              <StyledSelect
                id="role"
                name="location"
                setFieldValue={(e) => {
                  this.handleLocation(e);
                  this.setState({ error: null });
                }}
                isMulti={true}
                options={optionsLocationsArray}
                field="name"
                placeholder="All locations"
              />
            </div>
            <div className="form-row">
              <label htmlFor="">Tasks</label>
              <StyledSelect
                id="role"
                name="task"
                setFieldValue={(e) => {
                  this.handleTasks(e);
                  this.setState({ error: null });
                }}
                isMulti={true}
                options={arrayTasks}
                field="name"
                placeholder="All tasks"
              />
            </div>
            <div className="form-row">
              <label htmlFor="">Workers</label>
              <StyledSelect
                id="role"
                name="user"
                setFieldValue={(e) => {
                  this.handleUser(e);
                  this.setState({ error: null });
                }}
                isMulti={true}
                options={optionsUsersArray}
                field="name"
                placeholder="All workers"
              />
            </div>
            <div className="form-row">
              <button className="alt center" onClick={this.handleGetReport}>
                RUN REPORT
              </button>
            </div>
          </form>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .card {
    padding: 20px 20px 10px;
    margin-bottom: 15px;
    position: relative;
  }
  label {
    color: #333;
  }
  @media (min-width: 960px) {
    .card {
      padding: 30px 30px 10px;
    }
  }
`;

export default withRouter(TimeBlock);
