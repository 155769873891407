import React from 'react';
import { ReactSVG } from 'react-svg';

import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import CloseSVG from '../../../assets/images/close.svg';

const AddLocationPopup = ({ open, onClose, onDelete, id }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <Wrapper id="add-location-modal" className="modal">
        <div className="modal-header">
          <div className="modal-title">Delete Location?</div>
          <div className="modal-close" onClick={onClose}>
            <ReactSVG src={CloseSVG} />
          </div>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete this location?</p>
        </div>
        <div className="cta-bar">
          <button className="btn alert" onClick={() => onDelete(id)}>
            Delete
          </button>
        </div>
      </Wrapper>
    </StyledDialog>
  );
};
const Wrapper = styled.div`
  #province {
    width: 120px;
  }
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default AddLocationPopup;
