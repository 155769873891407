import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  listCities: [],
  creationError: null,
  isLoadingCreation: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_CITIES_REQUEST:
      return { ...state, listCities: [], isLoading: true };
    case types.GET_LIST_CITIES_SUCCESS:
      return {
        ...state,
        listCities: payload, //.data
        isCityAdded: false,
        isLoading: false,
      };
    case types.GET_LIST_CITIES_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.ADD_CITY_REQUEST:
      return {
        ...state,
        isLoadingCreation: true,
        creationError: null,
      };
    case types.ADD_CITY_SUCCESS:
      return {
        ...state,
        isLoadingCreation: false,
      };
    case types.ADD_CITY_FAIL:
      return {
        ...state,
        isLoadingCreation: false,
        creationError: payload,
      };

    case types.DELETE_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.DELETE_CITY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};
