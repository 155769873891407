import { connect } from 'react-redux';
import DashboardPage from './DashboardPage';
import { getUsers } from '../../store/usersStore/actions';

const mapStateToProps = ({ usersStore: { isLoading, listUsers } }) => ({
  isLoading,
  listUsers,
});

const mapDispatchToProps = { getUsers };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
