import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';

const AddCategoryPopup = (props) => {
  const { onClose, addCategory, category, updateTaskPlace } = props;
  const [categoryName, setCategoryName] = useState(category?.name || '');
  const [season, setSeason] = useState(category?.season?.seasonId || 1);
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <Wrapper id="add-location-modal" className="modal">
          <div className="modal-header">
            <div className="modal-title">
              {category ? 'Edit' : 'Add'} Task Category
            </div>
            <div className="modal-close" onClick={onClose}>
              <ReactSVG src={CloseSVG} />
            </div>
          </div>
          <div className="modal-body left-align">
            <div className="form-row flex">
              <label htmlFor="category">Task Category Name</label>
              <input
                defaultValue={categoryName}
                id="category"
                type="category"
                onInput={(e) => setCategoryName(e.target.value)}
              />
            </div>

            <WrapAllR>
              <WrapRadio>
                <input
                  type="radio"
                  name="1"
                  value={1}
                  checked={1 === season}
                  onChange={() => setSeason(1)}
                />
                Winter Task
              </WrapRadio>{' '}
              <WrapRadio>
                <input
                  type="radio"
                  name="2"
                  value={2}
                  checked={2 === season}
                  onChange={() => setSeason(2)}
                />{' '}
                Summer Task
              </WrapRadio>
            </WrapAllR>
          </div>
          <div className="cta-bar">
            <button
              className="btn"
              onClick={() => {
                !category
                  ? addCategory({ name: categoryName, seasonId: season })
                  : updateTaskPlace(category.id, {
                      name: categoryName,
                      seasonId: season,
                    });
              }}
            >
              {category ? 'Save' : 'Add'}
            </button>
          </div>
        </Wrapper>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div``;
const WrapRadio = styled.div`
  margin-right: 50px;
  input {
    margin-right: 15px;
  }
`;
const WrapAllR = styled.div`
  display: flex;
`;

export default AddCategoryPopup;
