import { getInitials } from './initials';
import React from 'react';
import styled from 'styled-components';
import { ROLE_MASTER } from './users';

export const getOptionsTeamMembers = (arr) => {
  if (arr.length > 0) {
    return arr
      .filter((item) => Boolean(item.role !== ROLE_MASTER))
      .map(({ id: value, name, lastName, color, WorkersGroupUserAssign }) => ({
        value,
        label: (
          <Wrap>
            <Avatar color={color} className="select-avatar">
              {getInitials({ name: name + ' ' + lastName })}
            </Avatar>{' '}
            <div>{name + ' ' + lastName}</div>
          </Wrap>
        ),
        color,
        role: WorkersGroupUserAssign?.userRole,
      }));
  } else return [];
};
const Wrap = styled.div`
  display: flex;
  div {
    display: flex;
    align-items: center;
  }
`;
const Avatar = styled.div`
  min-width: 30px;
  height: 30px;
  background: ${({ color }) => (color ? color : '#683ab7')} !important;
  border: ${({ color }) =>
    color ? `1px solid ${color}` : '1px solid #683ab7'};
  color: #fff;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 50%;
  justify-content: center;
  margin-right: 10px;
`;
