import * as types from './constants';

export const getLocations = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/locations' },
      actions: [
        types.GET_LIST_LOCATIONS_REQUEST,
        types.GET_LIST_LOCATIONS_SUCCESS,
        types.GET_LIST_LOCATIONS_FAIL,
      ],
    },
  };
};

export const getLocationsTasks = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/locations/tasks' },
      actions: [
        types.GET_LIST_TASKS_ON_LOCATIONS_REQUEST,
        types.GET_LIST_TASKS_ON_LOCATIONS_SUCCESS,
        types.GET_LIST_TASKS_ON_LOCATIONS_FAIL,
      ],
    },
  };
};

export const getTaskPlace = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/tasks/place' },
      actions: [
        types.GET_TASK_PLACE_REQUEST,
        types.GET_TASK_PLACE_SUCCESS,
        types.GET_TASK_PLACE_FAIL,
      ],
    },
  };
};

export const deleteTaskPlace = (taskPlaceId) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'DELETE', url: `/v1/tasks/place/${taskPlaceId}` },
      actions: [
        types.DELETE_TASK_PLACE_REQUEST,
        types.DELETE_TASK_PLACE_SUCCESS,
        types.DELETE_TASK_PLACE_FAIL,
      ],
    },
  };
};

export const deleteTask = (id) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'DELETE', url: `/v1/tasks/${id}` },
      actions: [
        types.DELETE_TASK_REQUEST,
        types.DELETE_TASK_SUCCESS,
        types.DELETE_TASK_FAIL,
      ],
    },
  };
};

export const updateTaskPlace = (taskPlaceId, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/tasks/place/${taskPlaceId}`,
        json: payload,
      },
      actions: [
        types.UPDATE_TASK_PLACE_REQUEST,
        types.UPDATE_TASK_PLACE_SUCCESS,
        types.UPDATE_TASK_PLACE_FAIL,
      ],
    },
  };
};

export const addTask = (payload) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'POST', url: `/v1/tasks`, json: payload },
      actions: [
        types.ADD_TASK_REQUEST,
        types.ADD_TASK_SUCCESS,
        types.ADD_TASK_FAIL,
      ],
    },
  };
};

export const updateTask = (taskId, payload) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'PATCH', url: `/v1/tasks/${taskId}`, json: payload },
      actions: [
        types.UPDATE_TASK_REQUEST,
        types.UPDATE_TASK_SUCCESS,
        types.UPDATE_TASK_FAIL,
      ],
    },
  };
};

export const addTaskCategory = (payload) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'POST', url: '/v1/tasks/place', json: payload },
      actions: [
        types.ADD_TASK_CATEGORY_REQUEST,
        types.ADD_TASK_CATEGORY_SUCCESS,
        types.ADD_TASK_CATEGORY_FAIL,
      ],
    },
  };
};

export const getLocationById = (locationsId) => {
  return {
    type: 'API',
    payload: {
      request: { url: `/v1/locations/${locationsId}` },
      actions: [
        types.GET_LOCATION_REQUEST,
        types.GET_LOCATION_SUCCESS,
        types.GET_LOCATION_FAIL,
      ],
    },
  };
};

export const getAllLocations = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/locations/listing' },
      actions: [
        types.GET_ALL_LOCATIONS_REQUEST,
        types.GET_ALL_LOCATIONS_SUCCESS,
        types.GET_ALL_LOCATIONS_FAIL,
      ],
    },
  };
};

export const deleteLocation = (id) => ({
  type: 'API',
  payload: {
    request: {
      method: 'DELETE',
      url: `/v1/locations`,
      json: { id: id },
    },
    actions: [
      types.DELETE_LOCATION_REQUEST,
      types.DELETE_LOCATION_SUCCESS,
      types.DELETE_LOCATION_FAIL,
    ],
  },
});

export const addLocation = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: '/v1/locations',
      json: {
        ...payload,
        name: payload.name || '',
      },
    },
    actions: [
      types.ADD_LOCATION_REQUEST,
      types.ADD_LOCATION_SUCCESS,
      types.ADD_LOCATION_FAIL,
    ],
  },
});

export const updateLocation = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'PATCH',
      url: '/v1/locations',
      json: payload,
    },
    actions: [
      types.UPDATE_LOCATION_REQUEST,
      types.UPDATE_LOCATION_SUCCESS,
      types.UPDATE_LOCATION_FAIL,
    ],
  },
});

export const assignLocation = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: '/v1/locations/assign',
      json: payload,
    },
    actions: [
      types.ASSIGN_LOCATION_REQUEST,
      types.ASSIGN_LOCATION_SUCCESS,
      types.ASSIGN_LOCATION_FAIL,
    ],
  },
});

export const addCommentToLocation = (locationId, payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: `/v1/locations/${locationId}/comment`,
      json: payload,
    },
    actions: [
      types.ADD_COMMENT_TO_LOCATION_REQUEST,
      types.ADD_COMMENT_TO_LOCATION_SUCCESS,
      types.ADD_COMMENT_TO_LOCATION_FAIL,
    ],
  },
});

export const joinLocationImg = (locationId, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/locations/${locationId}/image`,
        json: payload,
      },
      actions: [
        types.JOIN_LOCATION_TO_IMG_REQUEST,
        types.JOIN_LOCATION_TO_IMG_SUCCESS,
        types.JOIN_LOCATION_TO_IMG_FAIL,
      ],
    },
  };
};

export const cleanLocation = () => ({
  type: types.CLEAN_LOCATION,
});
