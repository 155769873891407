import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import CardItemWithClockIn from './CardItemWithClockIn';
import CardItemWithOutClockIn from './CardItemWithOutClockIn';
import ArrowLeft from '../../assets/svgs/arrow-left.svg';
import ArrowRight from '../../assets/svgs/arrow-right.svg';
import {
  formatDateWithoutYear,
  formatTimeLowerCase,
} from '../../utils/formatDate';
import { msToHMin } from '../../utils/msToTime';
import clsx from 'clsx';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class WorkHistory extends React.Component {
  render() {
    const {
      hidden,
      currentDate,
      jobsByGroup,
      jobsWithOutWorkingDayGroup,
      handleChangeMonth,
    } = this.props;
    const month = monthNames[currentDate.getMonth()];
    const nexDay = new Date(currentDate);
    nexDay.setDate(nexDay.getDate() + 1);
    const now = new Date();

    return (
      <div
        className={clsx('main-col', {
          ['hidden-mobile']: hidden === 1,
        })}
      >
        <div className="card flex-column">
          <div className="card-title">Work History</div>
          <div className="date-select">
            <a
              href="#"
              onClick={() => {
                handleChangeMonth(true);
              }}
            >
              <ReactSVG src={ArrowLeft} />
            </a>
            <span>
              {month} {currentDate.getFullYear()}
            </span>
            <Block
              opacity={nexDay > now}
              href="#"
              onClick={() => {
                if (nexDay > now) return;
                handleChangeMonth(false);
              }}
            >
              <ReactSVG src={ArrowRight} />
            </Block>
          </div>

          {jobsByGroup?.map(({ date, workingDay }) => (
            <Fragment key={date}>
              {workingDay.map(({ jobs, endDate, totalTime, startDate }) => {
                const time =
                  startDate && endDate
                    ? new Date(endDate).getTime() -
                      new Date(startDate).getTime()
                    : null;
                return (
                  <Fragment key={startDate}>
                    <div className="date">
                      <div className="day">
                        {new Date().toISOString().slice(0, -14) ===
                        new Date(date).toISOString().slice(0, -14)
                          ? 'Today'
                          : formatDateWithoutYear(date)}
                      </div>
                      <div className="hours-worked">
                        {time ? <span>{msToHMin(time)}</span> : null}

                        {!endDate
                          ? 'Started at ' + formatTimeLowerCase(startDate)
                          : `${formatTimeLowerCase(
                              startDate,
                            )} - ${formatTimeLowerCase(endDate)}`}
                      </div>
                    </div>
                    {jobs.length <= 0 ? (
                      <NoJobs>
                        No jobs were started during this working day
                      </NoJobs>
                    ) : (
                      jobs.map((job) => {
                        return (
                          <Fragment key={job.id}>
                            <div className="card-list">
                              <CardItemWithClockIn job={job} time={job.time} />
                            </div>
                          </Fragment>
                        );
                      })
                    )}
                  </Fragment>
                );
              })}
            </Fragment>
          ))}

          <WarapperWithOutWorkingDayGroup>
            {jobsWithOutWorkingDayGroup?.map(({ date, jobs }) => (
              <Fragment key={date}>
                <div className="date">
                  <div className="day">
                    {new Date().toISOString().slice(0, -14) ===
                    new Date(date).toISOString().slice(0, -14)
                      ? 'Today'
                      : formatDateWithoutYear(date)}
                  </div>
                </div>
                <div>
                  {jobs.map(({ job, time }) => {
                    return (
                      <div className="card-list" key={job.id}>
                        <CardItemWithOutClockIn job={job} time={time} />
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            ))}
          </WarapperWithOutWorkingDayGroup>
        </div>
      </div>
    );
  }
}
export default WorkHistory;

const WarapperWithOutWorkingDayGroup = styled.div`
  width: 100%;
`;

const Block = styled.a`
  cursor: ${({ opacity }) => (opacity ? 'initial' : 'pointer')};
  opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  svg {
    opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  }
`;

const NoJobs = styled.span`
  color: var(--grey-text);
  margin: 0 auto;
`;
