import React from 'react';
import styled from 'styled-components';
import Modal from '../../Modal';
import { ReactSVG } from 'react-svg';
import CloseSVG from '../../../assets/images/close.svg';

const ExistsPopup = ({ onClose }) => {
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <div className="modal">
          <Wrapper>
            <div className="modal-header">
              <div className="modal-title">User already exists</div>
              <div className="modal-close" onClick={onClose}>
                <ReactSVG src={CloseSVG} />
              </div>
            </div>
            <div className="modal-body">
              <p>A user with this email address already exists.</p>
            </div>
            <ButtonsWrap>
              <button onClick={onClose} className="active">
                OK
              </button>
            </ButtonsWrap>
          </Wrapper>
        </div>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div`
  p {
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
  .modal-title {
    font-size: 18px;
    line-height: 21px;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const ButtonsWrap = styled.div`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  button {
    width: 117px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #61933a;
    color: #61933a;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    &.active {
      background: #61933a;
      color: #fff;
      margin: 0 auto;
    }
  }
`;

export default ExistsPopup;
