export const GET_WORKERS_GROUP_REQUEST = 'GET_WORKERS_GROUP_REQUEST';
export const GET_WORKERS_GROUP_SUCCESS = 'GET_WORKERS_GROUP_SUCCESS';
export const GET_WORKERS_GROUP_FAIL = 'GET_WORKERS_GROUP_FAIL';

export const GET_WORKERS_GROUP_BY_ID_REQUEST =
  'GET_WORKERS_GROUP_BY_ID_REQUEST';
export const GET_WORKERS_GROUP_BY_ID_SUCCESS =
  'GET_WORKERS_GROUP_BY_ID_SUCCESS';
export const GET_WORKERS_GROUP_BY_ID_FAIL = 'GET_WORKERS_GROUP_BY_ID_FAIL';

export const ADD_WORKERS_GROUP_REQUEST = 'ADD_WORKERS_GROUP_REQUEST';
export const ADD_WORKERS_GROUP_SUCCESS = 'ADD_WORKERS_GROUP_SUCCESS';
export const ADD_WORKERS_GROUP_FAIL = 'ADD_WORKERS_GROUP_FAIL';

export const UPDATE_WORKERS_GROUP_REQUEST = 'UPDATE_WORKERS_GROUP_REQUEST';
export const UPDATE_WORKERS_GROUP_SUCCESS = 'UPDATE_WORKERS_GROUP_SUCCESS';
export const UPDATE_WORKERS_GROUP_FAIL = 'UPDATE_WORKERS_GROUP_FAIL';

export const DELETE_WORKERS_GROUP_REQUEST = 'DELETE_WORKERS_GROUP_REQUEST';
export const DELETE_WORKERS_GROUP_SUCCESS = 'DELETE_WORKERS_GROUP_SUCCESS';
export const DELETE_WORKERS_GROUP_FAIL = 'DELETE_WORKERS_GROUP_FAIL';

export const ASSIGN_WORKERS_TO_GROUP_REQUEST =
  'ASSIGN_WORKERS_TO_GROUP_REQUEST';
export const ASSIGN_WORKERS_TO_GROUP_SUCCESS =
  'ASSIGN_WORKERS_TO_GROUP_SUCCESS';
export const ASSIGN_WORKERS_TO_GROUP_FAIL = 'ASSIGN_WORKERS_TO_GROUP_FAIL';

export const REMOVE_WORKERS_FROM_GROUP_REQUEST =
  'REMOVE_WORKERS_FROM_GROUP_REQUEST';
export const REMOVE_WORKERS_FROM_GROUP_SUCCESS =
  'REMOVE_WORKERS_FROM_GROUP_SUCCESS';
export const REMOVE_WORKERS_FROM_GROUP_FAIL = 'REMOVE_WORKERS_FROM_GROUP_FAIL';

export const ASSIGN_TASK_TO_GROUP_REQUEST = 'ASSIGN_TASK_TO_GROUP_REQUEST';
export const ASSIGN_TASK_TO_GROUP_SUCCESS = 'ASSIGN_TASK_TO_GROUP_SUCCESS';
export const ASSIGN_TASK_TO_GROUP_FAIL = 'ASSIGN_TASK_TO_GROUP_FAIL';

export const REMOVE_TASK_FROM_GROUP_REQUEST = 'REMOVE_TASK_FROM_GROUP_REQUEST';
export const REMOVE_TASK_FROM_GROUP_SUCCESS = 'REMOVE_TASK_FROM_GROUP_SUCCESS';
export const REMOVE_TASK_FROM_GROUP_FAIL = 'REMOVE_TASK_FROM_GROUP_FAIL';

export const UPDATE_WORKERS_ON_GROUP_REQUEST =
  'UPDATE_WORKERS_ON_GROUP_REQUEST';
export const UPDATE_WORKERS_ON_GROUP_SUCCESS =
  'UPDATE_WORKERS_ON_GROUP_SUCCESS';
export const UPDATE_WORKERS_ON_GROUP_FAIL = 'UPDATE_WORKERS_ON_GROUP_FAIL';

export const GET_TASK_FROM_GROUP_REQUEST = 'GET_TASK_FROM_GROUP_REQUEST';
export const GET_TASK_FROM_GROUP_SUCCESS = 'GET_TASK_FROM_GROUP_SUCCESS';
export const GET_TASK_FROM_GROUP_FAIL = 'GET_TASK_FROM_GROUP_FAIL';

export const ASSIGN_LOCATION_TO_GROUP_REQUEST =
  'ASSIGN_LOCATION_TO_GROUP_REQUEST';
export const ASSIGN_LOCATION_TO_GROUP_SUCCESS =
  'ASSIGN_LOCATION_TO_GROUP_SUCCESS';
export const ASSIGN_LOCATION_TO_GROUP_FAIL = 'ASSIGN_LOCATION_TO_GROUP_FAIL';

export const REMOVE_LOCATION_FROM_GROUP_REQUEST =
  'REMOVE_LOCATION_FROM_GROUP_REQUEST';
export const REMOVE_LOCATION_FROM_GROUP_SUCCESS =
  'REMOVE_LOCATION_FROM_GROUP_SUCCESS';
export const REMOVE_LOCATION_FROM_GROUP_FAIL =
  'REMOVE_LOCATION_FROM_GROUP_FAIL';
