import React, { Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../config';
import { getAccessToken } from '../../services/api/storage';
import { getCSVFile } from '../../utils/getCSVFile';
import { ReactSVG } from 'react-svg';
import { formatDate } from '../../utils/formatDate';
import Spinner from '../../components/Spinner';
import DownloadImg from '../../assets/svgs/download.svg';
import GreenNotes from '../../assets/svgs/green-note.svg';
import GreenImg from '../../assets/svgs/green-img.svg';
import { getInitials } from '../../utils/initials';
import ClockInactive from '../../assets/svgs/clock-inactive.svg';
import WorkersGroup from '../../assets/svgs/workersGroup.svg';
import clsx from 'clsx';
import { msToTime } from '../../utils/msToTime';
import { TASK_STATUS_ID_YES } from '../../utils/tasks';

const ROADS_DEFAULT = 'Roads';
const SIDEWALKS_DEFAULT = 'Sidewalks';
const OTHER_DEFAULT = 'Other';

const isDone = (task) =>
  task.JobsTasks && task.JobsTasks.taskStatusId === TASK_STATUS_ID_YES;

class ReportsTimePage extends React.Component {
  componentDidMount() {
    const {
      getJobsReport,
      getAllLocations,
      getLocationsTasks,
      getUsers,
      match: { params },
    } = this.props;
    getAllLocations();
    getLocationsTasks();
    getUsers();
    getJobsReport(
      params.startDate,
      params.endDate,
      params.locationId,
      params.taskId,
      params.userId,
    );
  }

  downloadPDF = async () => {
    const {
      match: {
        params: { startDate, endDate, locationId, taskId, userId },
      },
    } = this.props;

    const _startDate = startDate !== 'null' ? `?startDate=${startDate}` : '';
    const _endDate =
      endDate !== 'null'
        ? `${startDate !== 'null' ? '&' : '?'}endDate=${endDate}`
        : '';
    const _locationIds =
      locationId !== 'all'
        ? `${
            startDate !== 'null' || endDate !== 'null' ? '&' : '?'
          }locationIds=[${locationId}]`
        : '';

    const _taskIds =
      taskId !== 'all'
        ? `${
            startDate !== 'null' || endDate !== 'null' || locationId !== 'all'
              ? '&'
              : '?'
          }taskIds=[${taskId}]`
        : '';

    const _userIds =
      userId !== 'all'
        ? `${
            startDate !== 'null' ||
            endDate !== 'null' ||
            locationId !== 'all' ||
            taskId !== 'all'
              ? '&'
              : '?'
          }userIds=[${userId}]`
        : '';

    const _timeDifference = `${
      startDate !== 'null' ||
      endDate !== 'null' ||
      locationId !== 'all' ||
      taskId !== 'all' ||
      userId !== 'all'
        ? '&'
        : '?'
    }timeDifference=${new Date().getTimezoneOffset()}`;

    let url = `${API_URL}/v1/jobs/report/csv/${_startDate}${_endDate}${_locationIds}${_taskIds}${_userIds}${_timeDifference}`;
    const token = getAccessToken();

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token ? `${token}` : '',
      },
    });

    let blob = await response.blob();
    let newEndDate;
    if (endDate !== 'null') {
      newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate() - 1);
    }
    getCSVFile(
      blob,
      startDate !== 'null'
        ? `Jobs Report ${moment(new Date(startDate)).format('YYYY.MM.DD')}-${
            newEndDate ? moment(new Date(newEndDate)).format('YYYY.MM.DD') : ''
          }.csv`
        : `Jobs Report.csv`,
    );
  };

  render() {
    const {
      isLoading,
      reportJob,
      listAllLocations,
      listUsers,
      listTasks,
      match: { params },
    } = this.props;

    const locationsArr =
      params.locationId !== 'all' ? params.locationId.split(',') : 'all';
    const tasksArr = params.taskId !== 'all' ? params.taskId.split(',') : 'all';
    const usersArr = params.userId !== 'all' ? params.userId.split(',') : 'all';
    let usedLocations, usedUsers, usedTasks;
    usedLocations =
      locationsArr !== 'all'
        ? listAllLocations
            .filter(({ id }) => locationsArr.includes(id.toString()))
            .map((location) => location.name || location.address)
        : listAllLocations.map((location) => location.name || location.address);
    usedUsers =
      usersArr !== 'all'
        ? listUsers
            .filter(({ id }) => usersArr.includes(id.toString()))
            .map((user) => user.name)
        : listUsers.map((user) => user.name);

    usedTasks =
      tasksArr !== 'all'
        ? listTasks.filter(({ id }) => tasksArr.includes(id.toString()))
        : listTasks;

    // let rodTasks = [];
    // let sidewalkTasks = [];
    // let otherTasks = [];
    // if (usedTasks.length) {
    //   rodTasks = usedTasks.filter((task) => task.place?.name === ROADS_DEFAULT);
    //   sidewalkTasks = usedTasks.filter(
    //     (task) => task.place?.name === SIDEWALKS_DEFAULT,
    //   );
    //   otherTasks = usedTasks
    //     .filter((task) => task.place?.name === OTHER_DEFAULT)
    //     .sort((taskOne, taskTwo) => taskOne.id - taskTwo.id);
    // }

    // const roadsNames = rodTasks.map((task) => ROADS_DEFAULT + ' ' + task.name);
    // const sidewalksNames = sidewalkTasks.map(
    //   (task) => SIDEWALKS_DEFAULT + ' ' + task.name,
    // );
    // const othersNames = otherTasks.map(
    //   (task) => OTHER_DEFAULT + ' ' + task.name,
    // );

    const arrayTasks = usedTasks.map((task) => task.name);

    let endDate = 'null';
    if (params.endDate !== 'null') {
      endDate = new Date(params.endDate);
      endDate.setDate(endDate.getDate() - 1);
    }

    return (
      <div className="container change">
        <Wrapper>
          <h2 className="underline">
            <div>
              Detailed Time Report:{' '}
              <span>
                {' '}
                {params.startDate === 'null' && params.endDate === 'null' ? (
                  'All'
                ) : (
                  <>
                    {params.startDate === 'null'
                      ? 'All'
                      : moment(new Date(params.startDate)).format(
                          'YYYY.MM.DD',
                        )}{' '}
                    -{' '}
                    {endDate === 'null'
                      ? new Date()
                          .toISOString()
                          .slice(0, -14)
                          .replaceAll('-', '.')
                      : moment(new Date(endDate)).format('YYYY.MM.DD')}
                  </>
                )}
              </span>
            </div>
            <DownloadButton onClick={this.downloadPDF}>
              <ReactSVG src={DownloadImg} />
            </DownloadButton>
          </h2>
          <InfoReport className="underline">
            <Hours>
              Total Hours: <br />
              <span>
                {Boolean(
                  reportJob &&
                    reportJob.totalTime &&
                    reportJob.jobsReportStat?.length > 0,
                ) && msToTime(reportJob.totalTime)}
                {Boolean(reportJob && reportJob.jobsReportStat?.length === 0) &&
                  '--:--'}
              </span>
            </Hours>
            <List>
              <div className="block">
                <div className="main">Locations: </div>
                <div className="bold">
                  {usedLocations.length === listAllLocations.length
                    ? 'All'
                    : usedLocations.join(', ')}
                </div>
              </div>
              <div className="block">
                <div className="main">Tasks: </div>
                <div className="bold">
                  {listTasks.length === arrayTasks.length
                    ? 'All'
                    : arrayTasks.join(', ')}
                </div>
              </div>
              <div className="block">
                <div className="main">Workers: </div>
                <div className="bold">
                  {listUsers.length === usedUsers.length
                    ? 'All'
                    : usedUsers.join(', ')}
                </div>
              </div>
            </List>
          </InfoReport>
          {isLoading && !reportJob?.jobsReportStat && <Spinner />}
          {reportJob?.jobsReportStat?.length === 0 && (
            <NoResults>NO RESULTS FOUND</NoResults>
          )}
          {reportJob?.jobsReportStat?.map(({ date, jobs }) => (
            <Fragment key={date}>
              <h2>{formatDate(date)}</h2>
              <div className="card">
                {jobs.map(({ job, time }) => {
                  let rodTasks = [];
                  let sidewalkTasks = [];
                  let otherTasks = [];
                  let renamedTasks = [];
                  if (job.tasks.length) {
                    rodTasks = job.tasks.filter(
                      (task) => task.place.name === ROADS_DEFAULT,
                    );
                    sidewalkTasks = job.tasks.filter(
                      (task) => task.place.name === SIDEWALKS_DEFAULT,
                    );
                    otherTasks = job.tasks
                      .filter((task) => task.place.name === OTHER_DEFAULT)
                      .sort((taskOne, taskTwo) => taskOne.id - taskTwo.id);
                    renamedTasks = job.tasks.filter(
                      (task) =>
                        task?.place?.name !== ROADS_DEFAULT &&
                        task?.place?.name !== SIDEWALKS_DEFAULT &&
                        task?.place?.name !== OTHER_DEFAULT,
                    );
                  }

                  const completedTasks = job.tasks.filter(
                    (task) => task.JobsTasks.taskStatusId === 1,
                  );
                  // const completedTasksLength = completedTasks.length;
                  let summerTask = [];
                  if (job.season?.seasonId === 2) {
                    summerTask = completedTasks;
                  }

                  return (
                    <div className="row" key={job.id}>
                      <MainBlock>
                        <div>
                          <p className="heading">
                            <span className="main">{job.location.name}</span>
                            <span className="full-address">
                              {' '}
                              {job.location.address}
                              {job.location.city ? ',' : ''} {job.location.city}
                              {job.location.province ? ',' : ''}{' '}
                              {job.location.province}
                              {job.location.postalCode}
                            </span>
                          </p>
                          <WrapperName>
                            {job.workersGroup?.name ? (
                              <>
                                <ReactSVG
                                  style={{ marginRight: 10, width: 20 }}
                                  src={WorkersGroup}
                                />
                                <div className="full-name">
                                  {job.workersGroup.name}
                                </div>
                              </>
                            ) : (
                              <>
                                <Avatar color={job.user.color}>
                                  {getInitials({
                                    name:
                                      job.user.name + ' ' + job.user.lastName,
                                  })}
                                </Avatar>
                                <div className="full-name">
                                  {job.user.name + ' ' + job.user.lastName}
                                </div>
                              </>
                            )}
                            <WrapSvgs>
                              {job.comment && <ReactSVG src={GreenNotes} />}
                              {Boolean(job.img.length) && (
                                <ReactSVG src={GreenImg} />
                              )}
                            </WrapSvgs>
                          </WrapperName>
                        </div>
                        <div className="flex-row grey time">
                          <strong>
                            <ReactSVG
                              className="clock"
                              src={ClockInactive}
                              wrapper="span"
                            />
                            {msToTime(time)}
                          </strong>
                          <div className="services">
                            <div>
                              {rodTasks.map((task) => (
                                <div
                                  key={task.id}
                                  className={clsx('green', {
                                    active: isDone(task),
                                  })}
                                >
                                  {task.name.slice(0, 1)}
                                </div>
                              ))}
                            </div>
                            <div>
                              {sidewalkTasks.map((task) => (
                                <div
                                  key={task.id}
                                  className={clsx('blue', {
                                    active: isDone(task),
                                  })}
                                >
                                  {task.name.slice(0, 1)}
                                </div>
                              ))}
                            </div>
                            <div>
                              {otherTasks.map((task) => (
                                <div
                                  key={task.id}
                                  className={clsx('brown', {
                                    active: isDone(task),
                                  })}
                                >
                                  {task.name.slice(0, 1)}
                                </div>
                              ))}
                            </div>
                            <div>
                              {job.season?.seasonId !== 2 &&
                                renamedTasks.map((task) => (
                                  <div
                                    key={task.id}
                                    className={clsx('www', {
                                      active: isDone(task),
                                    })}
                                  >
                                    {task.name.slice(0, 1)}
                                  </div>
                                ))}
                            </div>
                            <div>
                              {summerTask.map((task) => (
                                <div
                                  key={task.id}
                                  className={clsx('www', {
                                    active: isDone(task),
                                  })}
                                >
                                  {task.name.slice(0, 1)}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </MainBlock>

                      {/*))}*/}
                    </div>
                  );
                })}
              </div>
            </Fragment>
          ))}
        </Wrapper>
      </div>
    );
  }
}

const Wrapper = styled.div`
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2 span {
    font-weight: 300;
  }
  .bold {
    font-weight: 700;
  }
  .underline {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
  }
  .card {
    padding: 10px 20px 10px 20px;
    margin-bottom: 15px;
    position: relative;
  }
  .flex {
    display: flex;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .row:last-child {
    border: 0;
    margin-bottom: -10px;
  }

  margin-bottom: 85px;

  .logo {
    margin-bottom: 20px;
  }
  .grey {
    color: #777;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p.heading .main {
    font-size: 18px;
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
    color: #333;
  }
  p.heading .full-address {
    color: #777;
  }
  .clock {
    position: relative;
    top: -1px;
    margin-right: 7px;
  }
  .services {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 3px;
  }
  .services > div {
    display: flex;
    margin-left: 15px;
  }
  .services > div > div {
    margin-top: 6px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    height: 16px;
    border-radius: 2px;
    background: #ccc;
    margin-left: 5px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
  .services .green.active {
    background: #63933a;
  }
  .services .blue.active {
    background: #3c589f;
  }
  .services .brown.active {
    background: #795548;
  }
  .flex-row {
    display: flex;
    flex-direction: column;
  }
  .add-button {
    cursor: pointer;
  }
  .time {
    display: block;
    text-align: right;
  }
  @media (min-width: 768px) {
    p.heading {
      margin-bottom: 0;
    }
  }

  @media (min-width: 960px) {
    .card {
      padding: 20px 30px 10px;
    }
    .vertical-center {
      align-items: center;
    }
    .icon {
      margin-top: 0;
    }
  }

  @media (min-width: 1280px) {
    .weather-info {
      display: flex;
    }
    .left-col {
      width: 50%;
    }
    .right-col > div:not(:first-child) {
      margin-left: 30px;
    }
    .right-col {
      width: 50%;
      justify-content: flex-end;
    }
  }
`;

const WrapSvgs = styled.div`
  display: flex;

  svg {
    margin-right: 8px;
  }
`;

const Avatar = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ color }) => (color ? color : '#683ab7')};
  color: #fff;
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  border: ${({ color }) =>
    color ? `1px solid ${color}` : '1px solid #683ab7'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;

const WrapperName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  .full-name {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
    margin-right: 10px;
  }
`;

const MainBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  color: #777777;
  cursor: pointer;
`;

const DownloadButton = styled.button`
  width: 60px;
  height: 40px;
  outline: none;
  background: #61933a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const InfoReport = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hours = styled.div`
  min-width: max-content;
  margin-right: 10px;
  span {
    font-weight: bold;
    font-size: 23px;
    line-height: 23px;
    color: #333333;
  }
`;

const List = styled.div`
  min-width: 129px;
  width: max-content;
  .block {
    display: flex;
    justify-content: space-between;
    div {
      display: inline-block;
      text-align: end;
    }
    .main {
      width: max-content;
      min-width: 100px;
      text-align: start;
    }
  }
`;

const NoResults = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-top: 114px;
  text-align: center;
`;

export default withRouter(ReportsTimePage);
