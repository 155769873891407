/* eslint-disable */
import React from "react";
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {ReactSVG} from "react-svg";

import TrashRed from '../../assets/svgs/trash-red.svg';


const CitiesPagePopover = ({cityId, handleClose, handleDeleteCity}) => {
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MenuWrapper>
                <MenuItem onClick={() => handleDeleteCity(cityId)}>
                    <ReactSVG src={TrashRed}/>
                    <span>Delete</span>
                </MenuItem>
            </MenuWrapper>
        </ClickAwayListener>
    )
}

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3B494C;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  top: 50px;
  right: 0;
  width: 169px;
  z-index: 2;
  background-color: red;
`

const MenuItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 18px 22px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  border: none;
`

export default CitiesPagePopover;
