export const GET_LIST_CITIES_REQUEST = 'GET_LIST_CITIES_REQUEST';
export const GET_LIST_CITIES_SUCCESS = 'GET_LIST_CITIES_SUCCESS';
export const GET_LIST_CITIES_FAIL = 'GET_LIST_CITIES_FAIL';

export const ADD_CITY_REQUEST = 'ADD_CITY_REQUEST';
export const ADD_CITY_SUCCESS = 'ADD_CITY_SUCCESS';
export const ADD_CITY_FAIL = 'ADD_CITY_FAIL';

export const DELETE_CITY_REQUEST = 'DELETE_CITY_REQUEST';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAIL = 'DELETE_CITY_FAIL';
