import React from 'react';
import { ReactSVG } from 'react-svg';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';

const themeLight = createMuiTheme({
  overrides: {
    MuiBackdrop: {
      root: { backgroundColor: 'unset' },
    },
  },
});
const ChangeAssignedPopup = ({ open, onClose, content }) => {
  return (
    <MuiThemeProvider theme={themeLight}>
      <Modal>
        <div className="modal-blur" />
        <StyledDialog
          PaperProps={{
            style: {
              overflow: 'visible',
            },
          }}
          open={open}
          onClose={onClose}
        >
          <div className="modal-overlay">
            <div id="add-location-modal" className="modal">
              <div className="modal-header">
                <div className="modal-title">Edit Assigned Locations</div>
                <div className="modal-close" onClick={onClose}>
                  <ReactSVG src={CloseSVG} />
                </div>
              </div>
              <div
                style={{ overflow: 'visible' }}
                className="modal-body left-align"
              >
                {content}
              </div>
            </div>
          </div>
        </StyledDialog>
      </Modal>
    </MuiThemeProvider>
  );
};

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default ChangeAssignedPopup;
