export const GET_EQUIPMENT_REQUEST = 'GET_EQUIPMENT_REQUEST';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';
export const GET_EQUIPMENT_FAIL = 'GET_EQUIPMENT_FAIL';

export const GET_EQUIPMENT_TYPE_REQUEST = 'GET_EQUIPMENT_TYPE_REQUEST';
export const GET_EQUIPMENT_TYPE_SUCCESS = 'GET_EQUIPMENT_TYPE_SUCCESS';
export const GET_EQUIPMENT_TYPE_FAIL = 'GET_EQUIPMENT_TYPE_FAIL';

export const ADD_EQUIPMENT_REQUEST = 'ADD_EQUIPMENT_REQUEST';
export const ADD_EQUIPMENT_SUCCESS = 'ADD_EQUIPMENT_SUCCESS';
export const ADD_EQUIPMENT_FAIL = 'ADD_EQUIPMENT_FAIL';

export const DELETE_EQUIPMENT_REQUEST = 'DELETE_EQUIPMENT_REQUEST';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_FAIL = 'DELETE_EQUIPMENT_FAIL';

export const UPDATE_EQUIPMENT_REQUEST = 'UPDATE_EQUIPMENT_REQUEST';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_FAIL = 'UPDATE_EQUIPMENT_FAIL';

export const GET_EQUIPMENT_BY_ID_REQUEST = 'GET_EQUIPMENT_BY_ID_REQUEST';
export const GET_EQUIPMENT_BY_ID_SUCCESS = 'GET_EQUIPMENT_BY_ID_SUCCESS';
export const GET_EQUIPMENT_BY_ID_FAIL = 'GET_EQUIPMENT_BY_ID_FAIL';

export const RESET_CURRENT_EQUIPMENT = 'RESET_CURRENT_EQUIPMENT';
