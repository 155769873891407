import React, { useEffect } from 'react';
import styled from 'styled-components';
import EquipmentItem from './EquipmentItem';

function TypedSection(props) {
  const {
    typeName,
    list,
    deleteEquipment,
    handleOpenEditPopup,
    history,
  } = props;

  return (
    <div>
      <TypeName>{typeName}</TypeName>
      {list &&
        list.length &&
        list.map((item) => {
          return (
            <EquipmentItem
              history={history}
              key={item.id}
              item={item}
              deleteEquipment={deleteEquipment}
              handleOpenEditPopup={handleOpenEditPopup}
            />
          );
        })}
    </div>
  );
}

const TypeName = styled.div`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #777777;
  margin-bottom: 15px;
`;

export default TypedSection;
