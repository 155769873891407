// import Alert from '../components/StyledAlertMessage';
//
export const getErrorsMessage = (errors) => {
  if (errors.length === 0) {
    return '';
  }

  if (Array.isArray(errors)) {
    const [error] = errors;
    return error.message || error.msg || error;
  }

  return errors;
};

export const getErrorsMessageUser = (errors) => {
  if (errors.length === 0) {
    return '';
  }

  if (Array.isArray(errors)) {
    const [error] = errors;
    return error.msg + ' ' + error.param;
  }

  return errors;
};
//
// export const showAlertError = (
//   message,
//   { position = 'top-right', timeout = 2500, stack = 1 } = {},
// ) => Alert.error(message, { position, timeout, stack });
//
// export const isComma = (arr, index) => {
//   if (arr.length === 0 || index === arr.length - 1) {
//     return '';
//   }
//
//   return ',';
// };
//
// export const getFullName = (user) => {
//   if (!user || !user.customerData) {
//     return '';
//   }
//
//   const { fullName, firstName, lastName } = user.customerData;
//   if (fullName) {
//     return fullName;
//   }
//
//   return `${firstName} ${lastName}`;
// };
//
// export const getOptionsList = (arr, type) => {
//   if (arr) {
//     if (type) {
//       return arr
//         .filter((item) => item.type === type)
//         .map(({ id: value, name: label }) => ({ value, label }));
//     }
//
//     return arr.map(({ hierarchyLevel, id: value, name: label }) => ({
//       value,
//       label,
//       hierarchyLevel,
//     }));
//   }
//
//   return [];
// };
//
// export const filterSizeOptions = (data = [], options = []) => {
//   const sizes = data.map((item) => item.size);
//   return options.filter((item) => sizes.indexOf(item.id) === -1);
// };
//
// export const getSizingOptions = (data = [], options = []) => {
//   let filtered = options;
//   if (data.length > 0) {
//     filtered = filterSizeOptions(data, options);
//   }
//
//   return [
//     { label: 'Standard', options: getOptionsList(filtered, 'clothing') },
//     { label: 'Dress Sizing', options: getOptionsList(filtered, 'dress size') },
//     { label: 'Denim Sizing', options: getOptionsList(filtered, 'denim') },
//     { label: 'Euro Sizing', options: getOptionsList(filtered, 'euro') },
//   ];
// };
//
// export const formatPrice = (price, currencySymbol = '$') => {
//   const value = Number(price);
//   if (!value) {
//     return `${currencySymbol}0`;
//   }
//
//   const fixedValue = (value / 100).toFixed(2);
//   return `${currencySymbol}${fixedValue}`;
// };
//
// export const getPlanOptions = (plans) =>
//   plans
//     ? plans.map(({ name, code, price }) => ({
//         value: code,
//         label: `${name} ${formatPrice(price)}`,
//       }))
//     : [];
//
// export const mapItemStatus = (data) =>
//   data.reduce((acc, item) => {
//     acc[item.id] = `${item.status}`;
//     return acc;
//   }, {});
