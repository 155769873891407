import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.GET_EQUIPMENT_REQUEST, handleRequest),
  takeEvery(types.GET_EQUIPMENT_TYPE_REQUEST, handleRequest),
  takeEvery(types.ADD_EQUIPMENT_REQUEST, handleRequest),
  takeEvery(types.DELETE_EQUIPMENT_REQUEST, handleRequest),
  takeEvery(types.UPDATE_EQUIPMENT_REQUEST, handleRequest),
  takeEvery(types.GET_EQUIPMENT_BY_ID_REQUEST, handleRequest),
]);
