import React from 'react';
import Dropzone from 'react-dropzone';
import styled, { css } from 'styled-components';
import DropZone from './DropZone';
import FilePreview from './FilePreview';
import { generateId } from '../../utils/string';
import StyledAlertMessage from '../../components/StyledAlertMessage';
import { getErrorsMessage } from '../../utils/template';
import request from '../../services/api/request';
import { getResponseErrors } from '../../utils/response';
// import RejectLoadingImgPopup from '../../components/Popups/RejectLoadingImgPopup';

class DropZoneUpload extends React.Component {
  state = {
    isLoading: false,
    files: this.props.logos || [],
    rejectLoadingFiles: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.logos && prevProps.logos.length !== this.props.logos.length)
      this.setState({ files: this.props.logos });
  }

  handleChangeRejectLoadingFiles = () => {
    this.setState({ rejectLoadingFiles: false });
  };

  uploadImages(files) {
    const { multiple, field = 'key' } = this.props;
    const form = new FormData();
    files.forEach((file) => form.append(`${field}`, file));
    form.append('multiple', multiple);
    return request({ method: 'POST', url: '/images', body: form });
  }

  onDrop = async (uploadFiles) => {
    const { files } = this.state;
    const { isNotLimited, onChange, logos = [] } = this.props;
    if (uploadFiles.length > 0) {
      if (!isNotLimited && logos.length + uploadFiles.length > 15) {
        StyledAlertMessage.error('You can save only 10 images');
        return;
      }

      try {
        this.setState({ isLoading: true });
        const images = await this.uploadImages(uploadFiles);
        const filesList = [...files, ...images.locations];
        this.setState({ files: filesList, isLoading: false }, () => {
          onChange(filesList);
        });
      } catch (e) {
        const errors = getResponseErrors(e);
        this.setState({ isLoading: false });
        StyledAlertMessage.error(getErrorsMessage(errors));
      }
    }
  };

  onDropRejected = (rejectedFiles) => {
    if (rejectedFiles.length === 1) {
      this.setState({
        rejectLoadingFiles: `File ${rejectedFiles[0].name} is not supported`,
      });
    } else {
      const namesArr = rejectedFiles.map((file) => file.name);
      this.setState({
        rejectLoadingFiles: `Files ${namesArr.join(', ')} are not supported`,
      });
    }
  };

  render() {
    const { isLoading } = this.state; //rejectLoadingFiles
    const {
      pt = 0,
      small = 'false',
      multiple = false,
      handleDragStart,
      handleDragOver,
      handleDrop,
      handleDelete,
      logos,
      setPopupImg,
    } = this.props;

    const inputId = generateId('file-upload-');
    return (
      <Wrap
        pt={pt}
        amount={logos.length}
        className="drop-zone-wrap"
        multi={multiple && logos.length}
      >
        <DragWrapper className="boxesGroup">
          {logos &&
            logos.length > 0 &&
            multiple &&
            logos.map((item, index) => (
              <FilePreview
                box={item}
                key={`${item}-${index}`}
                draggable="true"
                onDragStart={(event) => handleDragStart(item, event)}
                onDragOver={(event) => handleDragOver(item, event)}
                onDrop={(event) => handleDrop(item, event)}
                multiple
                file={item}
                small={small}
                setPopupImg={setPopupImg}
                className="file-preview-drag"
                onDelete={() => {
                  handleDelete(index);
                }}
              />
            ))}

          <Dropzone
            accept="image/jpeg, image/jpg, image/png"
            multiple={multiple}
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="drop-zone-content">
                {logos.length > 0 && !multiple && (
                  <FilePreview
                    setPopupImg={setPopupImg}
                    file={logos}
                    small={small}
                    multiple={false}
                    onDelete={handleDelete}
                  />
                )}
                {(logos.length === 0 || (multiple && logos.length < 15)) && (
                  <DropZone
                    accept="image/jpeg, image/jpg, image/png"
                    small={small}
                    value={logos}
                    inputId={inputId}
                    multiple={multiple}
                    isLoading={isLoading}
                    inputProps={getInputProps()}
                    onDropRejected={this.onDropRejected}
                  />
                )}
              </div>
            )}
          </Dropzone>
        </DragWrapper>
        {/*<RejectLoadingImgPopup*/}
        {/*  onClose={this.handleChangeRejectLoadingFiles}*/}
        {/*  open={Boolean(rejectLoadingFiles)}*/}
        {/*  text={rejectLoadingFiles}*/}
        {/*/>*/}
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  min-width: 60px;
  min-height: 60px;
  padding-top: ${({ pt }) => pt}px;

  * {
    outline: none !important;
  }

  .drop-zone-content {
    max-width: 100%;
  }

  ${({ multi }) =>
    multi &&
    css`
      display: flex;
      flex-wrap: ${({ amount }) => (amount <= 4 ? 'nowrap' : 'wrap')};

      .drop-zone-content {
        // width: 20%;
        min-width: 100px;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap !important;

        .drop-zone-content {
          max-width: 100% !important;
          width: calc(50% - 16px);
          margin: 0 8px;
        }
      }
    `}
`;

const DragWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default DropZoneUpload;
