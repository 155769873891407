/* eslint-disable */
import React, {useState} from "react";
import styled from 'styled-components';
import moment from 'moment';
import {ReactSVG} from "react-svg";

import CitiesPagePopover from "./CitiesPagePopover";

import MenuDots from "../../assets/svgs/menu-image.svg";

const CityItem = ({city, activeCityPopover, setActiveCityPopover, handleDeleteCity}) => {
    const [openPopover, setOpenPopover] = useState(false)

    const handleOpenPopover = (e) => {
        e.stopPropagation();

        if (city.id === activeCityPopover) {
            setActiveCityPopover(-1)
            setOpenPopover(true);
        } else {
            setActiveCityPopover(city.id)
            setOpenPopover(true);
        }
    };

    const handleClosePopover = () => {
        setActiveCityPopover(-1);
        setOpenPopover(false);
    };

    const cityCreationDate = moment(city.createdAt).format('MMMM DD, YYYY');

    return (
        <Wrapper>
            {city.name}
            <CreationDate>{cityCreationDate}</CreationDate>
            <PopoverButton className="card-menu" onClick={handleOpenPopover}>
                <ReactSVG src={MenuDots}/>
            </PopoverButton>
            {openPopover && city.id === activeCityPopover && <CitiesPagePopover
                cityId={city.id}
                handleDeleteCity={handleDeleteCity}
                handleClose={handleClosePopover}
            />}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: grid;
  grid-template-columns: 1fr 140px 40px;
  align-items: center;
  gap: 24px;
  padding-left: 30px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0;
`

const CreationDate = styled.div`
  color: #777777;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`

const PopoverButton = styled.button`
  margin-top: -6px;
  background-color: #fff;
  border: none;
`

export default CityItem;
