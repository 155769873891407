import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import StyledMultiSelect from '../../StyledMultiSelect';
import { getOptionsTeamMembers } from '../../../utils/getOptionsTeamMembers';
import { isEqual } from 'lodash';

const Menu = (props) => {
  return <div {...props} />;
};
const Blanket = (props) => <div {...props} />;
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default class AssignSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      assignedUsers: null,
      options: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    // this.setState({ assignedUsers: this.props.listUsers });
    const { users, listUsers } = this.props;
    if (users) {
      this.setState({ assignedUsers: getOptionsTeamMembers(users) });
    }

    if (listUsers && users) {
      let options = [];
      if (listUsers.length) options = getOptionsTeamMembers(listUsers);

      if (users.length && !listUsers.length) options = users;

      let onlyValuesAssign = users.map(({ id }) => id);
      this.setState({
        options: options.sort(
          (a, b) =>
            onlyValuesAssign.indexOf(b.value) -
            onlyValuesAssign.indexOf(a.value),
        ),
      });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { listUsers, handleSetUsers } = this.props;
    const { assignedUsers } = this.state;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
      if (!isEqual(listUsers, assignedUsers)) {
        // const usersIds = assignedUsers.map(({ value }) => value);
        handleSetUsers(assignedUsers);
      }
    }
  }

  toggleOpen = () => {
    const { listUsers } = this.props;
    // const { assignedUsers } = this.state;
    // const usersIds = assignedUsers.map(({ value }) => value);
    // if (!this.state.isOpen && !listUsers.length) getTeamMembers({});
    // else
    // if (this.state.isOpen) updateEventAssignees({ eventNumber, usersIds });
    // else
    if (listUsers.length) {
      // let onlyValuesAssign = assignedUsers === null ? [] : [assignedUsers];

      this.setState({
        options: getOptionsTeamMembers(listUsers),
      });
    }

    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  onSelectChange = (value) => {
    // const onlyValues = value.map((value) => value);
    this.setState({
      assignedUsers: value.value,
    });
  };

  render() {
    const { listUsers, isLoading, isOneOption } = this.props;
    const { isOpen, assignedUsers, options } = this.state;

    const userSelected =
      assignedUsers === null
        ? null
        : listUsers.find((user) => user.id === assignedUsers);

    return (
      <Wrap ref={this.setWrapperRef}>
        <Dropdown
          isOpen={isOpen}
          onClose={this.toggleOpen}
          target={
            <Button
              style={{ backgroundColor: 'transparent', height: 'auto' }}
              onClick={this.toggleOpen}
              isSelected={isOpen}
            >
              <div>
                <WrapperUsers userSelected={userSelected}>
                  {/*<Avatar>*/}
                  {/*  {userSelected &&*/}
                  {/*    getInitials({*/}
                  {/*      name: userSelected.name + ' ' + userSelected.lastName,*/}
                  {/*    })}*/}
                  {/*</Avatar>*/}
                  {!userSelected && (
                    <span className="select-user">Select user</span>
                  )}
                  <div>
                    {userSelected &&
                      userSelected.name + ' ' + userSelected.lastName}
                  </div>
                </WrapperUsers>
              </div>
            </Button>
          }
        >
          <StyledMultiSelect
            isOneOption={isOneOption}
            id="eventTypeName"
            name="eventTypeName"
            onChange={this.onSelectChange}
            isLoading={isLoading}
            data={options}
            value={userSelected && getOptionsTeamMembers([userSelected])}
          />
        </Dropdown>
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  width: 100%;
`;

const WrapperUsers = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  color: ${({ userSelected }) => (userSelected ? '#333333' : '#61933a')};
  font-weight: ${({ userSelected }) => (userSelected ? 'bold' : 'inherit')};
`;
