import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import FooterMenu from '../../components/FooterMenu';
import { SidebarLayoutContext } from './context';

class SidebarLayout extends React.Component {
  static contextType = SidebarLayoutContext;

  render() {
    const { children } = this.props;

    const {} = this.context;

    return (
      <Fragment>
        <FooterMenu />
        <div className="wrapper">
          <div className="content-wrapper">
            <Switch>
              <Route
                exact
                path={[
                  '/',
                  '/jobs-list',
                  '/profile',
                  '/locations',
                  '/users',
                  '/reports',
                  '/reports/weather/:city/:startDate/:endDate',
                  '/reports/time/:startDate/:endDate/:locationId/:taskId/:userId',
                  '/edit-location/:id',
                  '/edit-user/:id',
                  '/edit-equipment/:id',
                  '/tasks',
                  '/equipment',
                  '/cities',
                  '/group-workers',
                  '/group/:id',
                ]}
              >
                {children}
              </Route>
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ usersStore: { identity } }) => ({
  identity,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLayout);
