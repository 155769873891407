import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import TrashRed from '../../assets/svgs/trash-red.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class DropDownLocations extends React.Component {
  render() {
    const { onClose, deleteLocation, location } = this.props;
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Wrap
          onClick={() => {
            deleteLocation(location.id);
            onClose();
          }}
        >
          <ReactSVG src={TrashRed} />
          <span>Delete</span>
        </Wrap>
      </ClickAwayListener>
    );
  }
}

const Wrap = styled.div`
  width: 169px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  right: 0;
  display: flex;
  padding: 18px 22px;
  z-index: 999;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 17px;
  color: #3b494c;
  span {
    padding-left: 16px;
    padding-top: 1px;
  }
  svg {
    margin-top: -3px;
  }
`;

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DropDownLocations);
