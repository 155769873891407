import * as types from '../reportsStore/constants';

export const getCities = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/city' },
      actions: [
        types.GET_LIST_CITIES_REQUEST,
        types.GET_LIST_CITIES_SUCCESS,
        types.GET_LIST_CITIES_FAIL,
      ],
    },
  };
};

export const getWeatherReportByDates = (city, startDate, endDate) => {
  return {
    type: 'API',
    payload: {
      request: {
        url: `/v1/weather/report/${city}?startDate=${startDate}&endDate=${endDate}`,
      },
      actions: [
        types.GET_WEATHER_REPORT_BY_DATES_REQUEST,
        types.GET_WEATHER_REPORT_BY_DATES_SUCCESS,
        types.GET_WEATHER_REPORT_BY_DATES_FAIL,
      ],
    },
  };
};

export const getJobsReport = (
  startDate,
  endDate,
  locationIds,
  taskIds,
  userIds,
) => {
  const _startDate = startDate !== 'null' ? `?startDate=${startDate}` : '';
  const _endDate =
    endDate !== 'null'
      ? `${startDate !== 'null' ? '&' : '?'}endDate=${endDate}`
      : '';
  const _locationIds =
    locationIds !== 'all'
      ? `${
          startDate !== 'null' || endDate !== 'null' ? '&' : '?'
        }locationIds=[${locationIds}]`
      : '';

  const _taskIds =
    taskIds !== 'all'
      ? `${
          startDate !== 'null' || endDate !== 'null' || locationIds !== 'all'
            ? '&'
            : '?'
        }taskIds=[${taskIds}]`
      : '';

  const _userIds =
    userIds !== 'all'
      ? `${
          startDate !== 'null' ||
          endDate !== 'null' ||
          locationIds !== 'all' ||
          taskIds !== 'all'
            ? '&'
            : '?'
        }userIds=[${userIds}]`
      : '';

  const _timeDifference = `${
    startDate !== 'null' ||
    endDate !== 'null' ||
    locationIds !== 'all' ||
    taskIds !== 'all' ||
    userIds !== 'all'
      ? '&'
      : '?'
  }timeDifference=${new Date().getTimezoneOffset()}`;

  return {
    type: 'API',
    payload: {
      request: {
        url: `/v1/jobs/report/${_startDate}${_endDate}${_locationIds}${_taskIds}${_userIds}${_timeDifference}`,
      },
      actions: [
        types.GET_JOB_REPORT_REQUEST,
        types.GET_JOB_REPORT_SUCCESS,
        types.GET_JOB_REPORT_FAIL,
      ],
    },
  };
};

export const getShiftsReport = (startDate, endDate) => {
  const _startDate = startDate !== 'null' ? `?startDate=${startDate}` : '';
  const _endDate =
    endDate !== 'null'
      ? `${startDate !== 'null' ? '&' : '?'}endDate=${endDate}`
      : '';

  return {
    type: 'API',
    payload: {
      request: {
        url: `/v1/working/day/report/${_startDate}${_endDate}`,
      },
      actions: [
        types.GET_WORKING_DAY_REPORT_REQUEST,
        types.GET_WORKING_DAY_REPORT_SUCCESS,
        types.GET_WORKING_DAY_REPORT_FAIL,
      ],
    },
  };
};
