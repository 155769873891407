import * as types from './constants';

export const getEquipment = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/equipment' },
      actions: [
        types.GET_EQUIPMENT_REQUEST,
        types.GET_EQUIPMENT_SUCCESS,
        types.GET_EQUIPMENT_FAIL,
      ],
    },
  };
};

export const getEquipmentTypes = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/equipment/type' },
      actions: [
        types.GET_EQUIPMENT_TYPE_REQUEST,
        types.GET_EQUIPMENT_TYPE_SUCCESS,
        types.GET_EQUIPMENT_TYPE_FAIL,
      ],
    },
  };
};

export const getEquipmentById = (id) => {
  return {
    type: 'API',
    payload: {
      request: { url: `/v1/equipment/${id}` },
      actions: [
        types.GET_EQUIPMENT_BY_ID_REQUEST,
        types.GET_EQUIPMENT_BY_ID_SUCCESS,
        types.GET_EQUIPMENT_BY_ID_FAIL,
      ],
    },
  };
};

export const addEquipment = (payload) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'POST', url: `/v1/equipment`, json: payload },
      actions: [
        types.ADD_EQUIPMENT_REQUEST,
        types.ADD_EQUIPMENT_SUCCESS,
        types.ADD_EQUIPMENT_FAIL,
      ],
    },
  };
};

export const deleteEquipment = (id) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'DELETE', url: `/v1/equipment/${id}` },
      actions: [
        types.DELETE_EQUIPMENT_REQUEST,
        types.DELETE_EQUIPMENT_SUCCESS,
        types.DELETE_EQUIPMENT_FAIL,
      ],
    },
  };
};

export const updateEquipment = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/equipment/${id}`,
        json: payload,
      },
      actions: [
        types.UPDATE_EQUIPMENT_REQUEST,
        types.UPDATE_EQUIPMENT_SUCCESS,
        types.UPDATE_EQUIPMENT_FAIL,
      ],
    },
  };
};

export const resetCurrentEquipment = () => ({
  type: types.RESET_CURRENT_EQUIPMENT,
});
