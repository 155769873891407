import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import { API_URL } from '../../../config';
import Modal from '../../Modal';
import { ReactSVG } from 'react-svg';
import CloseSVG from '../../../assets/images/close.svg';
import { storage, TOKEN_KEY } from '../../../services/api/storage';

const COVIDQuestionnairePopup = ({ user, onClose, handleOpenUnlockPopup }) => {
  const uploadFile = (event) => {
    const token = storage.getItem(TOKEN_KEY);

    let url = `${API_URL}/v1/sick-leave/close/user/${user.id}`;

    const files = event.target.files;
    const filesArr = Array.from(files);

    filesArr.forEach((file) => {
      let data = new FormData();

      data.append(`documentImg`, file);

      axios
        .post(url, data, {
          headers: {
            Accept: 'application/json',
            Authorization: `${JSON.parse(token).accessToken}`,
          },
        })
        .then(() => {
          onClose();
          handleOpenUnlockPopup();
        });
    });
  };

  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <div className="modal">
          <Wrapper>
            <div className="modal-header">
              <div className="modal-title">COVID Questionnaire</div>
              <div className="modal-close" onClick={onClose}>
                <ReactSVG src={CloseSVG} />
              </div>
            </div>
            <div className="modal-body left-align">
              <p className="mb-30">
                {`On ${moment(user.questionnaire[0].updatedAt).format(
                  'LL',
                )} at ${moment(user.questionnaire[0].updatedAt)
                  .format('LT')
                  .replace(' ', '')
                  .toLowerCase()}, ${
                  user.name + ' ' + user.lastName
                } answered “yes” to the
                  following COVID questions:`}
              </p>
              {user.questionnaire[0].questionnaire.fillSick && (
                <div className="grey-card">
                  <div className="question">Do you feel sick?</div>
                  <div className="answer">Yes</div>
                </div>
              )}
              {user.questionnaire[0].questionnaire.runnyNose && (
                <div className="grey-card">
                  <div className="question">Do you have a runny nose?</div>
                  <div className="answer">Yes</div>
                </div>
              )}
              {user.questionnaire[0].questionnaire.cough && (
                <div className="grey-card">
                  <div className="question">Do you have a cough?</div>
                  <div className="answer">Yes</div>
                </div>
              )}
              {user.questionnaire[0].questionnaire.headache && (
                <div className="grey-card">
                  <div className="question">Do you have a headache?</div>
                  <div className="answer">Yes</div>
                </div>
              )}
              {user.questionnaire[0].questionnaire.lossOfSmell && (
                <div className="grey-card">
                  <div className="question">
                    Do you have loss of smell or taste?
                  </div>
                  <div className="answer">Yes</div>
                </div>
              )}
              {user.questionnaire[0].questionnaire.contact && (
                <div className="grey-card">
                  <div className="question">
                    Have you or someone you live with come in contact with
                    someone who has been diagnosed with COVID-19?
                  </div>
                  <div className="answer">Yes</div>
                </div>
              )}
              {user.questionnaire[0].questionnaire.traveled && (
                <div className="grey-card">
                  <div className="question">
                    Have you or someone you live with traveled in the last 14
                    days?
                  </div>
                  <div className="answer">Yes</div>
                </div>
              )}
              <p className="mt-30">
                To unlock this account, you must upload negative covid test.
              </p>
            </div>

            <div className="cta-bar">
              <label htmlFor="files" className="btn">
                UPLOAD NEGATIVE COVID TEST
              </label>
              {/*<button className="btn"></button>*/}
              <HiddenInput
                id="files"
                type="file"
                accept={'.png, .jpg, .jpeg, .svg, .pdf, .doc, .txt'}
                onChange={uploadFile}
              />
            </div>
          </Wrapper>
        </div>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div`
  .grey-card {
    line-height: 16px;
    background: #f5f5f5;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .grey-card .question {
    font-weight: 600;
  }
  .grey-card .answer {
    font-size: 18px;
    color: var(--alert);
    margin-left: 10px;
    white-space: nowrap;
  }
  label {
    line-height: 39px;
  }
  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const HiddenInput = styled.input`
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  visibility: hidden;
`;

export default COVIDQuestionnairePopup;
