import { connect } from 'react-redux';
import WorkerPage from './WorkerPage';
import {
  addUser,
  getUserById,
  updateUser,
  cleanUser,
  restoreUser,
  deleteUsers,
} from '../../store/usersStore/actions';
import {
  getAllLocations,
  assignLocation,
} from '../../store/locationsStore/actions';
import { getJobsByGroup, getActiveJob } from '../../store/jobsStore/actions';

const mapStateToProps = ({
  usersStore: {
    isUserAdded,
    userItem,
    isUserLoading,
    isUserUpdated,
    isUserRestored,
    error,
    isUserDeleted,
  },
  locationsStore: { listAllLocations, isLoading, isAssignedLocation },
  jobsStore: {
    jobsByGroup,
    isLoadingHistory,
    jobsWithOutWorkingDayGroup,
    activeJob,
  },
}) => ({
  isUserDeleted,
  isUserAdded,
  userItem,
  isUserLoading,
  isUserUpdated,
  isUserRestored,
  error,
  listAllLocations,
  isLoading,
  isAssignedLocation,
  jobsByGroup,
  jobsWithOutWorkingDayGroup,
  activeJob,
  isLoadingHistory,
});

const mapDispatchToProps = {
  addUser,
  getUserById,
  updateUser,
  cleanUser,
  restoreUser,
  getAllLocations,
  assignLocation,
  getJobsByGroup,
  getActiveJob,
  deleteUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerPage);
