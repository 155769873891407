import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  listCities: [],
  reportWeather: [],
  reportJob: {},
  reportShift: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_CITIES_REQUEST:
      return { ...state, listCities: [], isLoading: true };
    case types.GET_LIST_CITIES_SUCCESS:
      return {
        ...state,
        listCities: payload,
        isLoading: false,
      };
    case types.GET_LIST_CITIES_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_WEATHER_REPORT_BY_DATES_REQUEST:
      return { ...state, reportWeather: [], isLoading: true };
    case types.GET_WEATHER_REPORT_BY_DATES_SUCCESS:
      return {
        ...state,
        reportWeather: payload,
        isLoading: false,
      };
    case types.GET_WEATHER_REPORT_BY_DATES_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_JOB_REPORT_REQUEST:
      return {
        ...state,
        reportJob: {},
        isLoading: true,
        isLoadingReport: true,
      };
    case types.GET_JOB_REPORT_SUCCESS:
      return {
        ...state,
        reportJob: payload,
        isLoading: false,
        isLoadingReport: false,
      };
    case types.GET_JOB_REPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingReport: false,
        error: payload,
      };
    case types.GET_WORKING_DAY_REPORT_REQUEST:
      return {
        ...state,
        reportShift: [],
        isLoading: true,
        isLoadingReport: true,
      };
    case types.GET_WORKING_DAY_REPORT_SUCCESS:
      return {
        ...state,
        reportShift: payload.data,
        isLoading: false,
        isLoadingReport: false,
      };
    case types.GET_WORKING_DAY_REPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingReport: false,
        error: payload,
      };

    default:
      return state;
  }
};
