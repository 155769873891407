import { connect } from 'react-redux';
import EquipmentProfile from './EquipmentProfile';
import {
  getEquipmentById,
  deleteEquipment,
  updateEquipment,
  resetCurrentEquipment,
} from '../../store/equipmentStore/actions';

const mapStateToProps = ({ equipmentStore: { currentEquipment } }) => ({
  currentEquipment,
});

const mapDispatchToProps = {
  getEquipmentById,
  deleteEquipment,
  updateEquipment,
  resetCurrentEquipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentProfile);
