import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import AddEquipmentPopup from '../../components/Popups/AddEquipmentPopup';
import Spinner from '../../components/Spinner';
import TypedSection from './TypedSection';

function EquipmentPage(props) {
  const {
    getEquipment,
    getEquipmentTypes,
    addEquipment,
    deleteEquipment,
    updateEquipment,
    listEquipment,
    equipmentTypes,
    history,
  } = props;

  const [list, setList] = useState(null);
  const [openEquipmentPopup, setOpenEquipmentPopup] = useState(false);
  const [addingTypeId, setAddingTypeId] = useState(null);
  const [editingItem, setEditingItem] = useState({});

  useEffect(() => {
    getEquipment();
    getEquipmentTypes();
  }, []);

  useEffect(() => {
    let customList = {};
    equipmentTypes.length &&
      equipmentTypes.forEach((type) => {
        customList[type.id] = {
          type: {
            id: type.id,
            name: type.name,
          },
          equipments: [],
        };
      });

    if (listEquipment.length && equipmentTypes.length) {
      listEquipment.forEach((item) => {
        customList[item.equipmentType.id].equipments.push(item);
      });
    }
    setList(customList);
  }, [listEquipment, equipmentTypes]);

  const handleSubmitPopup = (data) => {
    if (addingTypeId) {
      handleAddEquipment(data);
    } else if (editingItem.id) {
      handleEditEquipment(data);
    }
  };

  const handleAddEquipment = (data) => {
    if (data.licensePlateExpiryDate) {
      let formated = new Date(data.licensePlateExpiryDate);
      formated.setMinutes(formated.getMinutes() + formated.getTimezoneOffset());
      data.licensePlateExpiryDate = formated.toISOString();
    }
    if (data.nextServiceDate) {
      let formated = new Date(data.nextServiceDate);
      formated.setMinutes(formated.getMinutes() + formated.getTimezoneOffset());
      data.nextServiceDate = formated.toISOString();
    }
    for (let key in data) {
      !data[key] && delete data[key];
    }
    data.equipmentTypeId = addingTypeId;
    addEquipment(data);
    setOpenEquipmentPopup(false);
    resetPopup();
  };

  const handleEditEquipment = (data) => {
    if (data.licensePlateExpiryDate) {
      let formated = new Date(data.licensePlateExpiryDate);
      data.licensePlateExpiryDate = formated.toISOString();
    }
    if (data.nextServiceDate) {
      let formated = new Date(data.nextServiceDate);
      data.nextServiceDate = formated.toISOString();
    }
    for (let key in data) {
      !data[key] ? (data[key] = null) : null;
    }
    updateEquipment(editingItem.id, data);
    setOpenEquipmentPopup(false);
    resetPopup();
  };

  const handleOpenEditPopup = (item) => {
    setEditingItem(item);
    setOpenEquipmentPopup(true);
  };

  const resetPopup = () => {
    setAddingTypeId(null);
    setEditingItem(null);
  };

  return (
    <Wrapper>
      <div className="title-bar">
        <h1>Equipment</h1>
      </div>
      <div className="container">
        {!list ? (
          <Spinner />
        ) : (
          Object.keys(list).map((id) => {
            const type =
              list[id].type.name.slice(-1) === 's'
                ? list[id].type.name.slice(0, -1)
                : list[id].type.name;
            return (
              <Fragment key={id}>
                <TypedSection
                  history={history}
                  handleOpenEditPopup={handleOpenEditPopup}
                  deleteEquipment={deleteEquipment}
                  typeName={list[id].type.name}
                  list={list[id].equipments}
                />
                <Add>
                  <span
                    onClick={() => {
                      setAddingTypeId(list[id].type.id);
                      setOpenEquipmentPopup(true);
                    }}
                  >
                    {' '}
                    + Add {type}
                  </span>
                </Add>
              </Fragment>
            );
          })
        )}
        {openEquipmentPopup && (
          <AddEquipmentPopup
            equipment={editingItem}
            handleSubmit={handleSubmitPopup}
            onClose={() => {
              setOpenEquipmentPopup(false);
              resetPopup();
            }}
            type={addingTypeId}
          />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h2 {
    color: #777777;
  }
`;

const Add = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  color: #61933a;
  margin-top: 15px;
  margin-bottom: 30px;
  span {
    cursor: pointer;
  }
`;

export default EquipmentPage;
