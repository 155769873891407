import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getDefaultValueForWeatherSelect } from '../../utils/options';
import StyledSelect from '../../components/StyledSelect';

class WeatherBlock extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    location: null,
    error: null,
  };

  handleChangeStartTime = (event) => {
    if (!event || !event.target || !event.target.value) return;
    this.setState({ error: null });

    this.setState({
      startDate: event.target.value,
    });
  };

  handleChangeEndTime = (event) => {
    if (!event || !event.target || !event.target.value) return;
    this.setState({ error: null });

    this.setState({
      endDate: event.target.value,
    });
  };

  handleLocation = (name, value) => {
    this.setState({ location: { label: name, value: value } });
  };

  handleGetReport = (e) => {
    const { history } = this.props;
    const { startDate, endDate, location } = this.state;
    e.preventDefault();
    if (!startDate || !endDate || !location) {
      this.setState({ error: 'Fill all the fields' });
      return;
    }
    let endFormatDate = new Date(endDate);
    endFormatDate.setDate(endFormatDate.getDate() + 1);
    history.push(
      `/reports/weather/${location.value}/${new Date(
        startDate,
      ).toISOString()}/${endFormatDate.toISOString()}`,
    );
  };

  render() {
    const { listCities } = this.props;
    const { startDate, endDate, error } = this.state;

    const optionsCitiesArray = listCities.map((location) =>
      getDefaultValueForWeatherSelect(location),
    );

    const dayLimit = new Date().toISOString().slice(0, 10);

    return (
      <Wrapper>
        <div className="card">
          <form>
            <div className="form-row flex">
              <div className="one-half">
                <label htmlFor="">Start Date</label>

                <input
                  type="date"
                  id="start-time"
                  defaultValue={startDate}
                  max={dayLimit}
                  onInput={this.handleChangeStartTime}
                />
              </div>
              <div className="one-half">
                <label htmlFor="">End Date</label>
                <input
                  type="date"
                  id="end-time"
                  defaultValue={endDate}
                  max={dayLimit}
                  min={startDate}
                  onInput={this.handleChangeEndTime}
                />
              </div>
            </div>
            <div className="form-row">
              <label htmlFor="">Locations</label>
              <StyledSelect
                id="role"
                name="role"
                setFieldValue={(name, value) => {
                  this.handleLocation(name, value);
                  this.setState({ error: null });
                }}
                options={optionsCitiesArray}
                field="name"
                placeholder="Select Location"
              />
            </div>
            <Error>{error}</Error>
            <div className="form-row">
              <button className="alt center" onClick={this.handleGetReport}>
                RUN REPORT
              </button>
            </div>
          </form>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .card {
    padding: 20px 20px 10px;
    margin-bottom: 15px;
    position: relative;
  }
  label {
    color: #333;
  }
  @media (min-width: 960px) {
    .card {
      padding: 30px 30px 10px;
    }
  }
`;

const Error = styled.span`
  color: #d60000;
`;

export default withRouter(WeatherBlock);
