import React from 'react';
import NotesImg from '../../assets/svgs/noteImg.svg';
import ImageImg from '../../assets/svgs/imageImg.svg';
import { formatTimeLowerCase } from '../../utils/formatDate';
import { msToHMin } from '../../utils/msToTime';

const CardItemWithClockIn = ({ job, time }) => {
  return (
    <div className="card">
      <div>
        <p className="heading">
          <span>{job.location.name}</span> {job.location.address}
          {job.location.city ? ',' : ''} {job.location.city}
          {job.location.province ? ',' : ''} {job.location.province}
          {job.location.postalCode}
        </p>
        <div className="tasks-completed">
          <span className="tag">
            {
              job.tasks.filter((item) => item.JobsTasks.taskStatusId === 1)
                .length
            }{' '}
            tasks
          </span>
          {job.comment && <img src={NotesImg} />}
          {job.img.length > 0 && <img src={ImageImg} />}
        </div>
      </div>
      <div>
        <span className="hours-worked">{msToHMin(time)}</span>
        <span className="start-stop-time">
          {formatTimeLowerCase(job.startDate)} -{' '}
          {formatTimeLowerCase(job.endDate)}
        </span>
      </div>
    </div>
  );
};

export default CardItemWithClockIn;
