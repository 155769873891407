import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, BrowserRouter } from 'react-router-dom';
import Alert from 'react-s-alert';
import { ROLE_MASTER } from './utils/users';
import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import ProfilePage from './pages/Profile';
import AllLocationsAdminPage from './pages/AllLocationsAdmin';
import LocationPage from './pages/LocationPage';
import WorkerPage from './pages/WorkerPage';
import JobsListAdminPage from './pages/JobsListAdmin';
import UsersPage from './pages/Users';
import AddUserPage from './pages/AddUser';
import ReportsPage from './pages/Reports';
import ReportsWeatherPage from './pages/ReportsWeather';
import ReportsTimePage from './pages/ReportsTime';
import CitiesPage from './pages/Cities/';
import TasksPage from './pages/TasksPage';
import EquipmentPage from './pages/EquipmentPage';
import GroupPage from './pages/GroupPage';
import GuestRoute from './components/GuestRoute';
import PrivateRoute from './components/PrivateRoute';
import SidebarLayout from './layouts/SidebarLayout';
import { SidebarLayoutProvider } from './layouts/SidebarLayout/context';
import { getUser } from './store/usersStore/actions';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import EquipmentProfile from './pages/EquipmentProfile';

class App extends React.Component {
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.props.getUser();
    }
  }

  render() {
    return (
      <Fragment>
        <Alert stack={{ limit: 3 }} position={'bottom-left'} />
        <BrowserRouter>
          <Switch>
            <GuestRoute exact path="/login" component={LoginPage} />
            <GuestRoute
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <GuestRoute
              exact
              path="/forgot/password"
              component={ResetPasswordPage}
            />
            <PrivateRoute
              exact
              path={[
                '/',
                '/jobs-list',
                '/profile',
                '/locations',
                '/users',
                '/reports',
                '/reports/weather/:city/:startDate/:endDate',
                '/reports/time/:startDate/:endDate/:locationId/:taskId/:userId',
                '/edit-location/:id',
                '/edit-user/:id',
                '/edit-equipment/:id',
                '/tasks',
                // '/group-workers',
                '/group/:id',
                '/equipment',
                '/cities',
              ]}
            >
              <SidebarLayoutProvider>
                <SidebarLayout>
                  <Switch>
                    <PrivateRoute exact path="/" component={DashboardPage} />
                    <PrivateRoute
                      exact
                      path="/jobs-list"
                      component={JobsListAdminPage}
                    />
                    <PrivateRoute exact path="/tasks" component={TasksPage} />
                    <PrivateRoute
                      exact
                      path="/equipment"
                      component={EquipmentPage}
                    />
                    <PrivateRoute exact path="/cities" component={CitiesPage} />
                    <PrivateRoute
                      exact
                      path="/group/:id"
                      component={GroupPage}
                    />
                    <PrivateRoute
                      exact
                      path="/profile"
                      component={ProfilePage}
                    />
                    <PrivateRoute
                      exact
                      path="/locations"
                      roles={[ROLE_MASTER]}
                      component={AllLocationsAdminPage}
                    />
                    <PrivateRoute
                      exact
                      path="/users"
                      roles={[ROLE_MASTER]}
                      component={UsersPage}
                    />
                    <PrivateRoute
                      exact
                      path="/reports"
                      roles={[ROLE_MASTER]}
                      component={ReportsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/reports/weather/:city/:startDate/:endDate"
                      roles={[ROLE_MASTER]}
                      component={ReportsWeatherPage}
                    />
                    <PrivateRoute
                      exact
                      path="/reports/time/:startDate/:endDate/:locationId/:taskId/:userId"
                      roles={[ROLE_MASTER]}
                      component={ReportsTimePage}
                    />
                    <PrivateRoute
                      exact
                      path={['/edit-location/:id']}
                      roles={[ROLE_MASTER]}
                      component={LocationPage}
                    />
                    <PrivateRoute
                      exact
                      path={['/edit-user/:id']}
                      roles={[ROLE_MASTER]}
                      component={WorkerPage}
                    />
                    <PrivateRoute
                      exact
                      path={['/edit-equipment/:id']}
                      roles={[ROLE_MASTER]}
                      component={EquipmentProfile}
                    />
                  </Switch>
                </SidebarLayout>
              </SidebarLayoutProvider>
            </PrivateRoute>
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path={['/add-location', '/edit-location/:id']}*/}
            {/*  roles={[ROLE_MASTER]}*/}
            {/*  component={AddLocationPage}*/}
            {/*/>*/}

            <PrivateRoute
              exact
              path={['/add-user']}
              roles={[ROLE_MASTER]}
              component={AddUserPage}
            />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  dataStore: { user },
  usersStore: { isLoadingValidate },
}) => ({
  user,
  isLoadingValidate,
});

const mapDispatchToProps = {
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
