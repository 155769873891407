import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import StyledSelect from '../../components/StyledSelect';
import { getDefaultValueForStyledSelect } from '../../utils/options';

export default class Form extends React.Component {
  render() {
    const {
      user,
      handleSubmit,
      formValues,
      bindSubmitForm,
      errorsForm,
      handleRemoveError,
    } = this.props;

    return (
      <Formik
        initialValues={{
          name: !isEmpty(formValues) ? formValues.name : user?.name,
          lastName: !isEmpty(formValues) ? formValues.lastName : user?.lastName,
          companyName: !isEmpty(formValues)
            ? formValues.companyName
            : user?.companyName,
          address: !isEmpty(formValues) ? formValues.address : user?.address,
          city: !isEmpty(formValues) ? formValues.city : user?.city,
          province: !isEmpty(formValues) ? formValues.province : user?.province,
          postalCode: !isEmpty(formValues)
            ? formValues.postalCode
            : user?.postalCode,
          phone: !isEmpty(formValues) ? formValues.phone : user?.phone,
          email: !isEmpty(formValues) ? formValues.email : user?.email,
          role: !isEmpty(formValues) ? formValues.role : user?.role,
          password: !isEmpty(formValues) ? formValues.password : user?.password,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().nullable(),
          // .trim()
          // .max(255, 'is too long')
          // .min(3, 'must be at least 3 characters')
          // .required('is required'),
          lastName: Yup.string().trim().nullable(),
          //.max(255, 'is too long'),
          address: Yup.string().nullable(),
          phone: Yup.string().nullable(),
          email: Yup.string().nullable(),
          //.email('is invalid').required('is required'),
          role: Yup.string().nullable(),
          //.required('is required'),
          password: Yup.string().nullable(),

          // pathname.includes('edit')
          // ? Yup.string().min(8, 'must be at least 8 characters').nullable()
          // : Yup.string()
          //     .min(8, 'must be at least 8 characters')
          //     .required('is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          } = props;
          bindSubmitForm(props.submitForm);

          const states = [{ label: 'ON', value: 'ON' }];
          const roles = [
            { label: 'Worker', value: 'worker' },
            { label: 'Supervisor', value: 'supervisor' },
          ];

          return (
            <form id="signup-form" noValidate="" onSubmit={handleSubmit}>
              <div className="two">
                <div
                  className={
                    (errors.name && touched.name) || errorsForm.name
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="name">First Name</label>
                  <input
                    id="name"
                    value={values.name}
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      handleRemoveError('name');
                    }}
                    onBlur={handleBlur}
                  />
                  <div className="form-error-message">
                    First Name {errors.name || errorsForm.name}
                  </div>
                </div>

                <div
                  className={
                    errors.lastName && touched.lastName
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    value={values.lastName}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="form-error-message">
                    Last Name {errors.lastName}
                  </div>
                </div>
              </div>
              <div
                className={
                  errors.companyName && touched.companyName
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="lastName">Company Name</label>
                <input
                  id="companyName"
                  value={values.companyName}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="form-error-message">
                  Company Name {errors.companyName}
                </div>
              </div>

              <div
                className={
                  errors.address && touched.address
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="address">Address</label>
                <input
                  id="address"
                  value={values.address}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="form-error-message">
                  Address {errors.address}
                </div>
              </div>

              <div className="three">
                <div
                  className={
                    errors.city && touched.city
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="city">City</label>
                  <input
                    className="city"
                    id="city"
                    value={values.city}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="form-error-message">City {errors.city}</div>
                </div>

                <div className="form-row">
                  <label htmlFor="province">Province</label>
                  <StyledSelect
                    value={getDefaultValueForStyledSelect(values.province)}
                    id="province"
                    name="province"
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    options={states}
                    field="name"
                  />
                </div>
                <div className="form-row">
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    className="postal-code"
                    defaultValue={values.postalCode}
                    type="text"
                    name="postalCode"
                    maxLength="6"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="two">
                <div
                  className={
                    errors.phone && touched.phone
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="name">Phone</label>
                  <input
                    id="phone"
                    defaultValue={values.phone}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="form-error-message">Phone {errors.phone}</div>
                </div>

                <div
                  className={
                    (errors.email && touched.email) || errorsForm.email
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    defaultValue={values.email}
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      handleRemoveError('email');
                    }}
                    onBlur={handleBlur}
                  />
                  <div className="form-error-message">
                    Email {errors.email || errorsForm.email}
                  </div>
                </div>
              </div>
              <div className="two">
                <div
                  className={
                    (errors.role && touched.role) || errorsForm.role
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="role">Role</label>
                  <StyledSelect
                    value={getDefaultValueForStyledSelect(values.role)}
                    id="role"
                    name="role"
                    onChange={(e) => {
                      handleChange(e);
                      handleRemoveError('role');
                    }}
                    setFieldValue={(name, value) => {
                      setFieldValue(name, value);
                      handleRemoveError('role');
                    }}
                    setFieldTouched={setFieldTouched}
                    options={roles}
                    field="name"
                  />
                  <div className="form-error-message">
                    Role {errors.role || errorsForm.role}
                  </div>
                </div>

                <div
                  className={
                    (errors.password && touched.password) || errorsForm.password
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    defaultValue={values.password}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder="••••••"
                    spellCheck="false"
                    type="password"
                    onChange={(e) => {
                      handleChange(e);
                      handleRemoveError('password');
                    }}
                    onBlur={handleBlur}
                  />
                  <div className="form-error-message">
                    Password {errors.password || errorsForm.password}
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}
