import React from 'react';
import Button from '@atlaskit/button';
import StyledMultiSelect from '../../components/StyledMultiSelect';
import { getOptionsTeamMembers } from '../../utils/getOptionsTeamMembers';
import { getInitials } from '../../utils/initials';
import { isEqual } from 'lodash';
import { ReactSVG } from 'react-svg';
import AddIconGrey from '../../assets/svgs/add-icon-grey.svg';

const Menu = (props) => {
  return <div {...props} />;
};
const Blanket = (props) => <div {...props} />;
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default class AssignSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      assignedUsers: [],
      options: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    const { users, listUsers } = this.props;
    if (users) {
      this.setState({ assignedUsers: getOptionsTeamMembers(users) });
    }

    if (listUsers && users) {
      let options = [];
      if (listUsers.length)
        options = getOptionsTeamMembers(
          listUsers.filter((user) => user.active !== 'inactive'),
        );

      if (users.length && !listUsers.length) options = users;

      let onlyValuesAssign = users.map(({ id }) => id);
      this.setState({
        options: options.sort(
          (a, b) =>
            onlyValuesAssign.indexOf(b.value) -
            onlyValuesAssign.indexOf(a.value),
        ),
      });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    // const { listUsers, handleSetUsers } = this.props;
    // const { assignedUsers } = this.state;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
      // if (!isEqual(listUsers, assignedUsers)) {
      //   const usersIds = assignedUsers.map(({ value }) => value);
      //   handleSetUsers(usersIds);
      // }
    }
  }

  toggleOpen = () => {
    const { listUsers } = this.props;
    const { assignedUsers } = this.state;
    // const usersIds = assignedUsers.map(({ value }) => value);
    // if (!this.state.isOpen && !listUsers.length) getTeamMembers({});
    // else
    // if (this.state.isOpen) updateEventAssignees({ eventNumber, usersIds });
    // else
    if (listUsers.length) {
      let onlyValuesAssign = assignedUsers.map(({ value }) => value);

      this.setState({
        options: getOptionsTeamMembers(
          listUsers.filter((user) => user.active !== 'inactive'),
        ).sort(function (a, b) {
          return (
            onlyValuesAssign.indexOf(b.value) -
            onlyValuesAssign.indexOf(a.value)
          );
        }),
      });
    }

    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  onSelectChange = (value) => {
    const { handleSetUsers } = this.props;
    const onlyValues = value.map((value) => value);
    const usersIds = onlyValues.map(({ value }) => value);
    handleSetUsers(usersIds);
    this.setState({
      assignedUsers: onlyValues,
    });
  };

  render() {
    const { listUsers, isLoading } = this.props;
    const { isOpen, assignedUsers, options } = this.state;

    return (
      <div ref={this.setWrapperRef}>
        <Dropdown
          isOpen={isOpen}
          onClose={this.toggleOpen}
          target={
            <Button
              className="button"
              style={{
                backgroundColor: '#fff',
                height: 'auto',
                padding: 0,
              }}
              onClick={this.toggleOpen}
              isSelected={isOpen}
            >
              <div>
                {assignedUsers.map((user, index) => {
                  const userItem = listUsers.find(
                    (item) => item.id === user.value,
                  );

                  return (
                    <div className="worker" key={index}>
                      <div
                        className="avatar"
                        style={{ background: user.color }}
                        key={user.value}
                      >
                        {userItem &&
                          getInitials({
                            name: userItem.name + ' ' + userItem.lastName,
                          })}
                      </div>
                      <div>
                        {(user && user.name) ||
                          (userItem && userItem.name + ' ' + userItem.lastName)}
                      </div>
                    </div>
                  );
                })}
                <div className="worker">
                  <div className="avatar">
                    <ReactSVG src={AddIconGrey} />
                  </div>
                  <div>Add worker</div>
                </div>
              </div>
            </Button>
          }
        >
          <StyledMultiSelect
            // assignedUsers={assignedUsers}
            noPaint={true}
            id="eventTypeName"
            name="eventTypeName"
            onChange={this.onSelectChange}
            isLoading={isLoading}
            data={options}
            value={assignedUsers}
          />
        </Dropdown>
      </div>
    );
  }
}
