import React from 'react';
import styled from 'styled-components';
import LocationSection from './LocationSection';
import TrashGrey from '../../assets/svgs/trash-grey.svg';
import { ReactSVG } from 'react-svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import SixDotsImg from '../../assets/svgs/six-dots.svg';

class LocationsTab extends React.Component {
  render() {
    const {
      id,
      getWorkersGroupById,
      assignLocationToGroup,
      removeLocationFromGroup,
      listAllLocations,
      handleLocation,
      myRef,
      // showAll,
      // handleShowAll,
      // removeLocation,
      getLocationItems,
      onDragEnd,
    } = this.props;

    const locationItems = getLocationItems();

    return (
      <Wrapper>
        {/*<CheckBlock className="checkbox">*/}
        {/*  <CustomizedCheckbox*/}
        {/*    name="showAll"*/}
        {/*    checked={showAll}*/}
        {/*    handleChecked={handleShowAll}*/}
        {/*  />*/}
        {/*  <div className="label">Show all locations tab</div>*/}
        {/*</CheckBlock>*/}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="QUESTIONS">
            {(provided) => (
              <StyledDiv ref={provided.innerRef}>
                {locationItems.map((location, index) => (
                  <Draggable
                    key={location.id.toString()}
                    draggableId={location.id.toString()}
                    index={index}
                    isDragDisabled
                  >
                    {(provided) => (
                      <MainBlock
                        key={location.id.toString()}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // style={getItemStyle(provided.draggableProps.style)}>
                      >
                        <ReactSVG
                          className="drag"
                          // src={SixDotsImg}
                          {...provided.dragHandleProps}
                        />
                        <p className="heading">
                          <div className="main">{location.name}</div>
                          <div className="full-address">
                            {' '}
                            {location.address}
                            {location.city ? ',' : ''} {location.city}
                            {location.province ? ',' : ''} {location.province}
                            {location.postalCode}
                          </div>
                        </p>
                        <TrashWrap
                          onClick={() => {
                            removeLocationFromGroup(id, {
                              locationId: location.id,
                            });
                            setTimeout(() => {
                              getWorkersGroupById(id);
                            }, 100);
                          }}
                        >
                          <ReactSVG src={TrashGrey} />
                        </TrashWrap>
                      </MainBlock>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </StyledDiv>
            )}
          </Droppable>
        </DragDropContext>

        <LocationSection
          id={id}
          getWorkersGroupById={getWorkersGroupById}
          assignLocationToGroup={assignLocationToGroup}
          removeLocationFromGroup={removeLocationFromGroup}
          locations={locationItems.map(({ id }) => id)}
          handleSetlocations={handleLocation}
          listAllLocations={listAllLocations}
          ref={myRef}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 32px;
  .main {
    color: #333333;
    font-size: 18px;
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .heading {
    margin: 0;
  }
`;

const MainBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  color: #777777;
  cursor: pointer;

  padding: 15px 20px;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: relative;
  padding-left: 40px;

  .drag {
    position: absolute;
    left: 0;
    padding: 30px 15px 30px 15px;
  }
`;

const TrashWrap = styled.div``;
const StyledDiv = styled.div`
  overflow: scroll;
  height: 250px;
  margin-bottom: 24px;
`;

export default LocationsTab;
