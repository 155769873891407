import React from 'react';
import styled from 'styled-components';
import MenuDots from '../../assets/svgs/menu-image.svg';
import { ReactSVG } from 'react-svg';
import TaskBlock from './TaskBlock';
import DropDownCategory from './DropDownCategory';

export default class WorkPlace extends React.Component {
  state = {
    openMenu: false,
  };
  handleOpenMenu = (e) => {
    e.stopPropagation();

    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };

  render() {
    const {
      season,
      placeId,
      handleOpenCategoryPopup,
      deleteTaskPlace,
      handleOpenTaskPopup,
      updateTask,
      deleteTask,
    } = this.props;
    const { openMenu } = this.state;
    return (
      <div>
        <WrapHead>
          <h2 style={{ fontFamily: 'Roboto' }}>{season[placeId].place.name}</h2>

          <a className="card-menu" onClick={this.handleOpenMenu}>
            <ReactSVG className="dropdown" src={MenuDots} />
            {openMenu && (
              <DropDownCategory
                handleOpenCategoryPopup={handleOpenCategoryPopup}
                category={season[placeId].place}
                deleteTaskPlace={deleteTaskPlace}
                onClose={this.handleOpenMenu}
                // handleOpenTaskPopup={handleOpenTaskPopup}
                // task={task}
              />
            )}
          </a>
        </WrapHead>
        <WrapperTasks>
          {season[placeId]?.tasks?.map((task, index) => (
            <TaskBlock
              deleteTask={deleteTask}
              updateTask={updateTask}
              last={season[placeId]?.tasks.length - 1 === index}
              handleOpenTaskPopup={(e) => handleOpenTaskPopup(e, task)}
              task={task}
              key={task.id}
            />
          ))}
        </WrapperTasks>
      </div>
    );
  }
}

const WrapperTasks = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const WrapHead = styled.div`
  position: relative;
  .card-menu {
    top: -9px;
  }
`;
