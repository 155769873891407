import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseSVG from '../../../assets/images/close.svg';
import Form from './Form';
import Modal from '../../Modal';

const AddLocationPopup = ({ onClose, handleSubmit, locationItem }) => {
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <Wrapper id="add-location-modal" className="modal">
          <div className="modal-header">
            <div className="modal-title">
              {locationItem ? 'Edit' : 'Add'} Location
            </div>
            <div className="modal-close" onClick={onClose}>
              <ReactSVG src={CloseSVG} />
            </div>
          </div>
          <div className="modal-body left-align">
            <Form handleSubmit={handleSubmit} locationItem={locationItem} />
          </div>
          <div className="cta-bar">
            <button type="submit" className="btn" form="signup-form">
              {locationItem ? 'Save' : 'Add'}
            </button>
          </div>
        </Wrapper>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div`
  #province {
    width: 120px;
  }
  .modal-body {
    overflow: visible !important;
  }
`;

export default AddLocationPopup;
