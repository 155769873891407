import * as types from './constants';
import { removeToken } from '../../services/api/storage';

const initialState = {
  error: false,
  isLoading: false,
  user: null,
  userItem: null,
  listUsers: [],
  logout: false,
  isUserDeleted: false,
  isUserAdded: false,
  isUserUpdated: false,
  isUserLoading: false,
  isUserRestored: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
      };
    case types.GET_USER_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_USER_BY_ID_REQUEST:
      return { ...state, isLoading: true, isUserLoading: true };
    case types.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userItem: payload,
        isLoading: false,
        isUserLoading: false,
      };
    case types.GET_USER_BY_ID_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingLocation: false,
        error: payload,
      };

    case types.GET_ALL_USERS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        listUsers: payload,
        isLoading: false,
      };
    case types.GET_ALL_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.DELETE_USER_REQUEST:
      return { ...state, isLoading: true, isUserDeleted: false };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        isUserDeleted: true,
        isLoading: false,
      };
    case types.DELETE_USER_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.ADD_USER_REQUEST:
      return { ...state, isLoading: true, isUserAdded: false, error: false };
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        userItem: payload,
        isUserAdded: true,
        isLoading: false,
      };
    case types.ADD_USER_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.UPDATE_USER_REQUEST:
      return { ...state, isLoading: true, isUserUpdated: false, error: false };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        userItem: payload.user,
        isLoading: false,
        isUserUpdated: true,
      };
    case types.UPDATE_USER_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.RESTORE_USER_REQUEST:
      return { ...state, isLoading: true, isUserRestored: false };
    case types.RESTORE_USER_SUCCESS:
      return {
        ...state,
        userItem: payload,
        isUserRestored: true,
        isLoading: false,
      };
    case types.RESTORE_USER_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.CLEAN_USER:
      return initialState;

    case types.USER_LOGOUT:
      removeToken();
      return initialState;

    default:
      return state;
  }
};
