export const getCSVFile = (blob, name) => {
  let newBlob = new Blob([blob], {
    type: '.csv',
  });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  let link = document.createElement('a');

  link.href = data;
  link.download = name;
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
};
