import React from 'react';
import styled from 'styled-components';

export const getOptionsLocations = (arr) => {
  if (arr.length > 0) {
    return arr.map(({ id: value, name, address }) => ({
      value,
      label: (
        <Wrap>
          <div>{name}</div>
          <div className="address">{address}</div>
        </Wrap>
      ),
    }));
  } else return [];
};

const Wrap = styled.div`
  display: block;
  .address {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    color: #999999;
  }
  div {
    display: flex;
    align-items: center;
  }
`;
