import { connect } from 'react-redux';
import AddUserPage from './AddUserPage';
import {
  addUser,
  getUserById,
  updateUser,
  cleanUser,
  restoreUser,
} from '../../store/usersStore/actions';
import {
  getAllLocations,
  assignLocation,
} from '../../store/locationsStore/actions';

const mapStateToProps = ({
  usersStore: {
    isUserAdded,
    userItem,
    isUserLoading,
    isUserUpdated,
    isUserRestored,
    error,
  },
  locationsStore: { listAllLocations, isLoading, isAssignedLocation },
}) => ({
  isUserAdded,
  userItem,
  isUserLoading,
  isUserUpdated,
  isUserRestored,
  error,
  listAllLocations,
  isLoading,
  isAssignedLocation,
});

const mapDispatchToProps = {
  addUser,
  getUserById,
  updateUser,
  cleanUser,
  restoreUser,
  getAllLocations,
  assignLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPage);
