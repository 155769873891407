import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import MenuDots from '../../assets/svgs/menu-image.svg';
import DropDownGroup from './DropDownGroup';
// import AddGroupPopup from '../../components/Popups/AddGroupPopup';

class GroupItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  handleOpenMenu = (e) => {
    e.stopPropagation();

    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };

  render() {
    const {
      history,
      group,
      deleteWorkerGroup,
      handleAddGroupPopup,
    } = this.props;
    const { openMenu } = this.state;

    return (
      <Wrapper onClick={() => history.push(`/group/${group.workersGroupId}`)}>
        <span className="group">{group.name}</span>
        <a className="card-menu" onClick={this.handleOpenMenu}>
          <ReactSVG className="dropdown" src={MenuDots} />
          {openMenu && (
            <DropDownGroup
              deleteWorkerGroup={deleteWorkerGroup}
              handleAddGroupPopup={handleAddGroupPopup}
              group={group}
            />
          )}
        </a>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  position: relative;
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 5px;
  .card-menu {
    top: 2px;
  }
`;

export default withRouter(GroupItem);
