export const getDefaultValueForStyledSelect = (value, name) => {
  if (name) {
    return value
      ? {
          value: value[name],
          label: value[name],
        }
      : null;
  }

  return value
    ? {
        value: value,
        label: value,
      }
    : null;
};

export const getDefaultValueForWeatherSelect = ({ name }) => {
  return {
    value: name,
    label: name,
  };
};

export const getDefaultValueForLocationsSelect = ({ id, name, address }) => {
  return {
    value: id,
    label: name + ' ' + address,
  };
};

export const getDefaultValueForUsersSelect = ({ id, name, lastName }) => {
  return {
    value: id,
    label: name + ' ' + lastName,
  };
};
