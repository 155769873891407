import React from 'react';
import { getInitials } from '../../utils/initials';
import { ReactSVG } from 'react-svg';
import ImageImg from '../../assets/svgs/imageImg.svg';
import NotesImg from '../../assets/svgs/noteImg.svg';
import { formatTimeLowerCase } from '../../utils/formatDate';
import MenuImg from '../../assets/svgs/menu.svg';
import DropDownJob from '../JobsListAdmin/DropDownJob';
import DeleteJobPopup from '../../components/Popups/DeleteJobPopup';
import { msToHMin } from '../../utils/msToTime';
import WorkersGroup from '../../assets/svgs/workersGroup.svg';

export default class JobBlock extends React.Component {
  state = {
    openMenu: false,
    openConfirmDelete: false,
  };

  handleDelete = () => {
    this.setState({
      openMenu: false,
      openConfirmDelete: !this.state.openConfirmDelete,
    });
  };

  handleOpenMenu = () => {
    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };

  handleActivitiesPopup = () => {
    this.setState({ openMenu: false });
  };

  render() {
    const { time, job, deleteJob } = this.props;
    const { openMenu, openConfirmDelete } = this.state;
    return (
      <div className="card">
        <div>
          <div className="worker">
            {job.workersGroup?.name ? (
              <>
                <ReactSVG
                  style={{ marginRight: 10, width: 20 }}
                  src={WorkersGroup}
                />
                <span>{job.workersGroup.name}</span>
              </>
            ) : (
              <>
                <div className="avatar" style={{ background: job.user.color }}>
                  {getInitials({
                    name: job.user.name + ' ' + job.user.lastName,
                  })}
                </div>
                <span>{job.user.name + ' ' + job.user.lastName}</span>
              </>
            )}
          </div>
          <div className="tasks-completed">
            <span className={job.workersGroup?.name ? 'tag2' : 'tag'}>
              {
                job.tasks.filter((item) => item.JobsTasks.taskStatusId === 1)
                  .length
              }{' '}
              tasks
            </span>
            {job.img.length > 0 && <img src={ImageImg} />}
            {job.comment && <img src={NotesImg} />}
          </div>
        </div>
        <div>
          <span className="hours-worked">{msToHMin(time)}</span>
          <span className="start-stop-time">
            {formatTimeLowerCase(job.startDate)} -{' '}
            {!job.endDate ? 'in work' : formatTimeLowerCase(job.endDate)}
          </span>
        </div>
        <a className="card-menu" onClick={this.handleOpenMenu}>
          <ReactSVG src={MenuImg} />
          {openMenu && (
            <DropDownJob
              handleDelete={this.handleDelete}
              handleEdit={this.handleActivitiesPopup}
              onClose={this.handleOpenMenu}
              notEditing={true}
            />
          )}
        </a>
        <DeleteJobPopup
          open={openConfirmDelete}
          onClose={this.handleDelete}
          job={job}
          deleteJob={deleteJob}
        />
      </div>
    );
  }
}
