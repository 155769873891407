import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import MenuDots from '../../assets/svgs/menu-image.svg';
import DropDownWorker from './DropDownWorker';
import { getInitials } from '../../utils/initials';
class GroupPage extends React.Component {
  state = {
    openDrop: false,
  };

  handleOpenDrop = () => {
    setTimeout(() => {
      this.setState({ openDrop: !this.state.openDrop });
    }, 10);
  };

  render() {
    const {
      user,
      lead,
      id,
      removeWorkerFromGroup,
      updateWorkerOnGroup,
    } = this.props;
    const { openDrop } = this.state;

    return (
      <Wrapper>
        <div className="wrap">
          {/*<div className="avatar" style={{ background: job.user.color }}>*/}
          {/*  {getInitials({*/}
          {/*    name: user.name + ' ' + job.user.lastName,*/}
          {/*  })}*/}
          {/*</div>*/}
          <div className="worker">
            <div className="avatar" style={{ background: user.color }}>
              {getInitials({
                name: user.name + ' ' + user.lastName,
              })}
            </div>
            <span>{user.name + ' ' + user.lastName}</span>
          </div>
          {user.WorkersGroupUserAssign?.userRole?.toUpperCase() ===
            'CREW LEAD' && <span className="grey">Crew Lead</span>}
        </div>
        <a className="card-menu" onClick={this.handleOpenDrop}>
          <ReactSVG className="dropdown" src={MenuDots} />
          {openDrop && (
            <DropDownWorker
              id={id}
              lead={lead}
              removeWorkerFromGroup={removeWorkerFromGroup}
              updateWorkerOnGroup={updateWorkerOnGroup}
              user={user}
              onClose={this.handleOpenDrop}
            />
          )}
        </a>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 52px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  .worker:not(:last-child) {
    margin-bottom: 0;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 13%;
    align-items: center;
  }
  .grey {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #777777;
  }
  .card-menu {
    top: 0;
  }
  span {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
`;

export default withRouter(GroupPage);
