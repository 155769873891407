import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import LogoImg from '../../assets/images/logo.png';
import Form from './Form';
import { isEmpty } from 'lodash';

class LoginPage extends React.Component {
  state = {
    errorInForm: false,
  };
  componentDidUpdate(prevProps) {
    const { error, token, getUser } = this.props;

    if (!isEmpty(token) && isEmpty(prevProps.token)) {
      getUser();
    }

    if (!prevProps.error && error) {
      this.setState({ errorInForm: true });
    }
  }

  render() {
    const { loginUser, isLoading } = this.props;
    const { errorInForm } = this.state;

    return (
      <div>
        <Logo className="logo">
          <img src={LogoImg} />
        </Logo>
        <Wrap className="card login">
          <p className="card-title">LOGIN</p>
          <Form
            loginUser={loginUser}
            isLoading={isLoading}
            errorInForm={errorInForm}
          />
        </Wrap>
      </div>
    );
  }
}
const Wrap = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-top: 80px;
  .card-title {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    width: unset;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    margin-block-end: 1em;
  }
`;

const Logo = styled.div`
  img {
    width: 120px;
  }
  text-align: center;
  margin: 20px 0;
`;

export default withRouter(LoginPage);
