import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AddTimeInactiveImg from '../../assets/svgs/add-time-inactive.svg';
import { ReactSVG } from 'react-svg';

const pad = (number) => {
  return number < 10 ? '0' + number : number;
};

const convertToAMPM = (hours) => {
  return hours % 12 || 12;
};

const daysAgo = (dataNow, startTime) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const setDay = new Date(
    startTime.y,
    startTime.m,
    startTime.d,
    startTime.h,
    startTime.min,
    startTime.sec,
  );

  const difference = dataNow - +startTime.d;

  if (currentMonth === +startTime.m && currentYear === +startTime.y) {
    if (difference < 1) return 'Today';
    else if (difference === 1) return 'Yesterday';
    else if (difference < 7) {
      return days[setDay.getDay()];
    } else {
      return moment(setDay).format('MMMM DD');
    }
  } else {
    return moment(setDay).format('MMMM DD');
  }
};

export default class TimeSection extends React.Component {
  state = {
    startTime: {},
    stopTime: {},
    showAddInputStart: false,
    showAddInputEnd: false,
  };

  componentDidMount() {
    const { job } = this.props;
    let startDate, endDate;
    if (job) {
      startDate = job.startDate;
      endDate = job.endDate;
    }

    let start, startDay, startHour, startMin, startSec;
    let stop, stopDay, stopHour, stopMin, stopSec;

    if (job) {
      start = new Date(startDate);
      startDay = start.getDate();
      startHour = start.getHours();
      startMin = start.getMinutes();
      startSec = start.getSeconds();

      stop = new Date(endDate);
      stopDay = stop.getDate();
      stopHour = stop.getHours();
      stopMin = stop.getMinutes();
      stopSec = stop.getSeconds();

      this.setState({
        startTime: {
          y: start.getFullYear(),
          m: start.getMonth(),
          d: +startDay,
          h: +startHour,
          min: +startMin,
          sec: +startSec,
        },
        stopTime: {
          y: stop.getFullYear(),
          m: stop.getMonth(),
          d: +stopDay,
          h: +stopHour,
          min: +stopMin,
          sec: +stopSec,
        },
      });
    }
  }

  handleChangeStartTime = (event) => {
    const { handleChangeTime } = this.props;
    if (!event || !event.target || !event.target.value) return;
    const setDate = new Date(event.target.value);
    const hours = setDate.getHours();
    const minutes = setDate.getMinutes();

    let dateForStart = setDate.getDate();

    this.setState(
      {
        startTime: {
          y: setDate.getFullYear(),
          m: setDate.getMonth(),
          d: dateForStart,
          h: hours,
          min: minutes,
          sec: 0,
        },
      },
      () => {
        handleChangeTime();
      },
    );
  };

  handleChangeStopTime = (event) => {
    const { handleChangeTime } = this.props;

    if (!event.target || !event.target.value) {
      return;
    }
    const setDate = new Date(event.target.value);

    const hours = setDate.getHours();
    const minutes = setDate.getMinutes();
    let dateForStop = setDate.getDate();

    this.setState(
      {
        stopTime: {
          y: setDate.getFullYear(),
          m: setDate.getMonth(),
          d: dateForStop,
          h: hours,
          min: minutes,
          sec: 0,
        },
      },
      () => {
        handleChangeTime();
      },
    );
  };

  render() {
    const { add } = this.props;
    const {
      startTime,
      stopTime,
      showAddInputStart,
      showAddInputEnd,
    } = this.state;

    const dataNow = +new Date().getDate();
    const dayLimit = new Date().toISOString().slice(0, 10);

    return (
      <WrapAll className="card">
        <div>
          <span>Start</span>
          {isEmpty(startTime) && ( //|| isHidden
            <ClickAwayListener
              onClickAway={() => this.setState({ showAddInputStart: false })}
            >
              <Time
                hidden={showAddInputStart}
                onClick={
                  () => this.setState({ showAddInputStart: true }) // !isHidden &&
                }
                // notSet={isHidden}
              >
                <input
                  type="datetime-local"
                  id="start-time"
                  max={`${dayLimit}T23:59`}
                  onInput={this.handleChangeStartTime}
                  onClick={() => {
                    setTimeout(() => {
                      document.querySelector('.card').click();
                    }, 1000);
                  }}
                />

                <ReactSVG
                  className="clock"
                  src={AddTimeInactiveImg}
                  wrapper="span"
                />
                <span
                  className={
                    'active start' // isHidden ? 'active start hidden' : 'active start'
                  }
                >
                  Add Time
                </span>
              </Time>
            </ClickAwayListener>
          )}
          {!isEmpty(startTime) && ( //&& !isHidden
            <ClickAwayListener
              onClickAway={() => this.setState({ showAddInputStart: false })}
            >
              <TimeSet
                add={add}
                hidden={showAddInputStart}
                onClick={() => this.setState({ showAddInputStart: true })}
              >
                <div className="time">
                  {convertToAMPM(startTime.h) + ':' + pad(startTime.min)}
                  <span>{startTime.h >= 12 ? ' PM' : ' AM'}</span>
                </div>

                <input
                  type="datetime-local"
                  id="start-time"
                  max={`${dayLimit}T23:59`}
                  onInput={this.handleChangeStartTime}
                  onClick={() => {
                    setTimeout(() => {
                      document.querySelector('.card').click();
                    }, 1000);
                  }}
                />
                <span className="day">{daysAgo(dataNow, startTime)}</span>
              </TimeSet>
            </ClickAwayListener>
          )}
        </div>

        <div className="end-block">
          <span>End</span>
          {isEmpty(stopTime) && ( //|| isHidden
            <ClickAwayListener
              onClickAway={() => this.setState({ showAddInputEnd: false })}
            >
              <Time
                end
                notSet={isEmpty(startTime)} // || isHidden
                hidden={showAddInputEnd}
                onClick={() => {
                  if (!isEmpty(startTime))
                    //&& !isHidden
                    this.setState({ showAddInputEnd: true });
                }}
              >
                <label>
                  <span>Label text</span>

                  <input
                    type="datetime-local"
                    id="stop-time"
                    max={`${dayLimit}T23:59`}
                    disabled={isEmpty(startTime)}
                    onInput={this.handleChangeStopTime}
                    onClick={() => {
                      setTimeout(() => {
                        document.querySelector('.card').click();
                      }, 1000);
                    }}
                  />
                </label>
                <ReactSVG
                  className="clock"
                  src={AddTimeInactiveImg}
                  wrapper="span"
                />
                {/*)}*/}
                <span
                  className={
                    'active stop' // isHidden ? 'active stop hidden' : 'active stop'
                  }
                >
                  Add Time
                </span>
              </Time>
            </ClickAwayListener>
          )}
          {!isEmpty(stopTime) && ( //&& !isHidden
            <ClickAwayListener
              onClickAway={() => this.setState({ showAddInputEnd: false })}
            >
              <TimeSet
                add={add}
                hidden={showAddInputEnd}
                onClick={() => this.setState({ showAddInputEnd: true })}
              >
                <div className="time">
                  {convertToAMPM(stopTime.h) + ':' + pad(stopTime.min)}
                  <span>{stopTime.h >= 12 ? ' PM' : ' AM'}</span>
                </div>

                <input
                  type="datetime-local"
                  id="stop-time"
                  max={`${dayLimit}T23:59`}
                  disabled={isEmpty(startTime)}
                  onInput={this.handleChangeStopTime}
                  onClick={() => {
                    setTimeout(() => {
                      document.querySelector('.card').click();
                    }, 1000);
                  }}
                />
                <span className="day">{daysAgo(dataNow, stopTime)}</span>
              </TimeSet>
            </ClickAwayListener>
          )}
        </div>
      </WrapAll>
    );
  }
}

const TimeSet = styled.div`
  margin-top: 10px;
  margin-bottom: ${({ add }) => (add ? '0' : '45px')};
  position: relative;
  display: ${({ hidden }) => (hidden ? 'block' : 'inherit')};
  .time {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    span {
      font-size: 12px;
    }
  }
  #start-time,
  #stop-time {
    position: absolute;
    opacity: ${({ hidden }) => (hidden ? '1' : '0')};
    top: 0;
  }
  input {
    border: none;
    outline: none;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    width: 98px;
    background: transparent;
  }
  .time {
    position: relative;
    opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  }
  label {
    span {
      display: none;
    }
  }
  .day {
    color: #999999;
    text-transform: uppercase;
  }
`;

const Time = styled.div`
  position: relative;
  height: ${({ hidden, end }) => (hidden && end ? 'unset' : '72px')};
  display: flex;

  align-items: center;
  #start-time,
  #stop-time {
    cursor: ${({ notSet }) => (notSet ? 'not-allowed' : 'pointer')};
    position: absolute;
    opacity: ${({ hidden }) => (hidden ? '1' : '0')};
  }
  input {
    border: none;
    outline: none;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    width: 98px;
    background: transparent;
  }
  .active,
  .clock {
    margin-right: 10px;
    display: ${({ hidden }) => (hidden ? 'none' : 'inherit')};
  }
  .active.hidden {
    color: #cccccc;
  }
  label {
    span {
      display: none;
    }
  }
`;

const WrapAll = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 30px;

  .end-block {
    min-width: 98px;
  }
`;
