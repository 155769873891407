import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

export default class Form extends React.Component {
  state = {
    values: null,
  };

  handleSubmit = (values) => {
    this.props.forgotPassword(values);
  };

  render() {
    const { goBack, isLoading } = this.props;

    return (
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('The email address is invalid')
            .required('Email is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form noValidate="" onSubmit={handleSubmit}>
              <div
                className={
                  errors.email && touched.email
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <Label htmlFor="email">Email</Label>
                <input
                  id="email"
                  value={values['email']}
                  placeholder="example@site.com"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Error className="form-error-message">{errors.email}</Error>
              </div>
              <div className="form-row">
                <button
                  disabled={!isEmpty(errors) || isLoading}
                  className="btn"
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
              <p className="links center">
                <a className="cursor" onClick={goBack}>
                  Back to login
                </a>
              </p>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const Error = styled.div`
  color: var(--alert);
`;

const Label = styled.label`
  color: #000 !important;
`;
