import React, { Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const themeLight = createMuiTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'unset',
      },
    },
    MuiPaper: {
      root: {
        border: '1px solid #DDDDDD',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05) !important',
        borderRadius: '8px !important',
      },
    },
    MuiTypography: {
      root: {
        width: 540,
        height: 234,
        overflowX: 'scroll',
        padding: '27px 23px !important',
      },
    },
  },
});
const ChangeSelectedTasksPopup = ({
  open,
  onClose,
  tasks,
  idGroup,
  assignTaskToGroup,
  assignedTasksIds,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTasks, setSetSelectedTasks] = React.useState(assignedTasksIds);

  React.useEffect(() => {
    setSetSelectedTasks(assignedTasksIds);
  }, [assignedTasksIds]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    assignTaskToGroup(idGroup, { taskIds: selectedTasks });
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  return (
    <MuiThemeProvider theme={themeLight}>
      <Modal>
        <div className="modal-blur" />
        <StyledDialog
          PaperProps={{
            style: {
              overflow: 'visible',
            },
          }}
          open={open}
          onClose={onClose}
        >
          <div className="modal-overlay">
            <div id="add-location-modal" className="modal">
              <div className="modal-header">
                <div className="modal-title">Select Tasks</div>
                <div className="modal-close" onClick={onClose}>
                  <ReactSVG src={CloseSVG} />
                </div>
              </div>
              <div
                style={{ overflow: 'visible' }}
                className="modal-body left-align"
              >
                {Object.keys(tasks).map((placeId) => {
                  const display = tasks[placeId].tasks?.some((task) =>
                    selectedTasks.includes(task.id),
                  );
                  return (
                    <Fragment key={placeId}>
                      {display && (
                        <WarpIn>
                          <Category>{tasks[placeId].place.name}</Category>
                          <div className="wrap-tasks">
                            {tasks[placeId].tasks?.map(
                              (task) =>
                                selectedTasks.includes(task.id) && (
                                  <span key={task.id}>{task.name}</span>
                                ),
                            )}
                          </div>{' '}
                        </WarpIn>
                      )}
                    </Fragment>
                  );
                })}
                <Add onClick={handleClick}>ADD TASKS</Add>
                <Popover
                  id={id}
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography}>
                    {Object.keys(tasks).map((placeId) => {
                      return (
                        <Fragment key={placeId}>
                          <Section>{tasks[placeId].place.name}</Section>

                          {tasks[placeId].tasks?.map((task) => (
                            <Wrapper key={task.id} className="form-group">
                              <input
                                onClick={(event) => {
                                  const index = selectedTasks.indexOf(task.id);
                                  const tempArr = [...selectedTasks];
                                  if (index !== -1) {
                                    tempArr.splice(index, 1);

                                    setSetSelectedTasks(tempArr);
                                  } else {
                                    setSetSelectedTasks([
                                      ...selectedTasks,
                                      Number(event.target.value),
                                    ]);
                                  }
                                }}
                                id={`${tasks[placeId].place.name}-${task.name}`}
                                type="checkbox"
                                defaultChecked={selectedTasks.includes(task.id)}
                                value={task.id}
                              />
                              <label
                                htmlFor={`${tasks[placeId].place.name}-${task.name}`}
                              >
                                {task.name}
                              </label>
                            </Wrapper>
                          ))}
                        </Fragment>
                      );
                    })}
                  </Typography>
                </Popover>
                <div className="cta-bar">
                  <button className="btn" onClick={handleSave}>
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </StyledDialog>
      </Modal>
    </MuiThemeProvider>
  );
};

const StyledDialog = styled(Dialog)`
  height: 266px;
  .btn {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

const Wrapper = styled.div`
  display: block;
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
  }
  label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 12.7px;
    border: solid #4979c1;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Section = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
  color: #333333;
`;

const Category = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-right: 34px;
  width: 100px;
`;

const WarpIn = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span:after {
    content: ', ';
  }

  span:last-child:after {
    content: '';
  }
  .wrap-tasks {
    width: 400px;
  }
`;

const Add = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  color: #54a01d;
`;

export default ChangeSelectedTasksPopup;
