import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.GET_WORKERS_GROUP_REQUEST, handleRequest),
  takeEvery(types.ADD_WORKERS_GROUP_REQUEST, handleRequest),
  takeEvery(types.UPDATE_WORKERS_GROUP_REQUEST, handleRequest),
  takeEvery(types.DELETE_WORKERS_GROUP_REQUEST, handleRequest),
  takeEvery(types.GET_WORKERS_GROUP_BY_ID_REQUEST, handleRequest),
  takeEvery(types.ASSIGN_WORKERS_TO_GROUP_REQUEST, handleRequest),
  takeEvery(types.REMOVE_WORKERS_FROM_GROUP_REQUEST, handleRequest),
  takeEvery(types.ASSIGN_TASK_TO_GROUP_REQUEST, handleRequest),
  takeEvery(types.REMOVE_TASK_FROM_GROUP_REQUEST, handleRequest),
  takeEvery(types.GET_TASK_FROM_GROUP_REQUEST, handleRequest),
  takeEvery(types.REMOVE_LOCATION_FROM_GROUP_REQUEST, handleRequest),
  takeEvery(types.ASSIGN_LOCATION_TO_GROUP_REQUEST, handleRequest),
  takeEvery(types.UPDATE_WORKERS_ON_GROUP_REQUEST, handleRequest),
]);
