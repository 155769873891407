import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { ROLE_WORKER, ROLE_SUPERVISOR } from '../../utils/users';
import TrashRed from '../../assets/svgs/trash-red.svg';
import SetAsImg from '../../assets/svgs/set-as.svg';
import CloseRed from '../../assets/svgs/close-red.svg';
import CheckGrey from '../../assets/svgs/check-grey.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class DropDownUsers extends React.Component {
  render() {
    const { onClose, updateUser, user, handlePopupDelete } = this.props;
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper>
          {user?.role !== ROLE_WORKER && (
            <Wrap
              className="role"
              onClick={() => {
                let userValue = {
                  id: user.id,
                  role: ROLE_WORKER,
                };
                updateUser(userValue);
                onClose();
              }}
            >
              <ReactSVG src={SetAsImg} />
              <span>Set as Worker</span>
            </Wrap>
          )}
          {user?.role !== ROLE_SUPERVISOR && (
            <Wrap
              className="role"
              onClick={() => {
                let userValue = {
                  id: user.id,
                  role: ROLE_SUPERVISOR,
                };
                updateUser(userValue);
                onClose();
              }}
            >
              <ReactSVG src={SetAsImg} />
              <span>Set as Supervisor</span>
            </Wrap>
          )}
          {user?.role !== 'mechanic' && user.role !== ROLE_SUPERVISOR && (
            <Wrap
              className="role"
              onClick={() => {
                let userValue = {
                  id: user.id,
                  role: 'mechanic',
                };
                updateUser(userValue);
                onClose();
              }}
            >
              <ReactSVG src={SetAsImg} />
              <span>Set as Mechanic</span>
            </Wrap>
          )}
          <Wrap
            className="role"
            onClick={() => {
              const userValue = {
                id: user.id,
                active: user.active === 'active' ? 'inactive' : 'active',
              };
              updateUser(userValue);
              onClose();
            }}
          >
            {user.active === 'active' ? (
              <ReactSVG src={CloseRed} />
            ) : (
              <ReactSVG src={CheckGrey} />
            )}
            <span>
              {user.active === 'active' ? 'Set Inactive' : 'Set Active'}
            </span>
          </Wrap>
          <Wrap
            onClick={() => {
              handlePopupDelete(user.id);
            }}
          >
            <ReactSVG src={TrashRed} />
            <span>Delete</span>
          </Wrap>
        </Wrapper>
      </ClickAwayListener>
    );
  }
}

const Wrap = styled.div`
  display: flex;
  height: 75px;
  padding: 18px 22px;
  align-items: center;

  span {
    padding-left: 16px;
    padding-top: 1px;
  }
`;

const Wrapper = styled.div`
  z-index: 999;
  color: #3b494c;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 17px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  right: 10px;
  min-height: 100px;
  width: 246px;
  position: absolute;
  .role {
    border-bottom: 1px solid #c4c4c4;
  }
  .active {
    border-top: 1px solid #c4c4c4;
  }
`;

export default DropDownUsers;
