import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import WeatherBlock from './Weather';
import TimeBlock from './Time';

class ReportsPage extends React.Component {
  state = {
    tab: 1,
  };

  componentDidMount() {
    const {
      getCities,
      getAllLocations,
      getUsers,
      getLocationsTasks,
    } = this.props;
    getCities();
    getAllLocations();
    getUsers();
    getLocationsTasks();
  }

  handleChangeTab = (number) => {
    this.setState({
      tab: number,
    });
  };

  render() {
    const {
      history,
      listCities,
      listAllLocations,
      listUsers,
      listTasks,
    } = this.props;
    const { tab } = this.state;

    return (
      <div className="container change">
        <Wrapper>
          <div className="tabs">
            <div
              className={tab === 1 && 'active'}
              onClick={() => this.handleChangeTab(1)}
            >
              Time
            </div>
            <div
              className={tab === 2 && 'active'}
              onClick={() => this.handleChangeTab(2)}
            >
              Weather
            </div>
          </div>
          {tab === 2 && (
            <WeatherBlock history={history} listCities={listCities} />
          )}
          {tab === 1 && (
            <TimeBlock
              history={history}
              listTasks={listTasks}
              listAllLocations={listAllLocations}
              listUsers={listUsers}
            />
          )}
        </Wrapper>
      </div>
    );
  }
}

const Wrapper = styled.div`
  .tabs {
    width: 100%;
    display: flex;
    font-size: 18px;
    font-family: 'Montserrat', sans-sans-serif;
    margin: 20px 0 30px;
    color: #777;
    font-weight: 700;
    justify-content: center;
    position: relative;
  }
  .tabs > div:first-child {
    margin-right: 60px;
  }
  .tabs .active {
    color: #333;
    background: transparent !important;
  }
  .tabs .active::after {
    content: '';
    height: 2px;
    width: 100%;
    background: #61933a;
    display: block;
    margin-top: 5px;
  }
`;

export default withRouter(ReportsPage);
