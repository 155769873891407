import * as types from './constants';

export const getJobs = () => ({
  type: 'API',
  payload: {
    request: {
      url: `/v1/jobs`,
    },
    actions: [
      types.GET_JOBS_REQUEST,
      types.GET_JOBS_SUCCESS,
      types.GET_JOBS_FAIL,
    ],
  },
});

export const getJobsByGroup = (startDate, endDate, userIds) => {
  const _startDate = startDate !== 'null' ? `?startDate=${startDate}` : '';
  const _endDate =
    endDate !== 'null'
      ? `${startDate !== 'null' ? '&' : '?'}endDate=${endDate}`
      : '';
  const _userIds =
    userIds !== 'all'
      ? `${
          startDate !== 'null' || endDate !== 'null' ? '&' : '?'
        }userIds=[${userIds}]`
      : '';
  const _timeDifference = `${
    startDate !== 'null' || endDate !== 'null' || userIds !== 'all' ? '&' : '?'
  }timeDifference=${new Date().getTimezoneOffset()}`;

  return {
    type: 'API',
    payload: {
      request: {
        url: `/v1/jobs/group${_startDate}${_endDate}${_userIds}${_timeDifference}`,
      },
      actions: [
        types.GET_JOBS_BY_GROUP_REQUEST,
        types.GET_JOBS_BY_GROUP_SUCCESS,
        types.GET_JOBS_BY_GROUP_FAIL,
      ],
    },
  };
};

export const getActiveJob = (userId) => ({
  type: 'API',
  payload: {
    request: {
      url: `/v1/jobs/active?userId=${userId}`,
    },
    actions: [
      types.GET_ACTIVE_JOB_REQUEST,
      types.GET_ACTIVE_JOB_SUCCESS,
      types.GET_ACTIVE_JOB_FAIL,
    ],
  },
});

export const createJob = ({ locationId, payload }) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/locations/${locationId}/jobs`,
        json: {
          snowRemovalOrderId: payload.snowRemovalOrderId,
          comment: payload.comment,
          imgIds: payload.imgIds,
          userId: payload.userId,
          startDate: payload.startDate.slice(0, -5),
          endDate: payload.endDate.slice(0, -5),
          tasks: payload.tasks,
        },
      },
      actions: [
        types.CREATE_JOB_REQUEST,
        types.CREATE_JOB_SUCCESS,
        types.CREATE_JOB_FAIL,
      ],
    },
  };
};

export const updateJob = ({ jobId, payload }) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/jobs/${jobId}`,
        json: {
          startDate: payload.startDate ? payload.startDate.slice(0, -5) : null,
          endDate: payload.endDate ? payload.endDate.slice(0, -5) : null,
          tasks: payload.tasks,
          snowRemovalOrderId: payload.snowRemovalOrderId,
        },
      },
      actions: [
        types.UPDATE_JOB_REQUEST,
        types.UPDATE_JOB_SUCCESS,
        types.UPDATE_JOB_FAIL,
      ],
    },
  };
};

export const createJobTasks = ({ jobId, payload }) => ({
  type: 'API',
  payload: {
    request: {
      method: 'PATCH',
      url: `/v1/jobs/${jobId}/tasks`,
      json: payload,
    },
    actions: [
      types.ADD_JOB_TASKS_REQUEST,
      types.ADD_JOB_TASKS_SUCCESS,
      types.ADD_JOB_TASKS_FAIL,
    ],
  },
});

export const deleteJob = (jobId) => ({
  type: 'API',
  payload: {
    request: {
      method: 'DELETE',
      url: `/v1/jobs/${jobId}`,
    },
    actions: [
      types.DELETE_JOB_REQUEST,
      types.DELETE_JOB_SUCCESS,
      types.DELETE_JOB_FAIL,
    ],
  },
});

export const updateNoteOnJob = ({ jobId, comment }) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/jobs/${jobId}/comment`,
        json: { comment: comment },
      },
      actions: [
        types.UPDATE_NOTE_ON_JOB_REQUEST,
        types.UPDATE_NOTE_ON_JOB_SUCCESS,
        types.UPDATE_NOTE_ON_JOB_FAIL,
      ],
    },
  };
};

export const joinJobImg = (jobId, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/jobs/${jobId}/image`,
        json: payload,
      },
      actions: [
        types.JOIN_JOB_TO_IMG_REQUEST,
        types.JOIN_JOB_TO_IMG_SUCCESS,
        types.JOIN_JOB_TO_IMG_FAIL,
      ],
    },
  };
};
