import storage from 'redux-persist/lib/storage';
import auth from './usersStore/reducer';
import account from './accountStore/reducer';
import data from './dataStore/reducer';
import locations from './locationsStore/reducer';
import jobs from './jobsStore/reducer';
import reports from './reportsStore/reducer';
import workerGroups from './workerGroup/reducer';
import equipment from './equipmentStore/reducer';
import cities from './citiesStore/reducer';
import { USER_LOGOUT } from './usersStore/constants';

export const persistConfig = {
  storage,
  key: 'auth',
  whitelist: ['dataStore'],
};

const combineLogoutReducer = (reducer) => (state, action) => {
  if (action.type === USER_LOGOUT) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default {
  usersStore: combineLogoutReducer(auth),
  accountStore: combineLogoutReducer(account),
  dataStore: combineLogoutReducer(data),
  locationsStore: combineLogoutReducer(locations),
  jobsStore: combineLogoutReducer(jobs),
  reportsStore: combineLogoutReducer(reports),
  workerGroup: combineLogoutReducer(workerGroups),
  equipmentStore: combineLogoutReducer(equipment),
  citiesStore: combineLogoutReducer(cities),
};
