import { connect } from 'react-redux';
import ReportsTimePage from './ReportsTimePage';
import { getJobsReport } from '../../store/reportsStore/actions';
import {
  getAllLocations,
  getLocationsTasks,
} from '../../store/locationsStore/actions';
import { getUsers } from '../../store/usersStore/actions';

const mapStateToProps = ({
  reportsStore: { isLoading, reportJob },
  locationsStore: { listAllLocations, listTasks },
  usersStore: { listUsers },
}) => ({
  isLoading,
  reportJob,
  listAllLocations,
  listUsers,
  listTasks,
});

const mapDispatchToProps = {
  getJobsReport,
  getAllLocations,
  getLocationsTasks,
  getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTimePage);
