import React, { Fragment, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { isEmpty } from 'lodash';
import { TASK_STATUS_ID_YES, TASK_STATUS_ID_NO } from '../../../utils/tasks';
import TimeSection from '../../TimeSection';

const getTaskName = (task) => `task-${task.id}`;
const validateRequired = (values, tasks) =>
  tasks.every((task) => Boolean(values[getTaskName(task)]));

const getTaskStatusIdValue = (task, values) =>
  values[getTaskName(task)] === 'yes' ? TASK_STATUS_ID_YES : TASK_STATUS_ID_NO;

const groupTasksByPlace = (tasks) =>
  tasks.reduce((acc, task) => {
    const { taskPlaceId } = task;
    if (!acc[taskPlaceId]) {
      acc[taskPlaceId] = {
        place: task.place,
        tasks: [],
      };
    }

    acc[taskPlaceId].tasks.push(task);
    return acc;
  }, {});

const VALIDATION_MESSAGE_FIELD_REQUIRED = 'Please select each value';
const VALIDATION_MESSAGE_SRN_REQUIRED = 'Please fill "Snow Removal PO Number"';

const Tasks = ({
  tasks,
  locationId,
  userId,
  handleFirstScreen,
  validationMessage,
  setValidationMessage,
  isValidationMessageTime,
  setValidationTimeMessage,
  handleOpenNotesPopup,
  isNotEnteredTime,
  setNotEnteredTime,
}) => {
  const childCompRef = useRef(null);
  const [srn, setSRN] = useState('');
  const placeMap = groupTasksByPlace(tasks);
  const handleSubmit = (values) => {
    const startTime = childCompRef.current.state.startTime;
    const stopTime = childCompRef.current.state.stopTime;

    if (isEmpty(startTime) || isEmpty(stopTime)) {
      setNotEnteredTime(true);
    }

    const startISOTime = new Date(
      startTime.y,
      startTime.m,
      startTime.d,
      startTime.h,
      startTime.min,
      startTime.sec,
    ).toISOString();

    const stopISOTime = new Date(
      stopTime.y,
      stopTime.m,
      stopTime.d,
      stopTime.h,
      stopTime.min,
      stopTime.sec,
    ).toISOString();

    if (stopISOTime < startISOTime) {
      setValidationTimeMessage(true);
      return;
    } else {
      // setValidationTimeMessage(false);
    }

    if (!validateRequired(values, tasks)) {
      setValidationMessage(VALIDATION_MESSAGE_FIELD_REQUIRED);
      return;
    }
    if (values?.['task-7'] === 'yes' && !srn) {
      setValidationMessage(VALIDATION_MESSAGE_SRN_REQUIRED);
      return;
    }

    const answers = tasks.map((task) => ({
      // jobId: job.id,
      taskId: task.id,
      taskStatusId: getTaskStatusIdValue(task, values),
    }));

    handleFirstScreen({
      locationId: locationId,
      userId: userId,
      startDate: startISOTime,
      endDate: stopISOTime,
      tasks: answers,
      snowRemovalOrderId: srn,
    });

    handleOpenNotesPopup();
    // createJob({
    //   locationId,
    //   payload: {
    //     userId: userId,
    //     startDate: startISOTime,
    //     endDate: stopISOTime,
    //     tasks: answers,
    //   },
    // });
  };

  const handleChange = () => {
    if (validationMessage === VALIDATION_MESSAGE_FIELD_REQUIRED) {
      setValidationMessage('');
    }
  };

  const handleChangeTime = () => {
    setValidationTimeMessage(false);
    setNotEnteredTime(false);
  };

  return (
    <Wrapper>
      <Error>
        {isValidationMessageTime && (
          <span>
            <b>End</b> time needs to be later than <b>Start</b> time
          </span>
        )}
      </Error>
      {isNotEnteredTime && <Error>Enter all time fields</Error>}

      <TimeSection
        ref={childCompRef}
        add={true}
        handleChangeTime={handleChangeTime}
      />
      <Formik onSubmit={handleSubmit} initialValues={{}}>
        {({ values }) => (
          <Form onChange={handleChange}>
            <p>Services performed at this location:</p>
            {Object.keys(placeMap).map((placeId) => {
              placeMap[placeId].tasks.sort(
                (taskOne, taskTwo) => taskOne.id - taskTwo.id,
              );
              return (
                <Fragment key={placeId}>
                  <h2>{placeMap[placeId].place.name}</h2>
                  {placeMap[placeId].tasks.map((task) => (
                    <div key={task.id}>
                      <Row>
                        <span className="task-name">
                          {task.name.toLocaleLowerCase()}
                        </span>
                        <div className="switch-field">
                          <Field
                            type="radio"
                            name={getTaskName(task)}
                            id={`yes-${task.id}`}
                            value="yes"
                          />
                          <label htmlFor={`yes-${task.id}`}>Yes</label>
                          <Field
                            type="radio"
                            name={getTaskName(task)}
                            id={`no-${task.id}`}
                            value="no"
                          />
                          <label htmlFor={`no-${task.id}`}>No</label>
                        </div>
                      </Row>
                      {task.id === 7 && values?.['task-7'] === 'yes' && (
                        <WrapperInput>
                          <label htmlFor="">Snow Removal PO Number</label>
                          <input
                            type="text"
                            defaultValue={srn}
                            onChange={(event) => setSRN(event.target.value)}
                          />
                        </WrapperInput>
                      )}
                    </div>
                  ))}
                </Fragment>
              );
            })}
            <Error>{validationMessage}</Error>
            <ButtonsWrap>
              <button type="submit">NEXT</button>
            </ButtonsWrap>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
const Error = styled.span`
  color: #d60000;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  align-items: baseline;
  span {
    font-weight: bold;
  }
  .task-name {
    text-transform: capitalize;
    font-weight: 400;
  }
`;

const Wrapper = styled.div`
  min-width: 200px !important;
  h3 {
      text-align: center;
      margin-bottom: 29px;
   }
  .switch-field {
    display: flex;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    background: #F8F8F8;
    color: #333333;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 5px 14px;
    height: 27px;
    margin-right: -1px;
    border: 1px solid transparent;
      0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field input:checked + label {
    background-color: #61933a;
    color: #fff;
    box-shadow: none;
  }

  .switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  .switch-field label:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const ButtonsWrap = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  button {
    cursor: pointer;
    height: 50px;
    width: 240px;
    line-height: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    color: #fff;
    border-radius: 4px;
    background: #61933a;
    outline: none;
  }
`;

const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  margin-bottom: 20px;
  height: 38px;
  align-items: center;
  input {
    outline-color: #61933a;
    width: 128px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
  }
`;

export default Tasks;
