import { connect } from 'react-redux';
import GroupPage from './GroupPage';
import {
  getWorkersGroupById,
  assignWorkerToGroup,
  removeWorkerFromGroup,
  assignTaskToGroup,
  removeTaskFromGroup,
  updateWorkerOnGroup,
  assignLocationToGroup,
  removeLocationFromGroup,
  getTaskFromGroup,
  deleteWorkerGroup,
  updateWorkerGroup,
} from '../../store/workerGroup/actions';
import {
  getTaskPlace,
  getAllLocations,
  getLocationsTasks,
} from '../../store/locationsStore/actions';
import { getActiveJob } from '../../store/jobsStore/actions';
import { getUsers } from '../../store/usersStore/actions';

const mapStateToProps = ({
  workerGroup: { workerGroup, isLoadingDeleted, isLoadingTasks },
  usersStore: { listUsers },
  locationsStore: {
    tasksCategories,
    listTasks,
    listAllLocations,
    isLoading,
    isAssignedLocation,
  },
  jobsStore: { activeJob },
}) => ({
  workerGroup,
  listUsers,
  listAllLocations,
  isLoadingDeleted,
  isLoading,
  isAssignedLocation,
  listTasks,
  tasksCategories,
  activeJob,
  isLoadingTasks,
});

const mapDispatchToProps = {
  getWorkersGroupById,
  assignWorkerToGroup,
  removeWorkerFromGroup,
  assignTaskToGroup,
  removeTaskFromGroup,
  updateWorkerOnGroup,
  getAllLocations,
  getUsers,
  getActiveJob,
  assignLocationToGroup,
  removeLocationFromGroup,
  getTaskFromGroup,
  getLocationsTasks,
  getTaskPlace,
  deleteWorkerGroup,
  updateWorkerGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);
