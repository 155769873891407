import React from 'react';
import styled from 'styled-components';
import MenuDots from '../../assets/svgs/menu-image.svg';
import { ReactSVG } from 'react-svg';
import DropDownTasks from './DropDownTasks';

export default class TaskBlock extends React.Component {
  state = {
    openMenu: false,
  };
  handleOpenMenu = (e) => {
    e.stopPropagation();

    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };

  render() {
    const {
      task,
      handleOpenTaskPopup,
      last,
      updateTask,
      deleteTask,
    } = this.props;
    const { openMenu } = this.state;
    return (
      <Task className="card" last={last}>
        <span className="task-name">{task.name.toLocaleLowerCase()}</span>
        <a className="card-menu" onClick={this.handleOpenMenu}>
          <ReactSVG className="dropdown" src={MenuDots} />
          {openMenu && (
            <DropDownTasks
              deleteTask={deleteTask}
              handleOpenTaskPopup={handleOpenTaskPopup}
              task={task}
              updateTask={updateTask}
              onClose={this.handleOpenMenu}
            />
          )}
          {/*{openMenu && (*/}
          {/*  <DropDownUsers*/}
          {/*    handlePopupDelete={handlePopupDelete}*/}
          {/*    updateUser={updateUser}*/}
          {/*    deleteUsers={deleteUsers}*/}
          {/*    user={user}*/}
          {/*    onClose={this.handleOpenMenu}*/}
          {/*  />*/}
          {/*)}*/}
        </a>
      </Task>
    );
  }
}

const Task = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 10px 20px;
  display: flex;
  width: calc(33.3333333% - 30px);
  margin-right: ${({ last }) => !last && '30px'};
  span {
    text-transform: capitalize;
  }
  .card-menu {
    top: -2px;
  }
  @media (max-width: 601px) {
    width: 100%;
    margin-right: 0;
  }
`;
