import React from 'react';
import { getInitials } from '../../utils/initials';
import { ReactSVG } from 'react-svg';
import MenuDots from '../../assets/svgs/menu-image.svg';
import DropDownLocations from '../../components/DropDownLocations';
import ImageImg from '../../assets/svgs/imageImg.svg';
import NotesImg from '../../assets/svgs/noteImg.svg';
import styled from 'styled-components';

export default class LocationBlock extends React.Component {
  state = {
    openMenu: false,
  };
  handleOpenMenu = () => {
    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };

  render() {
    const { location, additional, deleteLocation, history } = this.props;
    const { openMenu } = this.state;

    return (
      <div className="card">
        <p
          className="heading"
          onClick={() => history.push(`/edit-location/${location.id}`)}
        >
          <Head>
            <span>{location.name} </span>
            <WrapSvgs>
              {location.comment && <img src={NotesImg} />}
              {Boolean(location.img.length) && <img src={ImageImg} />}
            </WrapSvgs>
          </Head>
          {location.address}
          {location.city ? ',' : ''} {location.city}
          {location.province ? ',' : ''} {location.province}
          {location.postalCode}
        </p>
        <div className="avatars">
          {location.users.map(
            (user, key) =>
              key < 3 && (
                <div
                  className="avatar"
                  style={{ background: user.color ? user.color : '#683AB7' }}
                  key={key}
                >
                  {getInitials(user)}
                </div>
              ),
          )}
          {additional && (
            <div className="avatar avatar-number">+{additional}</div>
          )}
        </div>
        <a className="card-menu" onClick={this.handleOpenMenu}>
          <ReactSVG className="dropdown" src={MenuDots} />
          {openMenu && (
            <DropDownLocations
              location={location}
              onClose={this.handleOpenMenu}
              deleteLocation={deleteLocation}
            />
          )}
        </a>
      </div>
    );
  }
}

const WrapSvgs = styled.div`
  display: flex;
  margin-left: 10px;
  position: relative;
  img {
    margin-right: 8px;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: baseline;
`;
