import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import LocationImg from '../../../assets/svgs/location-active.svg';
import PersonImg from '../../../assets/svgs/preson-active.svg';
import CloseGreyImg from '../../../assets/svgs/close-grey.svg';
import { ReactSVG } from 'react-svg';
import Tasks from './Tasks';
import AssignSection from './AssignSection';
import LocationSection from './LocationSection';

const AddActivityPopup = React.memo(
  ({
    open,
    onClose,
    listUsers,
    listAllLocations,
    handleOpenNotesPopup,
    handleFirstScreen,
  }) => {
    const [validationMessage, setValidationMessage] = useState('');
    const [isValidationMessageTime, setValidationTimeMessage] = useState(false);
    const [isNotEnteredTime, setNotEnteredTime] = useState(false);
    const [location, setLocation] = useState(null);
    const [user, setUser] = useState(null);

    const locationItem =
      location !== null
        ? listAllLocations.find((locationItem) => locationItem.id === location)
        : [];

    const clearAndClose = () => {
      clean();
      onClose();
    };

    const clean = () => {
      setLocation(null);
      setUser(null);
      setValidationTimeMessage(false);
      setValidationMessage('');
    };

    useEffect(() => {
      return () => {
        clean();
      };
    }, [open]);

    return (
      <StyledDialog
        disableBackdropClick
        open={open}
        onClose={clearAndClose}
        PaperProps={{
          style: {
            backgroundColor: '#fafafa',
            boxShadow: 'none',
          },
        }}
      >
        <Wrapper>
          <div className="modal-header">
            <h2>Add entry</h2>
            <div className="close-btn" onClick={clearAndClose}>
              <ReactSVG src={CloseGreyImg} />
            </div>
          </div>
          <WrapSelect>
            <div className="row">
              <ReactSVG src={LocationImg} />
              <LocationSection
                handleSetlocations={setLocation}
                listAllLocations={listAllLocations}
                isOneOption={true}
              />
            </div>

            <div className="row last">
              <ReactSVG src={PersonImg} />
              <AssignSection
                handleSetUsers={setUser}
                listUsers={listUsers}
                isOneOption={true}
                // isLoading={isLoading}
              />
            </div>
          </WrapSelect>
          {locationItem && locationItem.tasks && locationItem.tasks.length && (
            <Tasks
              isNotEnteredTime={isNotEnteredTime}
              setNotEnteredTime={setNotEnteredTime}
              isValidationMessageTime={isValidationMessageTime}
              validationMessage={validationMessage}
              setValidationMessage={setValidationMessage}
              tasks={locationItem.tasks}
              setValidationTimeMessage={setValidationTimeMessage}
              locationId={location}
              userId={user}
              handleOpenNotesPopup={handleOpenNotesPopup}
              handleFirstScreen={handleFirstScreen}
              onClose={clearAndClose}
            />
          )}
        </Wrapper>
      </StyledDialog>
    );
  },
);
const Wrapper = styled.div`
  h2 {
    margin: 10px 0;
  }
  .close-btn {
    cursor: pointer;
  }
  .content {
    min-height: calc(100vh - 100px);
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
  }
  .flex-row {
    margin-bottom: 10px;
  }
  .flex-row .icon {
    margin-right: 15px;
  }
  .task {
    padding-left: 40px;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .task > span {
    margin-top: 15px;
  }
  .card {
    margin-bottom: 15px;
    display: flex;
  }
  .card.space-between {
    margin: 20px 0 40px;
    justify-content: space-around;
  }
  .time {
    margin-top: 10px;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
  .time span {
    font-size: 14px;
    font-weight: 800;
    margin-left: 5px;
  }
  .date {
    color: #999;
    font-weight: bold;
  }

  .switch-field {
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 4px;
  }
  .switch-field input {
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    border: 0px;
    overflow: hidden;
    position: absolute !important;
  }
  .switch-field label {
    background: rgb(248, 248, 248);
    color: rgb(51, 51, 51);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    line-height: 34px;
    padding: 0;
    width: 60px;
    height: 34px;
    font-weight: bold;
    transition: all 0.1s ease-in-out 0s;
  }
  .switch-field input:checked + label {
    background-color: rgb(97, 147, 58);
    color: rgb(255, 255, 255);
    box-shadow: none;
    border-radius: 3px;
  }
  .cta {
    text-align: center;
    margin-top: 30px;
  }
  .cta button {
    height: 50px;
    width: 240px;
    line-height: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    color: #fff;
    border-radius: 4px;
    background: #61933a;
    outline: none;
  }

  //background: #fafafa;
  padding: 0 20px;
  min-width: 400px !important;
  width: 600px;
  min-height: 900px;
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

const WrapSelect = styled.div`
  font-family: Roboto;
  font-size: 18px;
  color: #61933a;
  .select-user {
    font-family: Roboto;
    font-size: 18px;
    color: #61933a;
    font-weight: 400;
    position: relative;
    top: -8px;
  }
  .row {
    display: flex;
    min-height: 44px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .last {
    margin-bottom: 0;
  }
`;

export default AddActivityPopup;
