import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { ROLE_MASTER } from '../../utils/users';
import UserBlock from './UserBlock';
import AddWorkerPopup from '../../components/Popups/AddWorkerPopup';
import ResetPopup from '../../components/Popups/ResetPopup';
import ExistsPopup from '../../components/Popups/ExistsPopup';
import DeleteWorkerPopup from '../../components/Popups/DeleteWorkerPopup';
import { getResponseErrors } from '../../utils/response';
import StyledAlertMessage from '../../components/StyledAlertMessage';
import { getErrorsMessageUser } from '../../utils/template';
import AddGroupPopup from '../../components/Popups/AddGroupPopup';
import PersonInactiveImg from '../../assets/svgs/userIcon.svg';
import PersonActiveImg from '../../assets/svgs/userIconActive.svg';
import UserGroup from '../../assets/svgs/users-group.svg';
import UserGroupActive from '../../assets/svgs/users-group-active.svg';
import GroupItem from '../WorkerGroupsPage/GroupItem';

class UsersPage extends React.Component {
  state = {
    openAddPopup: false,
    popup: false,
    popupExists: false,
    formValues: null,
    popupDelete: false,
    deleteId: null,
    addGroupPopup: false,
    group: null,
    tab: 1,
  };
  componentDidMount() {
    this.props.getUsers();
    this.props.getWorkersGroup();
  }

  componentDidUpdate(prevProps) {
    const {
      isUserDeleted,
      getUsers,
      isUserUpdated,
      isUserRestored,
      isUserAdded,
    } = this.props;

    if (
      prevProps.isUserAdded !== isUserAdded ||
      (!prevProps.isUserDeleted && isUserDeleted) ||
      (!prevProps.isUserUpdated && isUserUpdated) ||
      (!prevProps.isUserRestored && isUserRestored)
    ) {
      getUsers();
      this.setState({
        openAddPopup: false,
        popup: false,
        popupExists: false,
        formValues: null,
        popupDelete: false,
        deleteId: null,
      });
    }
    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.msg === 'USER IS ARCHIVED'
    ) {
      this.setState({ openAddPopup: false, popup: true });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.errors?.length
    ) {
      const errors = getResponseErrors(this.props.error);
      StyledAlertMessage.error(getErrorsMessageUser(errors));
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.msg === 'USER IS EXIST'
    ) {
      this.setState({ openAddPopup: false, popupExists: true });
    }
    // Worker Group
    const { isLoadingDeleted, getWorkersGroup } = this.props;
    if (prevProps.isLoadingDeleted !== isLoadingDeleted && !isLoadingDeleted) {
      this.setState({ addGroupPopup: false, group: null });
      getWorkersGroup();
    }
  }

  componentWillUnmount() {
    const { cleanUser } = this.props;
    cleanUser();
  }

  handleChangeTab(newTab) {
    this.setState({ tab: newTab });
  }

  handleAddGroupPopup = (e, group) => {
    this.setState({
      addGroupPopup: !this.state.addGroupPopup,
      group: group || null,
    });
  };

  handleOpenAddPopup = () => {
    this.setState(({ openAddPopup }) => ({
      openAddPopup: !openAddPopup,
    }));
  };

  handlePopupDelete = (id) => {
    this.setState(({ popupDelete }) => ({
      popupDelete: !popupDelete,
      deleteId: id ? id : null,
    }));
  };

  handleSubmit = (values) => {
    const { addUser } = this.props;
    addUser(values);
    this.setState({ formValues: { ...values, force: true } });
  };

  handlePopup = () => {
    this.setState({ popup: !this.state.popup });
  };

  handlePopupExists = () => {
    this.setState({ popupExists: !this.state.popupExists });
  };

  onDeleteUser = () => {
    const { deleteUsers } = this.props;
    const { deleteId } = this.state;
    deleteUsers(deleteId);
  };

  render() {
    const {
      listUsers,
      updateUser,
      isLoading,
      deleteUsers,
      history,
      restoreUser,
      error,
      workersGroups,
      updateWorkerGroup,
      deleteWorkerGroup,
      addWorkerGroup,
    } = this.props;
    const {
      openAddPopup,
      popup,
      popupExists,
      formValues,
      popupDelete,
      addGroupPopup,
      group,
      tab,
    } = this.state;

    listUsers.sort(function (a, b) {
      if (a.active === b.active) {
        if (a.role === b.role) {
          return a.name.localeCompare(b.name);
        }
        return a.role > b.role ? 1 : -1;
      }
      return a.active > b.active ? 1 : -1;
    });

    return (
      <Wrapper>
        <div className="title-bar">
          <h1>{tab === 1 ? 'Workers' : 'Teams'}</h1>
          <TabsWrap>
            <Tab
              theme={{ color: tab === 1 ? '#4979C1' : '#828282' }}
              onClick={() => this.handleChangeTab(1)}
            >
              <img
                src={tab === 1 ? PersonActiveImg : PersonInactiveImg}
                alt="personIcon"
              />
              Workers
            </Tab>
            <Tab
              theme={{ color: tab === 2 ? '#4979C1' : '#828282' }}
              onClick={() => this.handleChangeTab(2)}
            >
              <img
                src={tab === 2 ? UserGroupActive : UserGroup}
                alt="groupIcon"
              />
              Teams
            </Tab>
          </TabsWrap>
          {tab === 1 ? (
            <button
              id="add-location"
              className="btn"
              onClick={this.handleOpenAddPopup}
            >
              new worker
            </button>
          ) : (
            <button
              id="add-location"
              className="btn"
              onClick={this.handleAddGroupPopup}
            >
              new team
            </button>
          )}
        </div>
        {tab === 1 ? (
          <div className="container">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {listUsers.map(
                  (user, index) =>
                    user.role !== ROLE_MASTER && (
                      <UserBlock
                        handlePopupDelete={this.handlePopupDelete}
                        updateUser={updateUser}
                        deleteUsers={deleteUsers}
                        user={user}
                        key={index}
                        history={history}
                      />
                    ),
                )}
              </>
            )}
          </div>
        ) : (
          <div className="container">
            {workersGroups.map((group) => (
              <GroupItem
                key={group.workersGroupId}
                history={history}
                group={group}
                handleAddGroupPopup={this.handleAddGroupPopup}
                deleteWorkerGroup={deleteWorkerGroup}
              />
            ))}
          </div>
        )}{' '}
        {openAddPopup && (
          <AddWorkerPopup
            onClose={this.handleOpenAddPopup}
            handleSubmit={this.handleSubmit}
          />
        )}
        {popup && (
          <ResetPopup
            error={error}
            createNew={() => this.handleSubmit(formValues)}
            restoreUser={restoreUser}
            onClose={this.handlePopup}
          />
        )}
        {popupExists && <ExistsPopup onClose={this.handlePopupExists} />}
        {popupDelete && (
          <DeleteWorkerPopup
            onDeleteUser={this.onDeleteUser}
            onClose={this.handlePopupDelete}
          />
        )}
        {addGroupPopup && (
          <AddGroupPopup
            updateWorkerGroup={updateWorkerGroup}
            addWorkerGroup={addWorkerGroup}
            group={group}
            onClose={this.handleAddGroupPopup}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  p.heading,
  p.heading span {
    max-width: calc(100% - 30px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p.heading span {
    max-width: 100%;
  }
  .card {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    position: relative;
    flex-wrap: wrap;
    padding-right: 13%;
  }
  .card-menu {
    top: 4px;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
    color: var(--grey-text);
  }
  p.heading span {
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 3px;
    color: var(--black-text);
  }

  .worker {
    margin-bottom: 0 !important;
  }
  @media (min-width: 600px) {
    p.heading,
    p.heading span {
      max-width: calc(100% - 190px);
    }
    p.heading {
      margin: 0;
    }
    p.heading span {
      max-width: 100%;
    }
    .avatars {
      position: absolute;
      top: 14px;
      right: 45px;
    }
  }
`;

const TabsWrap = styled.div`
  display: flex;
  margin-left: auto;
  padding-right: 20px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color};
  img {
    width: 18px;
  }
  margin: 0 20px;
  &:hover {
    cursor: pointer;
  }
`;

export default withRouter(UsersPage);
