import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import TrashRed from '../../assets/svgs/trash-red.svg';
import EditImg from '../../assets/svgs/edit.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class DropDownCategory extends React.Component {
  render() {
    const {
      onClose,
      handleOpenCategoryPopup,
      category,
      deleteTaskPlace,
    } = this.props;
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper>
          <Wrap
            className="item"
            onClick={(e) => {
              handleOpenCategoryPopup(e, category);
            }}
          >
            <ReactSVG src={EditImg} />
            <span>Edit</span>
          </Wrap>

          <Wrap
            className="item"
            onClick={() => {
              deleteTaskPlace(category.id);
            }}
          >
            <ReactSVG src={TrashRed} />
            <span>Delete</span>
          </Wrap>
        </Wrapper>
      </ClickAwayListener>
    );
  }
}

const Wrap = styled.div`
  display: flex;
  height: 75px;
  padding: 18px 22px;
  align-items: center;

  span {
    padding-left: 16px;
    padding-top: 1px;
  }
`;

const Wrapper = styled.div`
  z-index: 999;
  top: 36px;
  color: #3b494c;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 17px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  right: 0;
  min-height: 100px;
  width: 129px;
  .item:nth-child(1) {
    border-bottom: 1px solid #dddddd;
  }

  position: absolute;
  .role {
    border-bottom: 1px solid #c4c4c4;
  }
  .active {
    border-top: 1px solid #c4c4c4;
  }
`;

export default DropDownCategory;
