/* eslint-disable */
import styled from 'styled-components';
import React, {useEffect, useState} from "react";

import Spinner from "../../components/Spinner";
import CityItem from "./CityItem";
import AddCityModal from "./AddCityModal";

const CitiesPage = (props) => {
    const {
        listCities,
        isLoading,
        isLoadingCreation,
        creationError,
    } = props;

    const [activeCityPopover, setActiveCityPopover] = useState(-1)
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        props.getCities()
    }, [])

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    if (isLoading && listCities.length === 0) {
        return (
            <SpinnerWrapper>
                <Spinner/>
            </SpinnerWrapper>
        )
    }

    return (
        <Wrapper>
            <PageHeader>
                <Title>Weather Locations</Title>
                <AddCityButton onClick={handleOpenModal}>NEW LOCATION</AddCityButton>
            </PageHeader>
            <CitiesList>
                {listCities.map(city => (
                    <CityItem
                        city={city}
                        handleDeleteCity={props.deleteCity}
                        activeCityPopover={activeCityPopover}
                        setActiveCityPopover={setActiveCityPopover}
                        key={city.id}
                    />
                ))}
            </CitiesList>
            {openModal && <AddCityModal
                handleAddCity={props.addCity}
                onClose={handleCloseModal}
                isLoading={isLoadingCreation}
                error={creationError}
            />}
        </Wrapper>
    );
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Wrapper = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
`

const Title = styled.h1`
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
`;

const AddCityButton = styled.button`
  width: 141px;
  height: 40px;
  border-radius: 5px;
  border: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  background-color: #54A01D;
  cursor: pointer;
`

const CitiesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default CitiesPage;
