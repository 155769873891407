import { connect } from 'react-redux';
import ReportsWeatherPage from './ReportsWeatherPage';
import { getWeatherReportByDates } from '../../store/reportsStore/actions';

const mapStateToProps = ({ reportsStore: { isLoading, reportWeather } }) => ({
  isLoading,
  reportWeather,
});

const mapDispatchToProps = { getWeatherReportByDates };

export default connect(mapStateToProps, mapDispatchToProps)(ReportsWeatherPage);
