import React, { useState } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DropZoneUpload from '../../components/DropZoneUpload';
import ImagePopup from '../../components/ImagePopup';

const NotesBlock = ({
  location,
  note,
  logos,
  images,
  handleNote,
  handleLogos,
  handleImages,
  handleAfterDel,
}) => {
  const [popupImg, setPopupImg] = useState(false);

  return (
    <div>
      <TextareaAutosize
        aria-label="minimum height"
        rowsMin={10}
        value={note}
        placeholder="Type your notes here..."
        onChange={(event) => {
          handleNote(event.target.value);
        }}
      />

      <div className="image-row">
        <DropZoneUpload
          setPopupImg={setPopupImg}
          location={location}
          multiple={true}
          value={logos}
          onChange={(file) => {
            handleLogos(file);
          }}
          setImages={handleImages}
          images={images}
          setAfterDel={handleAfterDel}
        />
      </div>
      <ImagePopup open={popupImg} src={popupImg} onClose={setPopupImg} />
    </div>
  );
};

export default NotesBlock;
