import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  jobs: [],
  jobsByGroup: [],
  jobByDate: [],
  jobsWithOutWorkingDayGroup: [],
  activeJob: {},
  isLoadingActive: false,
  tasksCreated: false,
  isJobChanged: false,
  isJobCreated: false,
  isJobAddedImg: false,
  noteUpdated: false,
  isLoadingHistory: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_JOB_REQUEST:
      return { ...state, isLoading: true, isJobCreated: false };
    case types.CREATE_JOB_SUCCESS:
      return {
        ...state,
        isJobCreated: true,
        activeJob: payload,
        isLoading: false,
      };
    case types.CREATE_JOB_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.UPDATE_JOB_REQUEST:
      return { ...state, isLoading: true, isJobChanged: false };
    case types.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        // activeJob: payload,
        isLoading: false,
        isJobChanged: true,
      };
    case types.UPDATE_JOB_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.DELETE_JOB_REQUEST:
      return { ...state, isLoading: true, isJobChanged: false };
    case types.DELETE_JOB_SUCCESS:
      return {
        ...state,
        activeJob: {},
        isLoading: false,
        isJobChanged: true,
      };
    case types.DELETE_JOB_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_JOBS_REQUEST:
      return { ...state, jobByDate: [], isLoading: true };
    case types.GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: payload.data,
        jobByDate: payload.dates,
        isLoading: false,
      };
    case types.GET_JOBS_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_JOBS_BY_GROUP_REQUEST:
      return {
        ...state,
        jobByWorkingDay: [],
        isLoading: true,
        isLoadingHistory: true,
      };
    case types.GET_JOBS_BY_GROUP_SUCCESS:
      return {
        ...state,
        jobsByGroup: payload.data,
        jobsWithOutWorkingDayGroup: payload.jobsWithOutWorkingDayGroup,
        isLoading: false,
        isLoadingHistory: false,
      };
    case types.GET_JOBS_BY_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoadingHistory: false,
      };

    case types.GET_ACTIVE_JOB_REQUEST:
      return { ...state, isLoading: true, isLoadingActive: true };
    case types.GET_ACTIVE_JOB_SUCCESS:
      return {
        ...state,
        activeJob: payload[0],
        isLoading: false,
        isLoadingActive: false,
      };
    case types.GET_ACTIVE_JOB_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingActive: false,
        error: payload,
      };

    case types.ADD_JOB_TASKS_REQUEST:
      return { ...state, tasksCreated: false, isLoading: true };
    case types.ADD_JOB_TASKS_SUCCESS:
      return {
        ...state,
        tasksCreated: true,
        isLoading: false,
      };
    case types.ADD_JOB_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.JOIN_JOB_TO_IMG_REQUEST:
      return { ...state, isJobAddedImg: false, isLoading: true };
    case types.JOIN_JOB_TO_IMG_SUCCESS:
      return {
        ...state,
        isJobAddedImg: true,
        isLoading: false,
      };
    case types.JOIN_JOB_TO_IMG_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.UPDATE_NOTE_ON_JOB_REQUEST:
      return { ...state, noteUpdated: false, isLoading: true };
    case types.UPDATE_NOTE_ON_JOB_SUCCESS:
      return {
        ...state,
        noteUpdated: true,
        isLoading: false,
      };
    case types.UPDATE_NOTE_ON_JOB_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};
