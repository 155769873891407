import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import MenuDots from '../../assets/svgs/menu-image.svg';
import DropDownEquipment from './DropDownEquipment';

function join(t, a, s) {
  function format(m) {
    let f = new Intl.DateTimeFormat('en', m);
    return f.format(t);
  }
  return a.map(format).join(s);
}

let a = [{ day: 'numeric' }, { month: 'long' }, { year: 'numeric' }];
let formated = (data) => join(new Date(data), a, ' ');

function EquipmentItem(props) {
  const { item, deleteEquipment, handleOpenEditPopup, history } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = (e) => {
    e.stopPropagation();
    setTimeout(() => {
      setOpenMenu(!openMenu);
    }, 10);
  };

  const dotColor = () => {
    let diff = new Date(item.nextServiceDate).getTime() - new Date().getTime();
    if (diff < 0) {
      return '#FF6666';
    } else if (diff < 2419200000) {
      return '#FFC107';
    } else return '#7DCE3D';
  };

  return (
    <ItemWrap onClick={() => history.push(`/edit-equipment/${item.id}`)}>
      <LeftSection>
        <EquipNumber>{item?.equipmentNumber}</EquipNumber>
        <div>
          <EquipName>
            {item?.year} {item?.make} {item?.model}
          </EquipName>
          <EquipVin>
            {item?.vinSerialNumber && (
              <>
                VIN:
                {item.vinSerialNumber}
              </>
            )}
          </EquipVin>
        </div>
      </LeftSection>
      <CenteredSection>
        <License>
          {item?.licensePlate && (
            <>
              <StyledSpan>Lic. Plate: </StyledSpan> {item.licensePlate}
            </>
          )}
        </License>
        <License>
          {item && item.licensePlateExpiryDate && (
            <>
              <StyledSpan>Exp.: </StyledSpan>
              {formated(item.licensePlateExpiryDate)}
            </>
          )}
        </License>
      </CenteredSection>
      <RightSection>
        <License style={{ marginRight: 25 }}>
          {item && item.status === 'in service' ? (
            <Service>In service</Service>
          ) : (
            <>
              {item && item.nextServiceDate && (
                <FlexBlock>
                  <StyledSpan>Next Service: </StyledSpan>
                  {formated(item.nextServiceDate)}
                  <Dot theme={{ color: dotColor() }} />
                </FlexBlock>
              )}
            </>
          )}
        </License>
        <a className="card-menu" onClick={handleOpenMenu}>
          <ReactSVG src={MenuDots} />
          {openMenu && (
            <DropDownEquipment
              deleteEquipment={deleteEquipment}
              handleOpenEditPopup={handleOpenEditPopup}
              item={item}
              updateTask={() => {}}
              onClose={handleOpenMenu}
            />
          )}
        </a>
      </RightSection>
    </ItemWrap>
  );
}

const ItemWrap = styled.div`
  background: #ffffff;
  min-height: 72px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 15px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const CenteredSection = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const EquipNumber = styled.span`
  font-weight: 900;
  font-size: 16px;
  color: #333333;
  margin-right: 30px;
`;

const EquipName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;
const EquipVin = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #777777;
`;

const License = styled.div`
  line-height: 16px;
`;

const StyledSpan = styled.span`
  color: #777777;
  margin-right: 5px;
`;

const Service = styled.span`
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  background-color: #ffe5a4;
  padding: 5px 10px;
`;

const FlexBlock = styled.div`
  display: flex;
`;

const Dot = styled.div`
  margin: 0 0 0 18px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.color};
`;

export default EquipmentItem;
