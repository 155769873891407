import * as types from './constants';

export const getCities = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/city' },
      actions: [
        types.GET_LIST_CITIES_REQUEST,
        types.GET_LIST_CITIES_SUCCESS,
        types.GET_LIST_CITIES_FAIL,
      ],
    },
  };
};

export const addCity = (payload) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'POST', url: `/v1/city`, json: payload },
      actions: [
        types.ADD_CITY_REQUEST,
        types.ADD_CITY_SUCCESS,
        types.ADD_CITY_FAIL,
      ],
    },
  };
};

export const deleteCity = (cityId) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'DELETE', url: `/v1/city/${cityId}` },
      actions: [
        types.DELETE_CITY_REQUEST,
        types.DELETE_CITY_SUCCESS,
        types.DELETE_CITY_FAIL,
      ],
    },
  };
};
