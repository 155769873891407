import { connect } from 'react-redux';
import LocationPage from './LocationPage';
import {
  addLocation,
  getLocationById,
  updateLocation,
  cleanLocation,
  addCommentToLocation,
  joinLocationImg,
  deleteLocation,
} from '../../store/locationsStore/actions';
import { getUsers } from '../../store/usersStore/actions';
import { getJobsReport } from '../../store/reportsStore/actions';
import { deleteJob } from '../../store/jobsStore/actions';

const mapStateToProps = ({
  locationsStore: {
    isLocationAdded,
    location,
    isLoadingLocation,
    isUpdatedLocation,
    isLocationDeleted,
  },
  usersStore: { listUsers, isLoading },
  reportsStore: { reportJob, isLoadingReport },
  jobsStore: { isJobChanged },
}) => ({
  isLocationAdded,
  listUsers,
  isLoading,
  locationItem: location,
  isLoadingLocation,
  isUpdatedLocation,
  reportJob,
  isLoadingReport,
  isLocationDeleted,
  isJobChanged,
});

const mapDispatchToProps = {
  addLocation,
  getUsers,
  getLocationById,
  updateLocation,
  cleanLocation,
  addCommentToLocation,
  joinLocationImg,
  getJobsReport,
  deleteLocation,
  deleteJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
