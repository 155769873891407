export const GET_LIST_CITIES_REQUEST = 'GET_LIST_CITIES_REQUEST';
export const GET_LIST_CITIES_SUCCESS = 'GET_LIST_CITIES_SUCCESS';
export const GET_LIST_CITIES_FAIL = 'GET_LIST_CITIES_FAIL';

export const GET_WEATHER_REPORT_BY_DATES_REQUEST =
  'GET_WEATHER_REPORT_BY_DATES_REQUEST';
export const GET_WEATHER_REPORT_BY_DATES_SUCCESS =
  'GET_WEATHER_REPORT_BY_DATES_SUCCESS';
export const GET_WEATHER_REPORT_BY_DATES_FAIL =
  'GET_WEATHER_REPORT_BY_DATES_FAIL';

export const GET_JOB_REPORT_REQUEST = 'GET_JOB_REPORT_REQUEST';
export const GET_JOB_REPORT_SUCCESS = 'GET_JOB_REPORT_SUCCESS';
export const GET_JOB_REPORT_FAIL = 'GET_JOB_REPORT_FAIL';

export const GET_WORKING_DAY_REPORT_REQUEST = 'GET_WORKING_DAY_REPORT_REQUEST';
export const GET_WORKING_DAY_REPORT_SUCCESS = 'GET_WORKING_DAY_REPORT_SUCCESS';
export const GET_WORKING_DAY_REPORT_FAIL = 'GET_WORKING_DAY_REPORT_FAIL';
