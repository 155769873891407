export function msToTime(duration) {
  let minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor(duration / (1000 * 60 * 60));

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes;
}

export function msToHMin(time) {
  const hours = msToTime(time).split(':')[0];
  const minutes = msToTime(time).split(':')[1];
  return `${Number(hours) > 0 ? hours + ' h' : ''} ${minutes} min`;
}
