import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import CrewImg from '../../assets/svgs/crew.svg';
import CrossRedImg from '../../assets/svgs/close-red.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class DropDownWorker extends React.Component {
  render() {
    const {
      lead,
      onClose,
      user,
      id,
      removeWorkerFromGroup,
      updateWorkerOnGroup,
    } = this.props;
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper>
          {!lead && (
            <Wrap
              className="item"
              onClick={() => {
                updateWorkerOnGroup(id, {
                  userId: user.id,
                  userRole: 'CREW LEAD',
                });
              }}
            >
              <ReactSVG src={CrewImg} />
              <span>Set as crew lead</span>
            </Wrap>
          )}
          {lead?.id === user?.id && (
            <Wrap
              className="item"
              onClick={() => {
                updateWorkerOnGroup(id, {
                  userId: user.id,
                  userRole: 'WORKER',
                });
              }}
            >
              <ReactSVG src={CrewImg} />
              <span>Set as worker</span>
            </Wrap>
          )}

          <Wrap
            classN
            ame="item"
            onClick={() => {
              removeWorkerFromGroup(id, {
                userId: user.id,
                userRole:
                  user?.WorkersGroupUserAssign?.userRole?.toUpperCase() ||
                  'WORKER',
              });
            }}
          >
            <ReactSVG src={CrossRedImg} />
            <span>Remove from team</span>
          </Wrap>
        </Wrapper>
      </ClickAwayListener>
    );
  }
}

const Wrap = styled.div`
  display: flex;
  height: 75px;
  padding: 18px 22px;
  align-items: center;

  span {
    padding-left: 16px;
    padding-top: 1px;
  }
`;

const Wrapper = styled.div`
  z-index: 999;
  color: #3b494c;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 17px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 37px;
  width: 246px;
  .item:nth-child(1) {
    border-bottom: 1px solid #dddddd;
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  position: absolute;
  .role {
    border-bottom: 1px solid #c4c4c4;
  }
  .active {
    border-top: 1px solid #c4c4c4;
  }
`;

export default DropDownWorker;
