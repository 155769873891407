import * as types from './constants';

export const getWorkersGroup = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/workers/group' },
      actions: [
        types.GET_WORKERS_GROUP_REQUEST,
        types.GET_WORKERS_GROUP_SUCCESS,
        types.GET_WORKERS_GROUP_FAIL,
      ],
    },
  };
};
export const getWorkersGroupById = (id) => {
  return {
    type: 'API',
    payload: {
      request: { url: `/v1/workers/group/${id}` },
      actions: [
        types.GET_WORKERS_GROUP_BY_ID_REQUEST,
        types.GET_WORKERS_GROUP_BY_ID_SUCCESS,
        types.GET_WORKERS_GROUP_BY_ID_FAIL,
      ],
    },
  };
};

export const addWorkerGroup = (payload) => {
  return {
    type: 'API',
    payload: {
      request: { method: 'POST', url: `/v1/workers/group`, json: payload },
      actions: [
        types.ADD_WORKERS_GROUP_REQUEST,
        types.ADD_WORKERS_GROUP_SUCCESS,
        types.ADD_WORKERS_GROUP_FAIL,
      ],
    },
  };
};

export const updateWorkerGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/workers/group/${id}`,
        json: payload,
      },
      actions: [
        types.UPDATE_WORKERS_GROUP_REQUEST,
        types.UPDATE_WORKERS_GROUP_SUCCESS,
        types.UPDATE_WORKERS_GROUP_FAIL,
      ],
    },
  };
};

export const deleteWorkerGroup = (id) => ({
  type: 'API',
  payload: {
    request: {
      method: 'DELETE',
      url: `/v1/workers/group/${id}`,
    },
    actions: [
      types.DELETE_WORKERS_GROUP_REQUEST,
      types.DELETE_WORKERS_GROUP_SUCCESS,
      types.DELETE_WORKERS_GROUP_FAIL,
    ],
  },
});

export const assignWorkerToGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/workers/group/${id}/assign`,
        json: payload,
      },
      actions: [
        types.ASSIGN_WORKERS_TO_GROUP_REQUEST,
        types.ASSIGN_WORKERS_TO_GROUP_SUCCESS,
        types.ASSIGN_WORKERS_TO_GROUP_FAIL,
      ],
    },
  };
};

export const updateWorkerOnGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/workers/group/${id}/assign`,
        json: payload,
      },
      actions: [
        types.UPDATE_WORKERS_ON_GROUP_REQUEST,
        types.UPDATE_WORKERS_ON_GROUP_SUCCESS,
        types.UPDATE_WORKERS_ON_GROUP_FAIL,
      ],
    },
  };
};

export const removeWorkerFromGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'DELETE',
        url: `/v1/workers/group/${id}/assign`,
        json: payload,
      },
      actions: [
        types.REMOVE_WORKERS_FROM_GROUP_REQUEST,
        types.REMOVE_WORKERS_FROM_GROUP_SUCCESS,
        types.REMOVE_WORKERS_FROM_GROUP_FAIL,
      ],
    },
  };
};

export const assignTaskToGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/workers/group/${id}/assign/task`,
        json: payload,
      },
      actions: [
        types.ASSIGN_TASK_TO_GROUP_REQUEST,
        types.ASSIGN_TASK_TO_GROUP_SUCCESS,
        types.ASSIGN_TASK_TO_GROUP_FAIL,
      ],
    },
  };
};

export const removeTaskFromGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'DELETE',
        url: `/v1/workers/group/${id}/assign/task`,
        json: payload,
      },
      actions: [
        types.REMOVE_TASK_FROM_GROUP_REQUEST,
        types.REMOVE_TASK_FROM_GROUP_SUCCESS,
        types.REMOVE_TASK_FROM_GROUP_FAIL,
      ],
    },
  };
};

export const getTaskFromGroup = (id) => {
  return {
    type: 'API',
    payload: {
      request: {
        url: `/v1/workers/group/${id}`,
      },
      actions: [
        types.GET_TASK_FROM_GROUP_REQUEST,
        types.GET_TASK_FROM_GROUP_SUCCESS,
        types.GET_TASK_FROM_GROUP_FAIL,
      ],
    },
  };
};

export const assignLocationToGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/workers/group/${id}/assign/location`,
        json: payload,
      },
      actions: [
        types.ASSIGN_LOCATION_TO_GROUP_REQUEST,
        types.ASSIGN_LOCATION_TO_GROUP_SUCCESS,
        types.ASSIGN_LOCATION_TO_GROUP_FAIL,
      ],
    },
  };
};

export const removeLocationFromGroup = (id, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'DELETE',
        url: `/v1/workers/group/${id}/assign/location`,
        json: payload,
      },
      actions: [
        types.REMOVE_LOCATION_FROM_GROUP_REQUEST,
        types.REMOVE_LOCATION_FROM_GROUP_SUCCESS,
        types.REMOVE_LOCATION_FROM_GROUP_FAIL,
      ],
    },
  };
};
