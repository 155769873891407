import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import LocationBlock from './LocationBlock';
import AddLocationPopup from '../../components/Popups/AddLocationPopup';
import deleteEmptyProperties from '../../utils/deleteEmptyProperties';

class AllLocationsAdminPage extends React.Component {
  state = {
    openAddPopup: false,
  };

  componentDidMount() {
    const { getAllLocations } = this.props;
    getAllLocations();
  }

  componentDidUpdate(prevProps) {
    const { isLocationDeleted, getAllLocations, isLocationAdded } = this.props;
    if (!prevProps.isLocationDeleted && isLocationDeleted) {
      getAllLocations();
    }
    if (!prevProps.isLocationAdded && isLocationAdded) {
      this.setState({ openAddPopup: false });
      getAllLocations();
    }
  }

  handleSubmit = (values) => {
    const { addLocation } = this.props;

    addLocation(deleteEmptyProperties(values));
  };

  handleOpenAddPopup = () => {
    this.setState(({ openAddPopup }) => ({
      openAddPopup: !openAddPopup,
    }));
  };

  render() {
    const { listAllLocations, history, deleteLocation } = this.props;
    const { openAddPopup } = this.state;

    listAllLocations.sort(function (a, b) {
      if (!a.name.localeCompare(b.name)) {
        return a.address.localeCompare(b.address);
      }
      return a.name.localeCompare(b.name);
    });

    listAllLocations.sort(function (a, b) {
      if (a.name && !b.name) {
        return -1;
      }
    });

    return (
      <Wrapper>
        <div className="title-bar">
          <h1>Locations</h1>
          <button
            id="add-location"
            className="btn"
            onClick={this.handleOpenAddPopup}
          >
            Add
          </button>
        </div>

        <div className="container">
          {listAllLocations.map((location, index) => {
            let additional;
            if (location.users.length > 3) {
              additional = location.users.length - 3;
            }
            return (
              <LocationBlock
                history={history}
                deleteLocation={deleteLocation}
                location={location}
                key={index}
                additional={additional}
              />
            );
          })}
        </div>
        {openAddPopup && (
          <AddLocationPopup
            onClose={this.handleOpenAddPopup}
            handleSubmit={this.handleSubmit}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .card {
    padding: 10px 20px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }
  p.heading,
  p.heading span {
    max-width: calc(100% - 30px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
    color: var(--grey-text);
  }
  p.heading span {
    max-width: 100%;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 3px;
    color: var(--black-text);
  }
  @media (min-width: 600px) {
    p.heading,
    p.heading span {
      max-width: calc(100% - 190px);
    }
    p.heading {
      margin: 0;
    }
    p.heading span {
      max-width: 100%;
    }
    .avatars {
      position: absolute;
      top: 14px;
      right: 45px;
    }
  }
`;

export default withRouter(AllLocationsAdminPage);
