import React from 'react';
import IconTimer from '../../assets/svgs/icon-timer.svg';

let interval;
let timerTime = 0;
const pad = (number) => {
  return number < 10 ? '0' + number : number;
};
const getDifferenceDate = (firstDate, secondDate) => {
  let different = firstDate - secondDate;

  let hours = Math.floor((different % 86400000) / 3600000);
  let minutes = Math.round(((different % 86400000) % 3600000) / 60000);
  let seconds = Math.round((different % 60000) / 1000);
  return {
    h: hours,
    min: minutes,
    sec: seconds,
  };
};

class Timer extends React.Component {
  state = {
    showTimerOnId: null,
    fullTime: {},
  };

  componentDidMount() {
    const { activeJob, listAllLocations } = this.props;
    if (activeJob && listAllLocations.length) {
      this.firstCalc(listAllLocations, activeJob);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeJob, listAllLocations } = this.props;
    if (
      activeJob &&
      !prevProps.listAllLocations.length &&
      listAllLocations.length
    ) {
      this.firstCalc(listAllLocations, activeJob);
    }
  }

  firstCalc = (listAllLocations, activeJob) => {
    const locationActive = listAllLocations.find(
      (location) => location.id === activeJob.locationId,
    );
    this.setState({ showTimerOnId: locationActive.id }, () => {
      let isTimerRunning = !Boolean(activeJob.endDate);

      if (activeJob.startDate) {
        if (isTimerRunning) {
          const nowDate = new Date();

          const difference = getDifferenceDate(
            nowDate,
            new Date(activeJob.startDate),
          );

          timerTime =
            difference.h * 60 * 60 + difference.min * 60 + difference.sec;
          this.incrementTimer();

          this.handleStart();
        } else {
          const difference = getDifferenceDate(
            new Date(activeJob.endDate),
            new Date(activeJob.startDate),
          );
          timerTime =
            difference.h * 60 * 60 + difference.min * 60 + difference.sec;
          this.incrementTimer();
        }
      }
    });
  };

  incrementTimer = () => {
    timerTime++;

    const seconds = Math.floor(timerTime % 60);
    const minutes = Math.floor((timerTime / 60) % 60);
    const hours = Math.floor((timerTime / (60 * 60)) % 24);

    this.setState({
      fullTime: {
        h: pad(hours),
        min: pad(minutes),
        sec: pad(seconds),
      },
    });
  };

  handleStart = () => {
    clearInterval(interval);
    interval = setInterval(this.incrementTimer, 1000);
  };
  componentWillUnmount() {
    timerTime = 0;
    clearInterval(interval);
  }

  render() {
    const { fullTime } = this.state;

    return (
      <div className="timer">
        <img src={IconTimer} /> {fullTime.h || '00'}:{fullTime.min || '00'}
      </div>
    );
  }
}

export default Timer;
