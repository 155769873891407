import React from 'react';
import Modal from '../../Modal';
import { ReactSVG } from 'react-svg';
import CloseSVG from '../../../assets/images/close.svg';

const DeleteWorkerPopup = ({ onClose, onDeleteUser }) => {
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div className="modal-title">Delete Worker?</div>
            <div className="modal-close" onClick={onClose}>
              <ReactSVG src={CloseSVG} />
            </div>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this worker?</p>
          </div>
          <div className="cta-bar">
            <button
              className="btn alert"
              onClick={() => {
                onDeleteUser();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWorkerPopup;
