import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DropZoneUpload from '../../DropZoneUpload';
import { ReactSVG } from 'react-svg';
import CloseGreyImg from '../../../assets/svgs/close-grey.svg';
import ImagePopup from '../../ImagePopup';

const NotesPopup = React.memo(
  ({
    open,
    onClose,
    joinJobImg,
    firstScreen,
    createJob,
    updateJob,
    updateNoteOnJob,
  }) => {
    const [note, setNote] = useState(firstScreen ? firstScreen.comment : '');
    const [logos, setLogos] = useState(
      firstScreen?.img?.length ? firstScreen.img.map((img) => img.imgUrl) : [],
    );
    const [images, setImages] = useState(
      firstScreen?.img?.length ? firstScreen.img : [],
    );
    const [afterDel, setAfterDel] = useState(null);
    const [popupImg, setPopupImg] = useState(false);

    const clean = () => {
      setNote('');
      setLogos([]);
      setImages([]);
    };

    useEffect(() => {
      return () => {
        clean();
      };
    }, [open]);

    return (
      <StyledDialog disableBackdropClick open={open} onClose={onClose}>
        <Wrapper>
          <div className="modal-header">
            <h2>Add entry</h2>
            <div className="close-btn" onClick={onClose}>
              <ReactSVG src={CloseGreyImg} />
            </div>
          </div>
          <h2>Notes</h2>
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={6}
            value={note}
            placeholder="Type your notes here..."
            onChange={(event) => {
              setNote(event.target.value);
            }}
          />
          <h2>Images</h2>
          <AddImg>
            <DropZoneUpload
              setPopupImg={setPopupImg}
              jobId={firstScreen ? firstScreen.jobId : null}
              multiple={true}
              value={logos}
              onChange={(file) => {
                setLogos(file);
              }}
              setImages={setImages}
              images={images}
              setAfterDel={setAfterDel}
            />
          </AddImg>
          <WrapButton>
            <MainButton
              onClick={() => {
                if (firstScreen.jobId) {
                  updateJob({
                    jobId: firstScreen.jobId,
                    payload: {
                      startDate: firstScreen.startDate,
                      endDate: firstScreen.endDate,
                      tasks: firstScreen.tasks,
                      snowRemovalOrderId: firstScreen.snowRemovalOrderId,
                    },
                  });
                  updateNoteOnJob({
                    jobId: firstScreen.jobId,
                    comment: note,
                  });
                  if (logos.length) {
                    const imagesFiltered = images.filter((x) =>
                      logos.some((y) => x.imgUrl === y),
                    );

                    const imagesIds = imagesFiltered.map(
                      (image) => image.imgId || image.id,
                    );

                    const imagesIdsOnTask =
                      afterDel !== null
                        ? afterDel
                        : firstScreen.img.map((img) => img.imgId || img.id);

                    const uniqImgIds = [
                      ...new Set([...imagesIdsOnTask, ...imagesIds]),
                    ];

                    joinJobImg(firstScreen.jobId, {
                      imgIds: uniqImgIds,
                    });
                  }
                } else {
                  let imagesIds = [];
                  if (logos.length) {
                    const imagesFiltered = images.filter((x) =>
                      logos.some((y) => x.imgUrl === y),
                    );

                    imagesIds = imagesFiltered.map(
                      (image) => image.imgId || image.id,
                    );
                  }
                  createJob({
                    locationId: firstScreen.locationId,
                    payload: {
                      comment: note,
                      userId: firstScreen.userId,
                      startDate: firstScreen.startDate,
                      endDate: firstScreen.endDate,
                      tasks: firstScreen.tasks,
                      imgIds: [...imagesIds],
                      snowRemovalOrderId: firstScreen.snowRemovalOrderId,
                    },
                  });
                  onClose();
                }
              }}
            >
              SAVE
            </MainButton>
          </WrapButton>
        </Wrapper>
        <ImagePopup
          open={Boolean(popupImg)}
          src={popupImg}
          onClose={setPopupImg}
        />
      </StyledDialog>
    );
  },
);

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 20px;
  min-width: 200px !important;
  color: #333333;
  background: #fafafa;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
  }

  .close-btn {
    cursor: pointer;
  }

  textarea {
    padding: 15px;
    width: 481px;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 14px;
  }

  @media (max-width: 550px) {
    padding: 20px 15px;
    textarea {
      width: auto;
      min-width: 300px;
    }
  }
`;

const AddImg = styled.div``;

const WrapButton = styled.div`
  display: flex;
  justify-content: center;
`;

const MainButton = styled.button`
  cursor: pointer;
  background: #61933a;
  border-radius: 5px;
  width: 294px;
  height: 40px;
  color: #fff;
  margin-top: 27px;
  border: none;
  outline: none;
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default NotesPopup;
