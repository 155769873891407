import React, { Fragment, useState, useRef } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Formik, Field, Form } from 'formik';
import { TASK_STATUS_ID_YES, TASK_STATUS_ID_NO } from '../../../utils/tasks';
import TimeSection from '../../TimeSection';
import { ReactSVG } from 'react-svg';
import CloseGreyImg from '../../../assets/svgs/close-grey.svg';
import Spinner from '../../Spinner';

const getTaskName = (task) => `task-${task.id}`;
const validateRequired = (values, tasks) =>
  tasks.every((task) => Boolean(values[getTaskName(task)]));

const getTaskStatusIdValue = (task, values) =>
  values[getTaskName(task)] === 'yes' ? TASK_STATUS_ID_YES : TASK_STATUS_ID_NO;

const groupTasksByPlace = (tasks) =>
  tasks.reduce((acc, task) => {
    const { taskPlaceId } = task;
    if (!acc[taskPlaceId]) {
      acc[taskPlaceId] = {
        place: task.place,
        tasks: [],
      };
    }

    acc[taskPlaceId].tasks.push(task);
    return acc;
  }, {});

const isTaskDone = (tasks) => {
  return tasks.JobsTasks &&
    tasks.JobsTasks.taskStatusId &&
    tasks.JobsTasks.taskStatusId === 1
    ? 'yes'
    : 'no';
};

const VALIDATION_MESSAGE_FIELD_REQUIRED = 'Please select each value';
const VALIDATION_MESSAGE_SRN_REQUIRED = 'Please fill "Snow Removal PO Number"';

const ActivitiesPopup = ({
  open,
  onClose,
  tasksMain,
  handleFirstScreen,
  handleOpenNotesPopup,
  job,
  jobTasks,
}) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [isValidationMessageTime, setValidationTimeMessage] = useState(false);
  const childCompRef = useRef(null);
  const [srn, setSRN] = useState(job?.snowRemovalOrder?.snowRemovalOrderId);

  let tasks = [];
  if (open) {
    if (job.season?.seasonId !== 2) {
      tasks = tasksMain.map((task) => {
        const taskFound = jobTasks.find((taskJob) => taskJob.id === task.id);
        return taskFound || { ...task, JobsTasks: { taskStatusId: 0 } };
      });
    } else {
      tasks = job.workersGroup?.task.map((task) => {
        const taskFound = jobTasks.find((taskJob) => taskJob.id === task.id);
        return taskFound || { ...task, JobsTasks: { taskStatusId: 0 } };
      });
    }
  }
  const placeMap = groupTasksByPlace(tasks);
  const handleSubmit = (values) => {
    const startTime = childCompRef.current.state.startTime;
    const stopTime = childCompRef.current.state.stopTime;

    const startISOTime = new Date(
      startTime.y,
      startTime.m,
      startTime.d,
      startTime.h,
      startTime.min,
      startTime.sec,
    ).toISOString();

    const stopISOTime = new Date(
      stopTime.y,
      stopTime.m,
      stopTime.d,
      stopTime.h,
      stopTime.min,
      stopTime.sec,
    ).toISOString();

    if (stopISOTime < startISOTime) {
      setValidationTimeMessage(true);
      return;
    }

    if (!validateRequired(values, tasks)) {
      setValidationMessage(VALIDATION_MESSAGE_FIELD_REQUIRED);
      return;
    }
    if (values?.['task-7'] === 'yes' && !srn) {
      setValidationMessage(VALIDATION_MESSAGE_SRN_REQUIRED);
      return;
    }

    const answers = tasks.map((task) => ({
      jobId: job.id,
      taskId: task.id,
      taskStatusId: getTaskStatusIdValue(task, values),
    }));

    handleFirstScreen({
      jobId: job.id,
      img: job.img,
      comment: job.comment,
      startDate: startISOTime,
      endDate: stopISOTime,
      tasks: answers,
      snowRemovalOrderId: srn,
    });

    handleOpenNotesPopup();
  };

  const handleChange = () => {
    if (validationMessage === VALIDATION_MESSAGE_FIELD_REQUIRED) {
      setValidationMessage('');
    }
  };

  const objectInitialOption = () => {
    const objArr = tasks.map((task) => {
      return {
        [getTaskName(task)]: isTaskDone(task),
      };
    });
    const obj = Object.assign({}, ...objArr);
    return obj;
  };

  const handleChangeTime = () => {
    setValidationTimeMessage(false);
  };

  const closeAndClean = () => {
    clean();
    onClose();
  };

  const clean = () => {
    setValidationTimeMessage(false);
    setValidationMessage('');
  };
  let initialData = {};
  const all = tasks.filter((t) => t.JobsTasks);
  const sjoulDisplay = tasks.length === all.length && tasks.length !== 0;
  if (sjoulDisplay) {
    initialData = objectInitialOption();
  }
  return (
    <StyledDialog open={open} onClose={closeAndClean}>
      <Wrapper>
        <div className="modal-header">
          <h2>Edit Entry</h2>
          <div className="close-btn" onClick={closeAndClean}>
            <ReactSVG src={CloseGreyImg} />
          </div>
        </div>
        <Error>
          {isValidationMessageTime && (
            <span>
              <b>End</b> time needs to be later than <b>Start</b> time
            </span>
          )}
        </Error>
        <TimeSection
          job={job}
          ref={childCompRef}
          handleChangeTime={handleChangeTime}
        />
        {!sjoulDisplay ? (
          <Spinner />
        ) : (
          <Formik initialValues={initialData} onSubmit={handleSubmit}>
            {({ values }) => (
              <Form onChange={handleChange}>
                <p>Services performed at this location:</p>
                <>
                  {Object.keys(placeMap).map((placeId) => {
                    placeMap[placeId].tasks.sort(
                      (taskOne, taskTwo) => taskOne.id - taskTwo.id,
                    );
                    return (
                      <Fragment
                        key={`${placeId} - ${placeMap[placeId]?.JobsTasks?.taskStatusId}`}
                      >
                        <h2>{placeMap[placeId]?.place?.name}</h2>
                        {placeMap[placeId].tasks.map((task) => (
                          <div key={task.id}>
                            <Row>
                              <span className="task-name">
                                {task.name.toLocaleLowerCase()}
                              </span>
                              <div className="switch-field">
                                <Field
                                  type="radio"
                                  name={getTaskName(task)}
                                  id={`yes-${task.id}`}
                                  value="yes"
                                />
                                <label htmlFor={`yes-${task.id}`}>Yes</label>
                                <Field
                                  type="radio"
                                  name={getTaskName(task)}
                                  id={`no-${task.id}`}
                                  value="no"
                                />
                                <label htmlFor={`no-${task.id}`}>No</label>
                              </div>
                            </Row>
                            {task.id === 7 && values?.['task-7'] === 'yes' && (
                              <WrapperInput>
                                <label htmlFor="">Snow Removal PO Number</label>
                                <input
                                  type="text"
                                  defaultValue={srn}
                                  onChange={(event) =>
                                    setSRN(event.target.value)
                                  }
                                />
                              </WrapperInput>
                            )}
                          </div>
                        ))}
                      </Fragment>
                    );
                  })}
                </>
                <Error>{validationMessage}</Error>
                <ButtonsWrap>
                  <button onClick={onClose} type="reset">
                    CANCEL
                  </button>
                  <button className="active" type="submit">
                    NEXT
                  </button>
                </ButtonsWrap>
              </Form>
            )}
          </Formik>
        )}
      </Wrapper>
    </StyledDialog>
  );
};
const Error = styled.span`
  color: #d60000;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  align-items: baseline;
  span {
    font-weight: bold;
  }
  .task-name {
    text-transform: capitalize;
    font-weight: 400;
  }
`;

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 20px;
  min-width: 400px !important;
  width: 600px;
  .close-btn {
    cursor: pointer;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
  }
  .switch-field {
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 4px;
  }

  .switch-field input {
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    border: 0px;
    overflow: hidden;
    position: absolute !important;
  }

  .switch-field label {
    background: rgb(248, 248, 248);
    color: rgb(51, 51, 51);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    line-height: 34px;
    padding: 0;
    width: 60px;
    height: 34px;
    font-weight: bold;
    transition: all 0.1s ease-in-out 0s;
  }
  .switch-field input:checked + label {
    background-color: rgb(97, 147, 58);
    color: rgb(255, 255, 255);
    box-shadow: none;
    border-radius: 3px;
  }

  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

const ButtonsWrap = styled.div`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  margin: 65px 10px 0 10px;
  justify-content: space-around;
  button {
    width: 117px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #61933a;
    color: #61933a;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    &.active {
      background: #61933a;
      color: #fff;
      margin-left: 24px;
    }
  }
`;

const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  margin-bottom: 20px;
  height: 38px;
  align-items: center;
  input {
    outline-color: #61933a;
    width: 128px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
  }
`;

export default ActivitiesPopup;
