export const GET_LIST_LOCATIONS_REQUEST = 'GET_LIST_LOCATIONS_REQUEST';
export const GET_LIST_LOCATIONS_SUCCESS = 'GET_LIST_LOCATIONS_SUCCESS';
export const GET_LIST_LOCATIONS_FAIL = 'GET_LIST_LOCATIONS_FAIL';

export const GET_LIST_TASKS_ON_LOCATIONS_REQUEST =
  'GET_LIST_TASKS_ON_LOCATIONS_REQUEST';
export const GET_LIST_TASKS_ON_LOCATIONS_SUCCESS =
  'GET_LIST_TASKS_ON_LOCATIONS_SUCCESS';
export const GET_LIST_TASKS_ON_LOCATIONS_FAIL =
  'GET_LIST_TASKS_ON_LOCATIONS_FAIL';

export const GET_TASK_PLACE_REQUEST = 'GET_TASK_PLACE_REQUEST';
export const GET_TASK_PLACE_SUCCESS = 'GET_TASK_PLACE_SUCCESS';
export const GET_TASK_PLACE_FAIL = 'GET_TASK_PLACE_FAIL';

export const DELETE_TASK_PLACE_REQUEST = 'DELETE_TASK_PLACE_REQUEST';
export const DELETE_TASK_PLACE_SUCCESS = 'DELETE_TASK_PLACE_SUCCESS';
export const DELETE_TASK_PLACE_FAIL = 'DELETE_TASK_PLACE_FAIL';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';

export const UPDATE_TASK_PLACE_REQUEST = 'UPDATE_TASK_PLACE_REQUEST';
export const UPDATE_TASK_PLACE_SUCCESS = 'UPDATE_TASK_PLACE_SUCCESS';
export const UPDATE_TASK_PLACE_FAIL = 'UPDATE_TASK_PLACE_FAIL';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL';

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL';

export const ADD_TASK_CATEGORY_REQUEST = 'ADD_TASK_CATEGORY_REQUEST';
export const ADD_TASK_CATEGORY_SUCCESS = 'ADD_TASK_CATEGORY_SUCCESS';
export const ADD_TASK_CATEGORY_FAIL = 'ADD_TASK_CATEGORY_FAIL';

export const GET_ALL_LOCATIONS_REQUEST = 'GET_ALL_LOCATIONS_REQUEST';
export const GET_ALL_LOCATIONS_SUCCESS = 'GET_ALL_LOCATIONS_SUCCESS';
export const GET_ALL_LOCATIONS_FAIL = 'GET_ALL_LOCATIONS_FAIL';

export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';

export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const ADD_LOCATION_REQUEST = 'ADD_LOCATION_REQUEST';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAIL = 'ADD_LOCATION_FAIL';

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

export const ASSIGN_LOCATION_REQUEST = 'ASSIGN_LOCATION_REQUEST';
export const ASSIGN_LOCATION_SUCCESS = 'ASSIGN_LOCATION_SUCCESS';
export const ASSIGN_LOCATION_FAIL = 'ASSIGN_LOCATION_FAIL';

export const ADD_COMMENT_TO_LOCATION_REQUEST =
  'ADD_COMMENT_TO_LOCATION_REQUEST';
export const ADD_COMMENT_TO_LOCATION_SUCCESS =
  'ADD_COMMENT_TO_LOCATION_SUCCESS';
export const ADD_COMMENT_TO_LOCATION_FAIL = 'ADD_COMMENT_TO_LOCATION_FAIL';

export const JOIN_LOCATION_TO_IMG_REQUEST = 'JOIN_LOCATION_TO_IMG_REQUEST';
export const JOIN_LOCATION_TO_IMG_SUCCESS = 'JOIN_LOCATION_TO_IMG_SUCCESS';
export const JOIN_LOCATION_TO_IMG_FAIL = 'JOIN_LOCATION_TO_IMG_FAIL';

export const CLEAN_LOCATION = 'CLEAN_LOCATION';
