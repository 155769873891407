import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { formatTimeLowerCase } from '../../utils/formatDate';
import { getInitials } from '../../utils/initials';
import { ReactSVG } from 'react-svg';
import ClockInactive from '../../assets/svgs/clock-inactive.svg';
import MenuDots from '../../assets/svgs/menu-image.svg';
import ImageImg from '../../assets/svgs/imageImg.svg';
import NotesImg from '../../assets/svgs/noteImg.svg';
import WorkersGroup from '../../assets/svgs/workersGroup.svg';
import DropDownJob from './DropDownJob';
import DeleteJobPopup from '../../components/Popups/DeleteJobPopup';
import ActivitiesPopup from '../../components/Popups/ActivitiesPopup';
import { TASK_STATUS_ID_YES } from '../../utils/tasks';
import NotesPopup from '../../components/Popups/NotesPopup';
import { msToHMin } from '../../utils/msToTime';

const isDone = (task) =>
  task.JobsTasks && task.JobsTasks.taskStatusId === TASK_STATUS_ID_YES;

export default class JobBlock extends React.Component {
  state = {
    openMenu: false,
    openConfirmDelete: false,
    openActivitiesPopup: false,
    openNotesPopup: false,
    firstScreen: null,
  };
  handleOpenMenu = () => {
    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };
  handleDelete = () => {
    this.setState({
      openMenu: false,
      openConfirmDelete: !this.state.openConfirmDelete,
    });
  };

  handleActivitiesPopup = () => {
    this.setState({
      openMenu: false,
      openActivitiesPopup: !this.state.openActivitiesPopup,
    });
  };

  handleOpenNotesPopup = () => {
    this.setState({
      openActivitiesPopup: false,
      openNotesPopup: !this.state.openNotesPopup,
    });
  };

  handleFirstScreen = (obj) => {
    this.setState({ firstScreen: obj });
  };

  render() {
    const {
      job,
      updateJob,
      deleteJob,
      joinJobImg,
      updateNoteOnJob,
      getLocationById,
      locationMain,
    } = this.props;
    const {
      openMenu,
      openConfirmDelete,
      openActivitiesPopup,
      openNotesPopup,
      firstScreen,
    } = this.state;
    const completedTasks = job.tasks.filter(
      (task) => task.JobsTasks.taskStatusId === 1,
    );
    const completedTasksLength = completedTasks.length;
    const time =
      job.startDate && job.endDate
        ? new Date(job.endDate).getTime() - new Date(job.startDate).getTime()
        : null;

    return (
      <Wrap className="card">
        <MainBlock
          onClick={() => {
            getLocationById(job.location.id);
            if (
              (job.season?.seasonId === 2 && !job.workersGroup) ||
              (job.season?.seasonId === 1 && !job.tasks.length)
            ) {
              console.log('4oto poWlo ne tak(');
            } else {
              this.handleActivitiesPopup();
            }
          }}
        >
          <div>
            <p className="heading">
              <span className="main">{job.location.name}</span>
              <span className="full-address">
                {' '}
                {job.location.address}
                {job.location.city ? ',' : ''} {job.location.city}
                {job.location.province ? ',' : ''} {job.location.province}
                {job.location.postalCode}
              </span>
            </p>
            <WrapperName>
              {job.workersGroup?.name ? (
                <>
                  <ReactSVG
                    style={{ marginRight: 10, width: 20 }}
                    src={WorkersGroup}
                  />
                  <div>{job.workersGroup.name}</div>
                </>
              ) : (
                <>
                  <Avatar color={job.user.color}>
                    {getInitials({
                      name: job.user.name + ' ' + job.user.lastName,
                    })}
                  </Avatar>
                  <div className="full-name">
                    {job.user.name + ' ' + job.user.lastName}
                  </div>
                </>
              )}
              {completedTasksLength !== 0 && (
                <CompletedTasksWrap theme={job.season}>
                  {completedTasksLength}
                  <span style={{ marginLeft: '3px' }}>
                    {completedTasksLength > 1 ? 'tasks' : 'task'}
                  </span>
                </CompletedTasksWrap>
              )}
              <WrapSvgs>
                {job.comment && <img src={NotesImg} />}
                {Boolean(job.img.length) && <img src={ImageImg} />}
              </WrapSvgs>
            </WrapperName>
          </div>
          <div className="grey">
            {time ? <HoursWorked>{msToHMin(time)}</HoursWorked> : null}
            <StyledStrong>
              {/* <ReactSVG className="clock" src={ClockInactive} wrapper="span" /> */}
              {formatTimeLowerCase(job.startDate)} -{' '}
              {!job.endDate ? 'in work' : formatTimeLowerCase(job.endDate)}
            </StyledStrong>
          </div>
        </MainBlock>
        <a className="card-menu" onClick={this.handleOpenMenu}>
          <ReactSVG className="dropdown" src={MenuDots} />
          {openMenu && (
            <DropDownJob
              getLocationById={getLocationById}
              locationId={job.location.id}
              handleDelete={this.handleDelete}
              handleEdit={this.handleActivitiesPopup}
              onClose={this.handleOpenMenu}
            />
          )}
        </a>
        <DeleteJobPopup
          open={openConfirmDelete}
          onClose={this.handleDelete}
          job={job}
          deleteJob={deleteJob}
        />
        {openActivitiesPopup && (
          <ActivitiesPopup
            jobTasks={job.tasks}
            locationId={job.locationId}
            open={openActivitiesPopup}
            onClose={this.handleActivitiesPopup}
            tasksMain={locationMain?.tasks || []}
            handleOpenNotesPopup={this.handleOpenNotesPopup}
            handleFirstScreen={this.handleFirstScreen}
            job={job}
          />
        )}
        {openNotesPopup && (
          <NotesPopup
            updateNoteOnJob={updateNoteOnJob}
            joinJobImg={joinJobImg}
            firstScreen={firstScreen}
            open={openNotesPopup}
            onClose={this.handleOpenNotesPopup}
            updateJob={updateJob}
          />
        )}
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  .main {
    color: #333333;
    font-weight: 600;
  }
  .card-menu {
    position: relative;
    width: 30px;
    cursor: pointer;
    padding-left: 10px;
  }
`;

const Avatar = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ color }) => (color ? color : '#683ab7')};
  color: #fff;
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  border: ${({ color }) =>
    color ? `1px solid ${color}` : '1px solid #683ab7'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;

const WrapperName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  .full-name {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
  }
`;

const MainBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 29px;
  font-family: Roboto;
  font-size: 14px;
  color: #777777;
  cursor: pointer;
`;

const WrapSvgs = styled.div`
  display: flex;

  img {
    margin-right: 8px;
  }
`;

const CompletedTasksWrap = styled.div`
  margin: 0 10px;
  background-color: ${(props) => {
    return props.theme.seasonId === 2 ? '#F7E6AE' : '#CEF7AE';
  }};
  color: #333333;
  padding: 2px 6px;
  border-radius: 3px;
`;

const HoursWorked = styled.div`
  font-weight: 600;
  text-align: end;
  color: #616161;
`;

const StyledStrong = styled.span`
  svg {
    margin: 0 5px;
  }
`;
