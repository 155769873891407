import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import StyledSelect from '../../StyledSelect';
import { getDefaultValueForStyledSelect } from '../../../utils/options';

export default class Form extends React.Component {
  render() {
    const { user, handleSubmit } = this.props;

    return (
      <Formik
        initialValues={{
          name: user ? user.name : '',
          lastName: user ? user.lastName : '',
          companyName: user ? user.companyName : '',
          address: user ? user.address : '',
          city: user ? user.city : '',
          province: user ? user.province : '',
          postalCode: user ? user.postalCode : '',
          phone: user ? user.phone : '',
          email: user ? user.email : '',
          role: user ? user.role : '',
          password: user ? user.password : '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .max(255, 'is too long')
            .min(3, 'must be at least 3 characters')
            .required('is required'),
          lastName: Yup.string().trim().nullable(),
          address: Yup.string().nullable(),
          phone: Yup.string().nullable(),
          email: Yup.string().required('is required'),
          role: Yup.string().required('is required'),
          password: user
            ? Yup.string().nullable()
            : Yup.string()
                .min(8, 'must be at least 8 characters')
                .required('is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          } = props;

          const states = [{ label: 'ON', value: 'ON' }];
          const roles = [
            { label: 'Worker', value: 'worker' },
            { label: 'Supervisor', value: 'supervisor' },
            { label: 'Mechanic', value: 'mechanic' },
          ];

          return (
            <form id="signup-form" noValidate="" onSubmit={handleSubmit}>
              <div className="form-row halfs">
                <div>
                  <label htmlFor="name">First Name</label>
                  <input
                    id="name"
                    value={values.name}
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      // handleRemoveError('name');
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name && (
                    <Error className="form-error-message">
                      First Name {errors.name}
                    </Error>
                  )}
                </div>

                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    value={values.lastName}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.lastName && (
                    <Error className="form-error-message">
                      Last Name {errors.lastName}
                    </Error>
                  )}
                </div>
              </div>

              <div className="form-row">
                <label htmlFor="companyName">Company Name</label>
                <input
                  id="companyName"
                  value={values.companyName}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.companyName && (
                  <Error className="form-error-message">
                    Company Name {errors.companyName}
                  </Error>
                )}
              </div>

              <div className="form-row">
                <label htmlFor="address">Address</label>
                <input
                  id="address"
                  value={values.address}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address && (
                  <Error className="form-error-message">
                    Address {errors.address}
                  </Error>
                )}
              </div>

              <div className="form-row flex">
                <div className="city">
                  <div className="form-row">
                    <label htmlFor="city">City</label>
                    <input
                      className="city"
                      id="city"
                      value={values.city}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.city && (
                      <Error className="form-error-message">
                        City {errors.city}
                      </Error>
                    )}
                  </div>
                </div>
                <div className="province">
                  <div className="form-row">
                    <label htmlFor="province">Province</label>
                    <StyledSelect
                      value={getDefaultValueForStyledSelect(values.province)}
                      id="province"
                      name="province"
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      options={states}
                      field="name"
                      menuPosition="fixed"
                    />
                  </div>
                </div>
                <div className="postal-code">
                  <div className="form-row">
                    <label htmlFor="postalCode">Postal Code</label>
                    <input
                      className="postal-code"
                      defaultValue={values.postalCode}
                      type="text"
                      name="postalCode"
                      maxLength="6"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row halfs">
                <div
                  className={
                    errors.phone && touched.phone
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="phone">Phone</label>
                  <input
                    id="phone"
                    defaultValue={values.phone}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.phone && (
                    <Error className="form-error-message">
                      Phone {errors.phone}
                    </Error>
                  )}
                </div>
                <div
                  className={
                    errors.role && touched.role
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="role">Role</label>
                  <StyledSelect
                    value={getDefaultValueForStyledSelect(values.role)}
                    id="role"
                    name="role"
                    onChange={(e) => {
                      handleChange(e);
                      // handleRemoveError('role');
                    }}
                    setFieldValue={(name, value) => {
                      setFieldValue(name, value);
                      // handleRemoveError('role');
                    }}
                    setFieldTouched={setFieldTouched}
                    options={roles}
                    field="name"
                    menuPosition="fixed"
                  />
                  {errors.role && touched.role && (
                    <Error className="form-error-message">
                      Role {errors.role}
                    </Error>
                  )}
                </div>
              </div>
              <div className="form-row halfs">
                <div
                  className={
                    errors.email && touched.email
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    defaultValue={values.email}
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      // handleRemoveError('email');
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <Error className="form-error-message">
                      Email {errors.email}
                    </Error>
                  )}
                </div>
                <div
                  className={
                    errors.password && touched.password
                      ? 'form-row has-error'
                      : 'form-row'
                  }
                >
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    defaultValue={values.password}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder="••••••"
                    spellCheck="false"
                    type="password"
                    onChange={(e) => {
                      handleChange(e);
                      // handleRemoveError('password');
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password && (
                    <Error className="form-error-message">
                      Password {errors.password}
                    </Error>
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const Error = styled.div`
  color: var(--alert);
`;
