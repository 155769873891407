import { createMuiTheme } from '@material-ui/core';

const theme = {
  overrides: {
    MuiFormControlLabel: {
      root: {
        marginRight: -15,
        marginLeft: 0,
        marginBottom: 0,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 14,
        lineHeight: 17,
      },
      body1: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 14,
      },
      body2: {
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    MuiIconButton: {
      label: {
        width: 32,
        height: 32,
        border: '1px solid #DDDDDD',
        borderRadius: 4,
      },
    },
  },
};
export const checkBoxMaterialTheme = createMuiTheme(theme);
