export const getInitials = (user) => {
  let initials = '';

  if (user) {
    let allFirstLetters = user.name.split(' ');
    allFirstLetters = allFirstLetters.map((i) => i.charAt(0));
    if (allFirstLetters.length !== 1)
      initials =
        allFirstLetters[0] + allFirstLetters[allFirstLetters.length - 1];
    else initials = allFirstLetters[0];
  }
  return initials.toUpperCase();
};
