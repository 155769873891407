import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

const colourStyles = {
  input: (styles) => ({ ...styles, maxHeight: 40 }),
  indicatorSeparator: () => ({ display: 'none' }),
  placeholder: (styles) => ({ ...styles }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (base, { selectProps: { transparent } }) => ({
    ...base,
    boxShadow: 'none',
    backgroundColor: transparent ? 'transparent' : 'inherit',
    border: transparent ? 'none' : '1px solid #ccc',
    width: transparent ? '80px' : 'inherit',
    height: 40,
    minHeight: 40,
    '&:hover': {
      border: transparent ? 'none' : '2px solid #61933A',
    },
    '&:active': {
      border: transparent ? 'none' : '2px solid #61933A',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: 40,
    padding: '2px 15px',
    flexWrap: 'nowrap',
  }),
  indicatorsContainer: (provided, { selectProps: { transparent } }) => ({
    ...provided,
    height: 40,
    '.select__indicator': {
      padding: 5,
      display: transparent ? 'none' : 'inherit',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    position: 'inherit',
    transform: 'none',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#61933A'
      : isFocused
      ? '#61933A'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? '#fff' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? '#61933A' : '#61933A'),
    },
  }),
  container: (base) => ({ ...base, zIndex: 500, position: 'initial' }),
  menu: (provided) => ({ ...provided, zIndex: 999999 }),
};

const StyledSelect = ({
  transparent,
  save,
  values,
  value,
  options,
  id,
  name,
  timeForm,
  placeholder,
  planningForm,
  setFieldTouched,
  setFieldValue,
  defaultValue,
  handleDisplaySave,
  notClear,
  isMulti,
  menuPosition = 'absolute',
}) => {
  // const options = getOptions(data, field, { preferences, timeForm });
  const handleChange = (event) => {
    if (isMulti) {
      setFieldValue(event);
      return;
    }
    const value = event ? event.value : '';

    setFieldValue(name, value);
    if (save) {
      handleDisplaySave({ ...values, [name]: value });
    }
  };

  return (
    <StyledReactSelect
      isMulti={isMulti}
      transparent={transparent}
      planningForm={planningForm}
      id={id}
      name={name}
      value={value}
      timeForm={timeForm}
      isClearable={!notClear}
      options={options}
      styles={colourStyles}
      onBlur={setFieldTouched}
      defaultValue={defaultValue}
      placeholder={placeholder ? placeholder : ''}
      className={name ? `basic-single ${name}` : 'basic-single'}
      classNamePrefix="select"
      onChange={handleChange}
      menuShouldBlockScroll={false}
      closeMenuOnScroll={(e) =>
        e.target &&
        e.target.classList &&
        !e.target.classList.contains('select__menu-list') &&
        !e.target.classList.contains('select__value-container')
      }
      menuPortalTarget={document.body}
      menuPlacement="auto"
      menuPosition={menuPosition}
    />
  );
};

const StyledReactSelect = styled(ReactSelect)`
  div > div.select__value-container {
    padding: ${({ timeForm, planningForm }) =>
      !planningForm && timeForm ? '2px 0 !important' : 'inherit'};
    padding-left: ${({ timeForm, planningForm, transparent }) =>
      transparent
        ? '0'
        : !planningForm && timeForm
        ? '5px !important'
        : '13px'};
  }
  .select__single-value {
    text-transform: capitalize;
  }
`;

export default StyledSelect;
