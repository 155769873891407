import React, { useEffect, Fragment, useState } from 'react';
import { ReactSVG } from 'react-svg';
import Spinner from '../../components/Spinner';
import styled from 'styled-components';
import EditImg from '../../assets/svgs/edit.svg';
import DeleteImg from '../../assets/svgs/delete.svg';
import ArrowRight from '../../assets/svgs/arrow-right.svg';
import ArrowLeft from '../../assets/svgs/arrow-left.svg';
import JobItem from './JobItem';
import { formatDateWithoutYear } from '../../utils/formatDate';
import AddEquipmentPopup from '../../components/Popups/AddEquipmentPopup';

function join(t, a, s) {
  function format(m) {
    let f = new Intl.DateTimeFormat('en', m);
    return f.format(t);
  }
  return a.map(format).join(s);
}

let a = [{ year: 'numeric' }, { month: 'numeric' }, { day: 'numeric' }];
let b = [{ day: 'numeric' }, { month: 'long' }, { year: 'numeric' }];
let formated = (data, b) => join(new Date(data), b || a, b ? ' ' : '-');

const groupJobsByDate = (jobs) =>
  jobs.reduce((acc, job) => {
    const date = formated(job.startDate);
    if (!acc[date]) {
      acc[date] = {
        date: formated(job.startDate),
        jobs: [],
      };
    }
    acc[date].jobs.push(job);
    return acc;
  }, {});

function EquipmentProfile(props) {
  const {
    history,
    getEquipmentById,
    currentEquipment,
    deleteEquipment,
    updateEquipment,
    resetCurrentEquipment,
  } = props;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentYearJobs, setCurrentYearJobs] = useState([]);
  const [openEquipmentPopup, setOpenEquipmentPopup] = useState(false);

  const now = new Date();
  const nexDay = new Date(currentDate);

  useEffect(() => {
    setCurrentYearJobs([]);
    let id = window.location.href.split('/')[4];
    getEquipmentById(id);
    return () => resetCurrentEquipment();
  }, []);

  useEffect(() => {
    if (currentEquipment) {
      let jobs = currentEquipment.jobEquipment.filter((job) => {
        let jobYear = new Date(job.startDate).getFullYear();
        let currrentYear = currentDate.getFullYear();
        return jobYear === currrentYear;
      });
      if (jobs.length) {
        const gropped = groupJobsByDate(jobs);
        setCurrentYearJobs(Object.keys(gropped).map((item) => gropped[item]));
      }
    }
  }, [currentDate, currentEquipment]);

  const handleChangeYear = (plus) => {
    setCurrentYearJobs([]);
    const tempCurrentDate = new Date(currentDate);
    if (plus) {
      tempCurrentDate.setFullYear(tempCurrentDate.getFullYear() - 1);
      setCurrentDate(tempCurrentDate);
    } else {
      tempCurrentDate.setFullYear(tempCurrentDate.getFullYear() + 1);
      setCurrentDate(tempCurrentDate);
    }
  };

  const handleEditEquipment = (data) => {
    data.licensePlateExpiryDate === 'Invalid date'
      ? (data.licensePlateExpiryDate = '')
      : null;
    data.nextServiceDate === 'Invalid date'
      ? (data.nextServiceDate = '')
      : null;
    if (data.licensePlateExpiryDate) {
      let formated = new Date(data.licensePlateExpiryDate);
      data.licensePlateExpiryDate = formated.toISOString();
    }
    if (data.nextServiceDate) {
      let formated = new Date(data.nextServiceDate);
      data.nextServiceDate = formated.toISOString();
    }
    for (let key in data) {
      !data[key] ? (data[key] = null) : null;
    }
    updateEquipment(currentEquipment.id, data);
    setOpenEquipmentPopup(false);
  };

  return (
    <Wrapper>
      <div className="title-bar">
        <div className="location-address">
          <h2>
            {currentEquipment?.year} {currentEquipment?.make}{' '}
            {currentEquipment?.model}{' '}
            {currentEquipment && currentEquipment.status === 'in service' && (
              <Service>In service</Service>
            )}
          </h2>
          <span>
            {currentEquipment?.vinSerialNumber && (
              <>VIN: {currentEquipment.vinSerialNumber}</>
            )}
          </span>
        </div>
        <div className="options">
          <button
            id="edit-location"
            className="btn-icon"
            onClick={() => setOpenEquipmentPopup(true)}
          >
            <ReactSVG src={EditImg} />
          </button>
          <button
            id="delete-location"
            className="btn-icon delete"
            onClick={() => {
              deleteEquipment(currentEquipment.id);
              history.push('/equipment');
            }}
          >
            <ReactSVG src={DeleteImg} />
          </button>
        </div>
      </div>
      <div className="container">
        <div className="col-row">
          {false ? (
            <Spinner />
          ) : (
            <>
              <div className="serviceHistory">
                <div className="card flex-column">
                  <div className="card-title">Service History</div>
                  <div className="date-select">
                    <a href="#" onClick={() => handleChangeYear(true)}>
                      <ReactSVG src={ArrowLeft} />
                    </a>
                    <span>{currentDate.getFullYear()}</span>
                    <Block
                      opacity={nexDay > now}
                      href="#"
                      onClick={() => {
                        if (nexDay > now) return;
                        handleChangeYear(false);
                      }}
                    >
                      <ReactSVG src={ArrowRight} />
                    </Block>
                  </div>
                  {currentYearJobs
                    ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map(({ date, jobs }) => {
                      return (
                        <Fragment key={date}>
                          <div className="date">
                            {new Date().toISOString().slice(0, -14) ===
                            new Date(date).toISOString().slice(0, -14)
                              ? 'Today'
                              : formatDateWithoutYear(date)}
                            {', '}
                            {currentDate.getFullYear()}
                          </div>
                          <div className="card-list">
                            {jobs.map((job) => {
                              return (
                                <JobItem
                                  deleteJob={() => {}}
                                  key={job.id}
                                  job={job}
                                />
                              );
                            })}
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="details">
                <div className="card">
                  <div className="card-title">Details</div>
                  <div className="worker-info">
                    <p>
                      Equipment #:{' '}
                      <span>{currentEquipment?.equipmentNumber || ''}</span>
                    </p>
                    {currentEquipment?.equipmentType.name !== 'Machinery' ? (
                      <p>
                        Lic. Plate:{' '}
                        <span>{currentEquipment?.licensePlate || ''}</span>
                      </p>
                    ) : null}
                    {currentEquipment?.equipmentType.name === 'Trucks' ? (
                      <p>
                        Exp.:{' '}
                        <span>
                          {currentEquipment?.licensePlateExpiryDate
                            ? formated(
                                currentEquipment.licensePlateExpiryDate,
                                b,
                              )
                            : null}
                        </span>
                      </p>
                    ) : null}
                    <p>
                      Next Service:{' '}
                      <span>
                        {currentEquipment?.nextServiceDate
                          ? formated(currentEquipment.nextServiceDate, b)
                          : null}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {openEquipmentPopup && (
        <AddEquipmentPopup
          equipment={currentEquipment}
          handleSubmit={handleEditEquipment}
          onClose={() => {
            setOpenEquipmentPopup(false);
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h2 {
    font-size: 24px;
    margin: 0 0 5px;
    font-family: 'Roboto', sans-serif;
  }
  .avatar svg {
    position: relative;
    top: 0;
  }
  .button,
  .button:hover {
    color: #333 !important;
  }
  .options {
    display: flex;
  }
  .options button:not(:first-child) {
    margin-left: 10px;
  }
  .card {
    padding: 20px;
    margin-bottom: 30px;
  }
  .card-list .card {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .card .date-select {
    background: #f7f7f7;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--grey-text);
    font-weight: 600;
  }
  .card .date-select a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #ccc;
  }
  .card .date-select a:hover {
    border-color: #777;
  }
  .card .date-select a svg {
    position: relative;
    top: 0;
    left: 10px;
  }
  .card .date-select span {
    margin: 0 20px;
  }
  .card-list .card {
    align-items: flex-start;
  }
  .card .card {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #f1f1f1;
    display: flex;
  }
  .card .card > div {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    flex-direction: column;
  }
  .card .card > div:nth-child(2) {
    align-items: flex-end;
  }
  .card .tasks-completed {
    display: flex;
    align-items: center;
  }
  .card .tasks-completed > * {
    margin-right: 5px;
  }
  .card .tag {
    display: inline-block;
    padding: 2px 5px;
    background: var(--tag-color);
    border-radius: 3px;
    margin-right: 10px;
  }
  .card .tag2 {
    display: inline-block;
    padding: 2px 5px;
    background: var(--tag2-color);
    border-radius: 3px;
    margin-right: 10px;
  }
  .card .hours-worked {
    font-weight: 600;
    margin: 2px 0 5px;
  }
  .card .start-stop-time {
    color: var(--grey-text);
  }
  .date {
    font-size: 18px;
    color: var(--grey-text);
    margin: 30px 0 20px;
    font-weight: 600;
  }
  textarea {
    width: 100%;
    border: 0;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .image-row {
    display: flex;
  }
  .image-row .image {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid var(--border-color);
    width: calc(100% / 3 - 13.33333333px);
    height: 80px;
    max-width: 104px;
  }
  .image-row .image:not(:first-child) {
    margin-left: 20px;
  }
  .image-row .image:not(.placeholder) {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
  }
  .image-row .image:not(.placeholder) svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .worker-info span {
    font-weight: bold;
  }

  @media (min-width: 960px) {
    .col-row {
      display: flex;
      justify-content: space-between;
    }
    .col-row .serviceHistory {
      flex: 5;
    }
    .col-row .details {
      flex: 2;
      margin-left: 30px;
    }
    .main-col {
      width: calc(100% - 420px);
    }
    .sidebar {
      width: 390px;
    }
  }
  @media (min-width: 414px) {
    .image-row .image {
      height: 104px;
    }
  }
`;

const Block = styled.a`
  cursor: ${({ opacity }) => (opacity ? 'initial' : 'pointer')};
  opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  svg {
    opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  }
`;

const Service = styled.span`
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  background-color: #ffe5a4;
  padding: 5px 10px;
  font-weight: 400 !important;
  margin-left: 5px;
`;

export default EquipmentProfile;
