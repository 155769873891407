import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import UserCard from './UserCard';
import Spinner from '../../components/Spinner';

class DashboardPage extends React.Component {
  componentDidMount() {
    const { getUsers } = this.props;
    getUsers(true);
  }

  render() {
    const { isLoading, listUsers, getUsers } = this.props;

    return (
      <Wrapper className="container">
        <div className="tabs hidden-desktop">
          <div id="details-tab" className="tab active">
            Details
          </div>
          <div id="history-tab" className="tab">
            Work History
          </div>
        </div>
        <div className="col-row">
          <div className="main-col hidden-mobile">
            <div className="card flex-column">
              <div className="card-title"></div>
            </div>
          </div>
          <div className="sidebar">
            <div className="card">
              <div className="card-title">Notifications</div>
              {isLoading ? (
                <Spinner />
              ) : (
                listUsers.map((user) => (
                  <UserCard getUsers={getUsers} user={user} key={user.id} />
                ))
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .options {
    display: flex;
  }
  .options button:not(:first-child) {
    margin-left: 10px;
  }
  p.heading,
  p.heading span {
    max-width: calc(100% - 30px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    width: 100%;
    color: var(--grey-text);
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.heading span {
    max-width: 100%;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 3px;
    color: var(--black-text);
  }
  .worker-info p:last-child {
    margin-bottom: 0;
  }
  .card-list p.heading {
    margin: 0 0 10px 0;
  }
  .card {
    padding: 20px;
    margin-bottom: 30px;
  }

  @media (min-width: 960px) {
    .col-row {
      display: flex;
      justify-content: space-between;
    }
    .main-col {
      width: calc(100% - 420px);
    }
    .sidebar {
      width: 390px;
    }
  }
`;

export default withRouter(DashboardPage);
