import React from 'react';
import styled from 'styled-components';
import { getInitials } from '../../utils/initials';
import ImageImg from '../../assets/svgs/imageImg.svg';
import NotesImg from '../../assets/svgs/noteImg.svg';
import { formatTimeLowerCase } from '../../utils/formatDate';
import { msToHMin } from '../../utils/msToTime';
import SimpleTimer from './SimpleTimer';

export default function JobItem(props) {
  const { job } = props;
  let time;
  let diff;
  if (job.startDate && job.endDate) {
    time = new Date(job.endDate).getTime() - new Date(job.startDate).getTime();
  } else
    diff = (new Date().getTime() - new Date(job.startDate).getTime()) / 1000;

  return (
    <Wrappper>
      <div>
        <div className="worker">
          <div className="avatar" style={{ background: job.user.color }}>
            {getInitials({
              name: job.user.name + ' ' + job.user.lastName,
            })}
          </div>
          <span>{job.user.name + ' ' + job.user.lastName}</span>
        </div>
        <div className="tasks-completed">
          {job.taskEquipment.filter(
            (item) => item.JobsEquipmentTasks?.taskStatusId === 1,
          ).length ? (
            <span className={job.workersGroup?.name ? 'tag2' : 'tag'}>
              {
                job.taskEquipment.filter(
                  (item) => item.JobsEquipmentTasks.taskStatusId === 1,
                ).length
              }{' '}
              tasks
            </span>
          ) : null}
          {job.img.length > 0 ? <img src={ImageImg} /> : null}
          {job.comments && job.comments[0] && job.comments[0].text ? (
            <img src={NotesImg} />
          ) : null}
        </div>
      </div>
      <div>
        {time ? (
          <div>
            <div className="hours-worked">{msToHMin(time)}</div>
            <span className="start-stop-time">
              {formatTimeLowerCase(job.startDate)} -{' '}
              {!job.endDate ? 'in work' : formatTimeLowerCase(job.endDate)}
            </span>
          </div>
        ) : (
          <SimpleTimer startDate={diff} />
        )}
      </div>
    </Wrappper>
  );
}

const Wrappper = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 3px 10px;
  border: 1px solid rgb(241, 241, 241);
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 8px;
`;
