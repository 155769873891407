import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';
import TruckForm from './TruckForm';
import MachineryForm from './MachineryFrom';
import TrailerForm from './TrailerForm';

const AddEquipmentPopup = (props) => {
  const { onClose, type, equipment, handleSubmit } = props;
  const typeId = type || equipment?.equipmentType?.id;

  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <Wrapper id="add-location-modal" className="modal">
          <div className="modal-header">
            <div className="modal-title">
              {type ? 'Add ' : 'Edit '}
              {typeId === 2
                ? 'Truck'
                : typeId === 3
                ? 'Machinery'
                : typeId === 4
                ? 'Trailer'
                : ''}
            </div>
            <div className="modal-close" onClick={onClose}>
              <ReactSVG src={CloseSVG} />
            </div>
          </div>
          <div className="modal-body left-align">
            {typeId === 2 ? (
              <TruckForm equipment={equipment} handleSubmit={handleSubmit} />
            ) : typeId === 3 ? (
              <MachineryForm
                equipment={equipment}
                handleSubmit={handleSubmit}
              />
            ) : typeId === 4 ? (
              <TrailerForm equipment={equipment} handleSubmit={handleSubmit} />
            ) : null}
          </div>
          <div className="cta-bar">
            <button
              type="submit"
              className="btn"
              form={
                typeId === 2
                  ? 'truck-form'
                  : typeId === 3
                  ? 'machinery-form'
                  : typeId === 4
                  ? 'trailer-form'
                  : ''
              }
            >
              {type ? 'add' : 'save'}
            </button>
          </div>
        </Wrapper>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div``;

export default AddEquipmentPopup;
