import { connect } from 'react-redux';
import TasksPage from './TasksPage';
import {
  getLocationsTasks,
  addTaskCategory,
  getTaskPlace,
  deleteTaskPlace,
  updateTask,
  updateTaskPlace,
  deleteTask,
  addTask,
} from '../../store/locationsStore/actions';

const mapStateToProps = ({
  locationsStore: {
    listTasks,
    tasksCategories,
    isLoadingMain,
    isLoadingDeleted,
  },
}) => ({ listTasks, tasksCategories, isLoadingMain, isLoadingDeleted });

const mapDispatchToProps = {
  getLocationsTasks,
  addTaskCategory,
  getTaskPlace,
  deleteTaskPlace,
  updateTask,
  updateTaskPlace,
  deleteTask,
  addTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksPage);
