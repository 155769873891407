import { connect } from 'react-redux';
import CitiesPage from './CitiesPage';
import {
  getCities,
  addCity,
  deleteCity,
} from '../../store/citiesStore/actions';

const mapStateToProps = ({
  citiesStore: { listCities, isLoading, isLoadingCreation, creationError },
}) => ({
  listCities,
  isLoading,
  isLoadingCreation,
  creationError,
});

const mapDispatchToProps = {
  getCities,
  addCity,
  deleteCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(CitiesPage);
