import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function SimpleTimer({ startDate }) {
  const [timer, setTimer] = useState(Number(startDate.toFixed()));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer((diff) => diff + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return (
    <TimerWrap>
      <Time>{formatTime()}</Time>
    </TimerWrap>
  );
}

const TimerWrap = styled.div`
  display: flex;
  div {
    margin: 0 3px;
  }
`;

const Time = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #4979c1;
`;

export default SimpleTimer;
