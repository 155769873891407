import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';
import JobBlock from './JobBlock';
import ShiftBlock from './ShiftBlock';
import { formatDate } from '../../utils/formatDate';
import { ReactSVG } from 'react-svg';
import AddActivityPopup from '../../components/Popups/AddActivityPopup';
import NotesPopup from '../../components/Popups/NotesPopup';
import { msToTime } from '../../utils/msToTime';
import ClockInactive from '../../assets/svgs/clock-inactive.svg';
import ShiftsActive from '../../assets/svgs/shiftsActive.svg';
import ShiftsInactive from '../../assets/svgs/shiftsInactive.svg';
import TasksActive from '../../assets/svgs/tasksActiveIco.svg';
import TasksInactive from '../../assets/svgs/tasksInactiveIco.svg';
import ArrowRight from '../../assets/svgs/arrow-right.svg';
import ArrowLeft from '../../assets/svgs/arrow-left.svg';
import {
  getFirstDayOnMonth,
  getLastDayOnMonth,
} from '../../utils/getFirstLastDay';
import moment from 'moment';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function JobsListAdminPage(props) {
  const {
    getJobsReport,
    getShiftsReport,
    getAllLocations,
    getUsers,
    isJobChanged,
    isJobCreated,
    jobByDate,
    reportShift,
    deleteJob,
    updateJob,
    createJob,
    isLoading,
    listAllLocations,
    listUsers,
    joinJobImg,
    updateNoteOnJob,
    getLocationById,
    location,
  } = props;

  const [currentDate, setCurrentDate] = useState(new Date());
  const [openPopup, setOpenPopup] = useState(false);
  const [openNotesPopup, setOpenNotesPopup] = useState(false);
  const [firstScreen, setFirstScreen] = useState(null);
  const [tab, setTab] = useState(1);

  const now = new Date();
  const nexDay = new Date(currentDate);

  useEffect(() => {
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    getJobsReport(
      getFirstDayOnMonth(month, year),
      getLastDayOnMonth(month, year),
      'all',
      'all',
      'all',
    );
    getShiftsReport(
      getFirstDayOnMonth(month, year),
      getLastDayOnMonth(month, year),
    );
    getAllLocations();
    getUsers();
  }, []);

  const handleChangeDay = (plus) => {
    const tempCurrentDate = new Date(currentDate);
    if (plus) {
      tempCurrentDate.setDate(tempCurrentDate.getDate() - 1);
      setCurrentDate(tempCurrentDate);
    } else {
      tempCurrentDate.setDate(tempCurrentDate.getDate() + 1);
      setCurrentDate(tempCurrentDate);
    }
  };
  useEffect(() => {
    if (currentDate) {
      let start = new Date(
        new Date(currentDate.setHours(0)).setMinutes(0),
      ).toISOString();
      let end = new Date(
        new Date(currentDate.setHours(23)).setMinutes(59),
      ).toISOString();
      getJobsReport(start, end, 'all', 'all', 'all');
      getShiftsReport(start, end);
    }
  }, [currentDate, isJobChanged, isJobCreated, getJobsReport, getShiftsReport]);

  return (
    <>
      <Wrapper>
        <div className="title-bar">
          <h1>Timelogs</h1>
          <TabsWrap>
            <Tab
              theme={{ color: tab === 1 ? '#4979C1' : '#828282' }}
              onClick={() => setTab(1)}
            >
              <img
                src={tab === 1 ? TasksActive : TasksInactive}
                alt="personIcon"
              />
              Tasks
            </Tab>
            <Tab
              theme={{ color: tab === 2 ? '#4979C1' : '#828282' }}
              onClick={() => setTab(2)}
            >
              <img
                src={tab === 2 ? ShiftsActive : ShiftsInactive}
                alt="groupIcon"
              />
              Work shifts
            </Tab>
          </TabsWrap>
          {tab === 1 ? (
            <button
              id="add-location"
              className="btn"
              onClick={() => setOpenPopup(!openPopup)}
            >
              new entry
            </button>
          ) : null}
        </div>
        <div className="container">
          <div className="date-select">
            <a href="#" onClick={() => handleChangeDay(true)}>
              <ReactSVG src={ArrowLeft} />
            </a>
            <span>
              {monthNames[currentDate.getMonth()]} {currentDate.getDate()}{' '}
              {currentDate.getFullYear()}
            </span>
            <Block
              opacity={nexDay > now}
              href="#"
              onClick={() => {
                if (nexDay > now) return;
                handleChangeDay(false);
              }}
            >
              <ReactSVG src={ArrowRight} />
            </Block>
            <TimeTotal>
              {(() => {
                let time = 0;
                if (tab === 1) {
                  time = jobByDate?.jobsReportStat
                    ? jobByDate.jobsReportStat[0]
                      ? jobByDate.jobsReportStat[0].time
                      : null
                    : null;
                } else {
                  reportShift?.forEach((item) => {
                    item.workingDays?.forEach((shift) => {
                      if (shift.startDate && shift.endDate) {
                        time +=
                          new Date(shift.endDate).getTime() -
                          new Date(shift.startDate).getTime();
                      }
                    });
                  });
                }
                return time ? (
                  <>
                    <ReactSVG
                      className="clock"
                      src={ClockInactive}
                      wrapper="span"
                    />
                    {msToTime(time)}
                  </>
                ) : null;
              })()}
            </TimeTotal>
          </div>
          {tab === 1 ? (
            <>
              {isLoading && !jobByDate?.jobsReportStat?.length && <Spinner />}
              {jobByDate?.jobsReportStat
                ?.filter((item) => {
                  return item.date === moment(currentDate).format('yyyy-MM-DD');
                })
                .map(({ date, jobs }) => (
                  <Fragment key={date}>
                    {jobs.map((job) => {
                      let rodTasks = [];
                      let sidewalkTasks = [];
                      let otherTasks = [];
                      if (job.job.tasks.length) {
                        rodTasks = job.job.tasks.filter(
                          (task) => task?.place?.id === 1,
                        );
                        sidewalkTasks = job.job.tasks.filter(
                          (task) => task?.place?.id === 2,
                        );
                        otherTasks = job.job.tasks
                          .filter((task) => task?.place?.id === 3)
                          .sort((taskOne, taskTwo) => taskOne.id - taskTwo.id);
                      }
                      return (
                        <JobBlock
                          key={job.job.id}
                          job={job.job}
                          locationMain={location}
                          getLocationById={getLocationById}
                          joinJobImg={joinJobImg}
                          rodTasks={rodTasks}
                          sidewalkTasks={sidewalkTasks}
                          otherTasks={otherTasks}
                          updateJob={updateJob}
                          deleteJob={deleteJob}
                          updateNoteOnJob={updateNoteOnJob}
                        />
                      );
                    })}
                  </Fragment>
                ))}
            </>
          ) : (
            reportShift?.map(({ date, workingDays }) => (
              <Fragment key={date}>
                {/* <Wrap>
                  <h2>{date === 'In work' ? date : formatDate(date)}</h2>
                </Wrap> */}
                {workingDays.map((day) => {
                  return <ShiftBlock key={day.id} day={day} />;
                })}
              </Fragment>
            ))
          )}
        </div>
        <AddActivityPopup
          open={openPopup}
          onClose={() => setOpenPopup(!openPopup)}
          listAllLocations={listAllLocations}
          listUsers={listUsers}
          handleOpenNotesPopup={() => {
            setOpenNotesPopup(!openNotesPopup);
            setOpenPopup(!openPopup);
          }}
          handleFirstScreen={(obj) => setFirstScreen(obj)}
        />
        <NotesPopup
          joinJobImg={joinJobImg}
          firstScreen={firstScreen}
          open={openNotesPopup}
          onClose={() => setOpenNotesPopup(!openNotesPopup)}
          createJob={createJob}
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  margin-bottom: 85px;
  background-color: '#E5E5E5';
  .date-select {
    background: #efefef;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--grey-text);
    font-weight: 600;
    margin-bottom: 30px;
    position: relative;
  }
  .date-select a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #ccc;
    margin: 0 15px;
  }
  .date-select a:hover {
    border-color: #777;
  }
  .date-select a svg {
    position: relative;
    top: 0;
    left: 10px;
  }
  .date-select span {
    margin: 0 3px;
  }

  .logo {
    margin-bottom: 20px;
  }
  .card {
    padding: 10px 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .grey {
    color: #777;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p.heading .main {
    font-size: 18px;
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
  }
  p.heading .full-address {
    color: #777;
  }
  .clock {
    position: relative;
    top: -2px;
  }
  .services {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 3px;
  }
  .services > div {
    display: flex;
    margin-left: 15px;
  }
  .services > div > div {
    margin-top: 6px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    height: 16px;
    border-radius: 2px;
    background: #ccc;
    margin-left: 5px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
  .services .green.active {
    background: #63933a;
  }
  .services .blue.active {
    background: #3c589f;
  }
  .services .brown.active {
    background: #795548;
  }
  .flex-row {
    display: flex;
    flex-direction: column;
  }
  .add-button {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    p.heading {
      margin-bottom: 0;
    }
  }
`;

const Time = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Block = styled.a`
  cursor: ${({ opacity }) => (opacity ? 'initial' : 'pointer')};
  opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  svg {
    opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  }
`;

const TabsWrap = styled.div`
  display: flex;
  margin-left: auto;
  padding-right: 20px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color};
  img {
    width: 18px;
  }
  margin: 0 20px;
  &:hover {
    cursor: pointer;
  }
`;

const TimeTotal = styled.div`
  position: absolute;
  right: 40px;
  top: 16px;
`;

export default JobsListAdminPage;
