export const getFirstDayOnMonth = (month, year) => {
  let firstDayOfCurrentMonth = new Date(year, month, 1);

  return firstDayOfCurrentMonth.toISOString();
};
export const getLastDayOnMonth = (month, year) => {
  let lastDayOfCurrentMonth = new Date(year, month + 1, 0);

  return lastDayOfCurrentMonth.toISOString();
};
