import React from 'react';
import styled from 'styled-components';
import { getInitials } from '../../utils/initials';
import MenuDots from '../../assets/svgs/menu-image.svg';
import DropDownUsers from './DropDownUsers';
import { ReactSVG } from 'react-svg';
import { ROLE_SUPERVISOR } from '../../utils/users';

export default class UserBlock extends React.Component {
  state = {
    openMenu: false,
  };
  handleOpenMenu = (e) => {
    e?.stopPropagation();

    setTimeout(() => {
      this.setState({ openMenu: !this.state.openMenu });
    }, 10);
  };

  render() {
    const {
      user,
      deleteUsers,
      updateUser,
      history,
      handlePopupDelete,
    } = this.props;
    const { openMenu } = this.state;
    return (
      <ItemUser
        className="card"
        active={Boolean(user.active === 'active')}
        onClick={() => history.push(`/edit-user/${user.id}`)}
      >
        <div className="worker">
          <div
            className="avatar"
            style={{
              background: Boolean(user.active === 'active')
                ? user.color
                : '#ccc',
            }}
          >
            {getInitials({
              name: user.name + ' ' + user.lastName,
            })}
          </div>
          <Name active={Boolean(user.active === 'active')}>
            {' '}
            {user.name + ' ' + user.lastName}
          </Name>
        </div>
        {user.role === ROLE_SUPERVISOR && (
          <span className="grey">Supervisor</span>
        )}
        {user.role === 'mechanic' && <span className="grey">Mechanic</span>}
        <a className="card-menu" onClick={this.handleOpenMenu}>
          <ReactSVG className="dropdown" src={MenuDots} />
          {openMenu && (
            <DropDownUsers
              handlePopupDelete={handlePopupDelete}
              updateUser={updateUser}
              deleteUsers={deleteUsers}
              user={user}
              onClose={this.handleOpenMenu}
            />
          )}
        </a>
      </ItemUser>
    );
  }
}

const ItemUser = styled.div`
  background: ${({ active }) => (active ? '#ffffff' : 'transparent')};
  border: ${({ active }) => (active ? 'none' : '1px solid #C4C4C4')};
`;

const Name = styled.span`
  color: ${({ active }) => (active ? '#333333' : '#BBBBBB')};
`;
