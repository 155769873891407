import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  workersGroups: [],
  isLoadingDeleted: false,
  workerGroup: null,
  assignedTasks: [],
  assignedLocations: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_WORKERS_GROUP_REQUEST:
      return { ...state, workersGroups: [], isLoading: true };
    case types.GET_WORKERS_GROUP_SUCCESS:
      return {
        ...state,
        // pageSize: Math.ceil(payload.total / payload.limit),
        workersGroups: payload.data, //.data
        // currentPage: payload.page,
        isLoading: false,
      };
    case types.GET_WORKERS_GROUP_FAIL:
      return { ...state, workersGroups: false, error: payload };

    case types.GET_WORKERS_GROUP_BY_ID_REQUEST:
      return { ...state, workerGroup: null, isLoading: true };
    case types.GET_WORKERS_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        workerGroup: payload.data, //.data
        isLoading: false,
      };
    case types.GET_WORKERS_GROUP_BY_ID_FAIL:
      return { ...state, workerGroup: false, error: payload };

    case types.ADD_WORKERS_GROUP_REQUEST:
      return { ...state, isLoadingDeleted: true, isLoading: true };
    case types.ADD_WORKERS_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingDeleted: false, //.data
        isLoading: false,
      };
    case types.ADD_WORKERS_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
        error: payload,
      };

    case types.UPDATE_WORKERS_GROUP_REQUEST:
      return { ...state, isLoadingDeleted: true, isLoading: true };
    case types.UPDATE_WORKERS_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingDeleted: false,
        isLoading: false,
      };
    case types.UPDATE_WORKERS_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
        error: payload,
      };

    case types.DELETE_WORKERS_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_WORKERS_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        workersGroups: payload.data,
      };
    case types.DELETE_WORKERS_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.ASSIGN_WORKERS_TO_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingDeleted: true,
      };
    case types.ASSIGN_WORKERS_TO_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
      };
    case types.ASSIGN_WORKERS_TO_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,

        error: payload,
      };

    case types.REMOVE_WORKERS_FROM_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingDeleted: true,
      };
    case types.REMOVE_WORKERS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
        // workersGroups: payload.data,
      };
    case types.REMOVE_WORKERS_FROM_GROUP_FAIL:
      return {
        ...state,
        isLoadingDeleted: false,
        isLoading: false,
        error: payload,
      };

    case types.UPDATE_WORKERS_ON_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingDeleted: true,
      };
    case types.UPDATE_WORKERS_ON_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
        // workersGroups: payload.data,
      };
    case types.UPDATE_WORKERS_ON_GROUP_FAIL:
      return {
        ...state,
        isLoadingDeleted: false,
        isLoading: false,
        error: payload,
      };

    case types.ASSIGN_TASK_TO_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingTasks: true,
      };
    case types.ASSIGN_TASK_TO_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingTasks: false,
      };
    case types.ASSIGN_TASK_TO_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingTasks: false,

        error: payload,
      };

    case types.REMOVE_TASK_FROM_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.REMOVE_TASK_FROM_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        workersGroups: payload.data,
      };
    case types.REMOVE_TASK_FROM_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.GET_TASK_FROM_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_TASK_FROM_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignedTasks: payload.data,
      };
    case types.GET_TASK_FROM_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.ASSIGN_LOCATION_TO_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingDeleted: true,
      };
    case types.ASSIGN_LOCATION_TO_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
      };
    case types.ASSIGN_LOCATION_TO_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,

        error: payload,
      };

    case types.REMOVE_LOCATION_FROM_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.REMOVE_LOCATION_FROM_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignedLocations: payload.data,
      };
    case types.REMOVE_LOCATION_FROM_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};
