import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  listLocations: [],
  listAllLocations: [],
  listTasks: [],
  tasksCategories: [],
  location: {},
  logout: false,
  isLocationDeleted: false,
  isLocationAdded: false,
  isLoadingLocation: false,
  isUpdatedLocation: false,
  isAssignedLocation: false,
  isCommentAdded: false,
  isImageAdded: false,
  pageSize: 12,
  currentPage: 1,
  totalElements: 0,
  isLoadingMain: false,
  isLoadingDeleted: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_LOCATIONS_REQUEST:
      return { ...state, listLocations: [], isLoading: true };
    case types.GET_LIST_LOCATIONS_SUCCESS:
      return {
        ...state,
        // pageSize: Math.ceil(payload.total / payload.limit),
        listLocations: payload.locations, //.data
        // currentPage: payload.page,
        totalElements: payload.total,
        isLoading: false,
      };
    case types.GET_LIST_LOCATIONS_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_LIST_TASKS_ON_LOCATIONS_REQUEST:
      return { ...state, listTasks: [], isLoading: true, isLoadingMain: true };
    case types.GET_LIST_TASKS_ON_LOCATIONS_SUCCESS:
      return {
        ...state,
        listTasks: payload, //.data
        isLoading: false,
        isLoadingMain: false,
      };
    case types.GET_LIST_TASKS_ON_LOCATIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingMain: false,
        error: payload,
      };

    case types.ADD_TASK_CATEGORY_REQUEST:
      return { ...state, tasksCategories: [], isLoading: true };
    case types.ADD_TASK_CATEGORY_SUCCESS:
      return {
        ...state,
        tasksCategories: payload.data,
        isLoading: false,
      };
    case types.ADD_TASK_CATEGORY_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_TASK_PLACE_REQUEST:
      return {
        ...state,
        tasksCategories: [],
        isLoading: true,
        isLoadingMain: true,
      };
    case types.GET_TASK_PLACE_SUCCESS:
      return {
        ...state,
        tasksCategories: payload.data,
        isLoading: false,
        isLoadingMain: false,
      };
    case types.GET_TASK_PLACE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoadingMain: false,
      };

    case types.DELETE_TASK_PLACE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_TASK_PLACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasksCategories: payload.data,
      };
    case types.DELETE_TASK_PLACE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.DELETE_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listTasks: payload.data.currentTasks,
      };
    case types.DELETE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case types.ADD_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_TASK_SUCCESS:
      return {
        ...state,
        listTasks: payload.data.currentTasks,

        isLoading: false,
        // isLoadingDeleted: false,
      };
    case types.ADD_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        // isLoadingDeleted: false,
      };

    case types.UPDATE_TASK_PLACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        // isLoadingDeleted: true,
      };
    case types.UPDATE_TASK_PLACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasksCategories: payload.data,
        // isLoadingDeleted: false,
      };
    case types.UPDATE_TASK_PLACE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        // isLoadingDeleted: false,
      };

    case types.UPDATE_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingDeleted: true,
      };
    case types.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingDeleted: false,
      };
    case types.UPDATE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoadingDeleted: false,
      };

    case types.GET_ALL_LOCATIONS_REQUEST:
      return { ...state, listAllLocations: [], isLoading: true };
    case types.GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        // pageSize: Math.ceil(payload.total / payload.limit),
        listAllLocations: payload.locations, //.data
        // currentPage: payload.page,
        totalElements: payload.total,
        isLoading: false,
      };
    case types.GET_ALL_LOCATIONS_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_LOCATION_REQUEST:
      return { ...state, isLoading: true, isLoadingLocation: true };
    case types.GET_LOCATION_SUCCESS:
      return {
        ...state,
        location: payload,
        isLoading: false,
        isLoadingLocation: false,
      };
    case types.GET_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingLocation: false,
        error: payload,
      };

    case types.DELETE_LOCATION_REQUEST:
      return { ...state, isLocationDeleted: false, isLoading: true };
    case types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        isLocationDeleted: true,
        isLoading: false,
      };
    case types.DELETE_LOCATION_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.ADD_LOCATION_REQUEST:
      return { ...state, isLocationAdded: false, isLoading: true };
    case types.ADD_LOCATION_SUCCESS:
      return {
        ...state,
        location: payload,
        isLocationAdded: true,
        isLoading: false,
      };
    case types.ADD_LOCATION_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.UPDATE_LOCATION_REQUEST:
      return { ...state, isLoading: true, isUpdatedLocation: false };
    case types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        location: payload,
        isLoading: false,
        isUpdatedLocation: true,
      };
    case types.UPDATE_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false,
        isUpdatedLocation: false,
        error: payload,
      };

    case types.ASSIGN_LOCATION_REQUEST:
      return { ...state, isLoading: true, isAssignedLocation: false };
    case types.ASSIGN_LOCATION_SUCCESS:
      return {
        ...state,
        location: payload,
        isLoading: false,
        isAssignedLocation: true,
      };
    case types.ASSIGN_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false,
        isAssignedLocation: false,
        error: payload,
      };

    case types.ADD_COMMENT_TO_LOCATION_REQUEST:
      return { ...state, isCommentAdded: false, isLoading: true };
    case types.ADD_COMMENT_TO_LOCATION_SUCCESS:
      return {
        ...state,
        isCommentAdded: true,
        isLoading: false,
      };
    case types.ADD_COMMENT_TO_LOCATION_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.JOIN_LOCATION_TO_IMG_REQUEST:
      return { ...state, isImageAdded: false, isLoading: true };
    case types.JOIN_LOCATION_TO_IMG_SUCCESS:
      return {
        ...state,
        isImageAdded: true,
        isLoading: false,
      };
    case types.JOIN_LOCATION_TO_IMG_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.CLEAN_LOCATION:
      return initialState;

    default:
      return state;
  }
};
