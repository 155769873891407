import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import AssignSection from './AssignSection';
import NotesBlock from './NotesBlock';
import EditImg from '../../assets/svgs/edit.svg';
import DeleteImg from '../../assets/svgs/delete.svg';
import ArrowRight from '../../assets/svgs/arrow-right.svg';
import ArrowLeft from '../../assets/svgs/arrow-left.svg';
import { isEqual } from 'lodash';
import {
  getFirstDayOnMonth,
  getLastDayOnMonth,
} from '../../utils/getFirstLastDay';
import { formatDateWithoutYear } from '../../utils/formatDate';
import AddLocationPopup from '../../components/Popups/AddLocationPopup';
import DeleteLocationPopup from '../../components/Popups/DeleteLocationPopup';
import deleteEmptyProperties from '../../utils/deleteEmptyProperties';
import JobBlock from './JobBlock';
import clsx from 'clsx';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class LocationPage extends React.Component {
  state = {
    users: [],
    tab: 1,
    note: '',
    logos: [],
    images: [],
    afterDel: null,
    formValues: {},
    save: false,
    errorsForm: {},
    currentDate: new Date(),
    openPopupEdit: false,
    openPopupDelete: false,
    hidden: 1,
  };

  componentDidMount() {
    const {
      getUsers,
      getLocationById,
      getJobsReport,
      match: { params },
    } = this.props;
    document.body.classList.remove('with-nav');

    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    getJobsReport(
      getFirstDayOnMonth(month, year),
      getLastDayOnMonth(month, year),
      params.id,
      'all',
      'all',
    );

    getUsers();
    if (params.id) {
      getLocationById(params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isLocationDeleted,
      isUpdatedLocation,
      locationItem,
      isJobChanged,
      joinLocationImg,
      history,
      updateLocation,
      getJobsReport,
      match: { params },
    } = this.props;
    const { users, images, logos, afterDel, currentDate } = this.state;
    if (!isEqual(prevProps.locationItem, locationItem) && locationItem) {
      if (locationItem.users) {
        this.setState({ users: locationItem.users.map((user) => user.id) });
      }
      if (locationItem.comment) {
        this.handleNote(locationItem.comment, true);
      }
      if (locationItem.img) {
        this.handleLogos(locationItem.img.map((img) => img.imgUrl));
        this.handleImages(locationItem.img);
      }
    }

    if (prevProps.isJobChanged !== isJobChanged) {
      getJobsReport(
        getFirstDayOnMonth(currentDate.getMonth(), currentDate.getFullYear()),
        getLastDayOnMonth(currentDate.getMonth(), currentDate.getFullYear()),
        params.id,
        'all',
        'all',
      );
    }

    if (!prevProps.isUpdatedLocation && isUpdatedLocation) {
      this.setState({ openPopupEdit: false });
    }

    const arrUsersIds = locationItem.users?.map((user) => user.id);

    if (!isEqual(prevState.users, users) && !isEqual(arrUsersIds, users)) {
      updateLocation({
        id: locationItem.id,
        users: users,
      });
    }

    if (!prevProps.isLocationDeleted && isLocationDeleted) {
      history.goBack();
    }

    if (logos.length && prevState.logos.length !== logos.length) {
      const imagesFiltered = images.filter((x) =>
        logos.some((y) => x.imgUrl === y),
      );

      const imagesIds = imagesFiltered.map((image) => image.imgId || image.id);

      const imagesIdsOnTask =
        afterDel !== null
          ? afterDel
          : locationItem.img
          ? locationItem.img.map((img) => img.imgId || img.id)
          : [];

      const uniqImgIds = [...new Set([...imagesIdsOnTask, ...imagesIds])];

      joinLocationImg(locationItem.id, {
        imgIds: uniqImgIds,
      });
    }
  }

  handleAfterDel = (afterDel) => {
    this.setState({ afterDel: afterDel });
  };

  handleNote = (note, notRequest) => {
    const { addCommentToLocation, locationItem } = this.props;
    this.setState({ note: note });
    if (notRequest) return;
    setTimeout(() => {
      addCommentToLocation(locationItem.id, { comment: note });
    }, 1000);
  };

  handleLogos = (logos) => {
    this.setState({ logos: logos });
  };

  handleImages = (images) => {
    this.setState({ images: images });
  };

  handleChangeTab = (number) => {
    this.setState({
      tab: number,
    });
  };

  handleSetUsers = (users) => {
    this.setState({
      users: users,
    });

    // updateLocation({
    //   id: locationItem.id,
    //   users: users,
    // });
  };

  componentWillUnmount() {
    this.props.cleanLocation();
  }

  handleRemoveError = (name) => {
    this.setState({
      errorsForm: {
        ...this.state.errorsForm,
        [name]: null,
      },
    });
  };

  handleChangeMonth = (plus) => {
    const {
      getJobsReport,
      match: { params },
    } = this.props;
    const { currentDate } = this.state;
    const tempCurrentDate = currentDate;
    if (plus) {
      tempCurrentDate.setMonth(tempCurrentDate.getMonth() - 1);

      this.setState({
        currentDate: tempCurrentDate,
      });
    } else {
      tempCurrentDate.setMonth(tempCurrentDate.getMonth() + 1);

      this.setState({
        currentDate: tempCurrentDate,
      });
    }

    getJobsReport(
      getFirstDayOnMonth(
        tempCurrentDate.getMonth(),
        tempCurrentDate.getFullYear(),
      ),
      getLastDayOnMonth(
        tempCurrentDate.getMonth(),
        tempCurrentDate.getFullYear(),
      ),
      params.id,
      'all',
      'all',
    );
  };

  handleOpenPopupEdit = () => {
    this.setState(({ openPopupEdit }) => ({ openPopupEdit: !openPopupEdit }));
  };

  handleOpenDeletePopup = () => {
    this.setState(({ openPopupDelete }) => ({
      openPopupDelete: !openPopupDelete,
    }));
  };

  handleSubmit = (values) => {
    const { updateLocation, locationItem } = this.props;

    updateLocation({ id: locationItem.id, ...deleteEmptyProperties(values) });
  };

  handleChangeHidden = (hidden) => {
    this.setState({ hidden: hidden });
  };

  render() {
    const {
      listUsers,
      isLoading,
      locationItem,
      isLoadingLocation,
      reportJob,
      deleteLocation,
      isLoadingReport,
      deleteJob,
    } = this.props;
    const {
      users,
      afterDel,
      note,
      logos,
      images,
      openPopupEdit,
      currentDate,
      openPopupDelete,
      hidden,
    } = this.state;

    const month = monthNames[currentDate.getMonth()];

    const nexDay = new Date(currentDate);
    nexDay.setDate(nexDay.getDate() + 1);
    const now = new Date();

    return (
      <Wrapper>
        <div className="title-bar">
          <div className="location-address">
            <h2>{locationItem.name}</h2>
            <span>
              {locationItem.address}
              {locationItem.city ? ',' : ''} {locationItem.city}
              {locationItem.province ? ',' : ''} {locationItem.province}
              {locationItem.postalCode}
            </span>
          </div>
          <div className="options">
            <button
              id="edit-location"
              className="btn-icon"
              onClick={this.handleOpenPopupEdit}
            >
              <ReactSVG src={EditImg} />
            </button>
            <button
              id="delete-location"
              className="btn-icon delete"
              onClick={this.handleOpenDeletePopup}
            >
              <ReactSVG src={DeleteImg} />
            </button>
          </div>
        </div>
        <div className="container">
          <div className="tabs hidden-desktop">
            <div
              id="details-tab"
              className={clsx('tab', {
                active: hidden === 1,
              })}
              onClick={() => this.handleChangeHidden(1)}
            >
              Details
            </div>
            <div
              id="history-tab"
              className={clsx('tab', {
                active: hidden === 2,
              })}
              onClick={() => this.handleChangeHidden(2)}
            >
              Work History
            </div>
          </div>

          <div className="col-row">
            {isLoadingLocation ? (
              <Spinner />
            ) : (
              <>
                <div
                  className={clsx('main-col', {
                    ['hidden-mobile']: hidden === 1,
                  })}
                >
                  <div className="card flex-column">
                    <div className="card-title">Work History</div>
                    <div className="date-select">
                      <a
                        href="#"
                        onClick={() => {
                          this.handleChangeMonth(true);
                        }}
                      >
                        <ReactSVG src={ArrowLeft} />
                      </a>
                      <span>
                        {month} {currentDate.getFullYear()}
                      </span>
                      <Block
                        opacity={nexDay > now}
                        href="#"
                        onClick={() => {
                          if (nexDay > now) return;
                          this.handleChangeMonth(false);
                        }}
                      >
                        <ReactSVG src={ArrowRight} />
                      </Block>
                    </div>
                    {isLoadingReport && <Spinner />}
                    {!isLoadingReport &&
                      reportJob?.jobsReportStat?.map(({ date, jobs }) => {
                        return (
                          <Fragment key={date}>
                            <div className="date">
                              {new Date().toISOString().slice(0, -14) ===
                              new Date(date).toISOString().slice(0, -14)
                                ? 'Today'
                                : formatDateWithoutYear(date)}
                            </div>
                            <div className="card-list">
                              {jobs.map(({ job, time }) => {
                                return (
                                  <JobBlock
                                    deleteJob={deleteJob}
                                    key={job.id}
                                    time={time}
                                    job={job}
                                  />
                                );
                              })}
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
                <div
                  className={clsx('sidebar', {
                    ['hidden-mobile']: hidden === 2,
                  })}
                >
                  <div className="card">
                    <div className="card-title">Assigned Workers</div>
                    <AssignSection
                      handleSetUsers={this.handleSetUsers}
                      listUsers={listUsers}
                      isLoading={isLoading}
                      users={
                        locationItem?.users?.length
                          ? locationItem.users
                          : listUsers.filter(({ id }) => users.includes(id))
                      }
                    />
                  </div>
                  <div className="card">
                    <div className="card-title">Public Notes</div>

                    <NotesBlock
                      location={locationItem}
                      note={note}
                      logos={logos}
                      images={images}
                      afterDel={afterDel}
                      handleAfterDel={this.handleAfterDel}
                      handleNote={this.handleNote}
                      handleLogos={this.handleLogos}
                      handleImages={this.handleImages}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {openPopupEdit && (
          <AddLocationPopup
            onClose={this.handleOpenPopupEdit}
            locationItem={locationItem}
            handleSubmit={this.handleSubmit}
          />
        )}
        <DeleteLocationPopup
          open={openPopupDelete}
          onDelete={deleteLocation}
          onClose={this.handleOpenDeletePopup}
          id={locationItem.id}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  h2 {
    font-size: 24px;
    margin: 0 0 5px;
    font-family: 'Roboto', sans-serif;
  }
  .avatar svg {
    position: relative;
    top: 0;
  }
  .button,
  .button:hover {
    color: #333 !important;
  }
  .options {
    display: flex;
  }
  .options button:not(:first-child) {
    margin-left: 10px;
  }
  .card {
    padding: 20px;
    margin-bottom: 30px;
  }
  .card-list .card {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .card .date-select {
    background: #f7f7f7;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--grey-text);
    font-weight: 600;
  }
  .card .date-select a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #ccc;
  }
  .card .date-select a:hover {
    border-color: #777;
  }
  .card .date-select a svg {
    position: relative;
    top: 0;
    left: 10px;
  }
  .card .date-select span {
    margin: 0 20px;
  }
  .card-list .card {
    align-items: flex-start;
  }
  .card .card {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #f1f1f1;
    display: flex;
  }
  .card .card > div {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    flex-direction: column;
  }
  .card .card > div:nth-child(2) {
    align-items: flex-end;
  }
  .card .tasks-completed {
    display: flex;
    align-items: center;
  }
  .card .tasks-completed > * {
    margin-right: 5px;
  }
  .card .tag {
    display: inline-block;
    padding: 2px 5px;
    background: var(--tag-color);
    border-radius: 3px;
    margin-right: 10px;
  }
  .card .tag2 {
    display: inline-block;
    padding: 2px 5px;
    background: var(--tag2-color);
    border-radius: 3px;
    margin-right: 10px;
  }
  .card .hours-worked {
    font-weight: 600;
    margin: 2px 0 5px;
  }
  .card .start-stop-time {
    color: var(--grey-text);
  }
  .date {
    font-size: 18px;
    color: var(--grey-text);
    margin: 30px 0 20px;
    font-weight: 600;
  }
  textarea {
    width: 100%;
    border: 0;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .image-row {
    display: flex;
  }
  .image-row .image {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid var(--border-color);
    width: calc(100% / 3 - 13.33333333px);
    height: 80px;
    max-width: 104px;
  }
  .image-row .image:not(:first-child) {
    margin-left: 20px;
  }
  .image-row .image:not(.placeholder) {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
  }
  .image-row .image:not(.placeholder) svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 960px) {
    .col-row {
      display: flex;
      justify-content: space-between;
    }
    .main-col {
      width: calc(100% - 420px);
    }
    .sidebar {
      width: 390px;
    }
  }
  @media (min-width: 414px) {
    .image-row .image {
      height: 104px;
    }
  }
`;

const Block = styled.a`
  cursor: ${({ opacity }) => (opacity ? 'initial' : 'pointer')};
  opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  svg {
    opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
  }
`;

export default withRouter(LocationPage);
