import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.GET_USER_REQUEST, handleRequest),
  takeEvery(types.GET_ALL_USERS_REQUEST, handleRequest),
  takeEvery(types.DELETE_USER_REQUEST, handleRequest),
  takeEvery(types.ADD_USER_REQUEST, handleRequest),
  takeEvery(types.UPDATE_USER_REQUEST, handleRequest),
  takeEvery(types.GET_USER_BY_ID_REQUEST, handleRequest),
  takeEvery(types.RESTORE_USER_REQUEST, handleRequest),
]);
