import * as types from './constants';

export const getUser = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/user' },
      actions: [
        types.GET_USER_REQUEST,
        types.GET_USER_SUCCESS,
        types.GET_USER_FAIL,
      ],
    },
  };
};

export const getUserById = (id) => {
  return {
    type: 'API',
    payload: {
      request: { url: `/v1/user/${id}` },
      actions: [
        types.GET_USER_BY_ID_REQUEST,
        types.GET_USER_BY_ID_SUCCESS,
        types.GET_USER_BY_ID_FAIL,
      ],
    },
  };
};

export const getUsers = (filterCovid) => {
  return {
    type: 'API',
    payload: {
      request: { url: `/v1/user/listing?filterCovid=${Boolean(filterCovid)}` },
      actions: [
        types.GET_ALL_USERS_REQUEST,
        types.GET_ALL_USERS_SUCCESS,
        types.GET_ALL_USERS_FAIL,
      ],
    },
  };
};

export const deleteUsers = (id) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'DELETE',
        url: '/v1/user',
        json: { id: id },
      },
      actions: [
        types.DELETE_USER_REQUEST,
        types.DELETE_USER_SUCCESS,
        types.DELETE_USER_FAIL,
      ],
    },
  };
};

export const addUser = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: '/v1/user',
      json: {
        ...payload,
        lastName: payload.lastName || '',
      },
    },
    actions: [
      types.ADD_USER_REQUEST,
      types.ADD_USER_SUCCESS,
      types.ADD_USER_FAIL,
    ],
  },
});

export const updateUser = (payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: '/v1/user',
        json: payload,
      },
      actions: [
        types.UPDATE_USER_REQUEST,
        types.UPDATE_USER_SUCCESS,
        types.UPDATE_USER_FAIL,
      ],
    },
  };
};

export const restoreUser = (userId) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: `/v1/user/restore/${userId}`,
    },
    actions: [
      types.RESTORE_USER_REQUEST,
      types.RESTORE_USER_SUCCESS,
      types.RESTORE_USER_FAIL,
    ],
  },
});

export const cleanUser = () => ({
  type: types.CLEAN_USER,
});

export const userLogout = () => ({ type: types.USER_LOGOUT });
