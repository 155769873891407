import React from 'react';
import IconFavLocation from '../../assets/svgs/icon-fav-location.svg';
import clsx from 'clsx';
import ChangeAssignedPopup from '../../components/Popups/ChangeAssignedPopup';
import { ReactSVG } from 'react-svg';
import EditImg from '../../assets/svgs/edit.svg';
import Timer from '../../components/Timer';

import LocationsTab from './LocationsTab';

class LocationItem extends React.Component {
  state = {
    openEditPopup: false,
  };

  handleOpenEditPopup = () => {
    this.setState(({ openEditPopup }) => ({ openEditPopup: !openEditPopup }));
  };
  render() {
    const {
      assignedLocations,
      activeJob,
      // onDragEnd,
      // getLocationItems,
      // showAll,
      // handleShowAll,
      // myRef,
      // removeLocation,
      // handleLocation,
      listAllLocations,
    } = this.props;
    const { openEditPopup } = this.state;

    return (
      <div className="card">
        <div className="card-title">Assigned Locations</div>
        <button
          id="edit-assigned-locations"
          className="btn-icon"
          onClick={this.handleOpenEditPopup}
        >
          <ReactSVG src={EditImg} />
        </button>
        <div className="locations-list">
          {assignedLocations?.map((assignedLocation) => (
            <div
              key={assignedLocation.id}
              className={clsx('location', {
                active: assignedLocation.id === activeJob?.locationId,
              })}
            >
              <img src={IconFavLocation} />
              <p className="heading">
                <span>{assignedLocation.name}</span>
                {assignedLocation.address}
                {assignedLocation.city ? ',' : ''} {assignedLocation.city}
                {assignedLocation.province ? ',' : ''}{' '}
                {assignedLocation.province}
                {assignedLocation.postalCode}
              </p>
              {assignedLocation.id === activeJob?.locationId && (
                <Timer
                  activeJob={activeJob}
                  listAllLocations={listAllLocations}
                />
              )}
            </div>
          ))}
        </div>
        <ChangeAssignedPopup
          open={openEditPopup}
          onClose={this.handleOpenEditPopup}
          content={<LocationsTab {...this.props} />}
        />
      </div>
    );
  }
}

export default LocationItem;
