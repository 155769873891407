import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ThemeProvider } from '@material-ui/styles';
import { checkBoxMaterialTheme } from '../../utils/checkBoxMaterialTheme';

const useStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: 15,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    width: 32,
    height: 32,
  },
  icon: {
    borderRadius: 2,
    width: 20,
    height: 20,
    'input:hover ~ &': {
      backgroundColor: '#ccc',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#61933a',
    'input:hover ~ &': {
      backgroundColor: '#61933a',
    },
  },
});

// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={checkBoxMaterialTheme}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={props.onChange}
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
          />
        }
        label={props.label}
      />
    </ThemeProvider>
  );
}

export default function CustomizedCheckbox({
  handleChecked,
  checked,
  name,
  label,
}) {
  return (
    <StyledCheckbox
      onChange={handleChecked}
      checked={checked}
      name={name ? name : ''}
      label={label}
    />
  );
}
