import { connect } from 'react-redux';
import AllLocationsAdminPage from './AllLocationsAdminPage';
import {
  addLocation,
  getAllLocations,
  deleteLocation,
} from '../../store/locationsStore/actions';

const mapStateToProps = ({
  locationsStore: {
    isLoading,
    listAllLocations,
    isLocationDeleted,
    isLocationAdded,
  },
}) => ({ isLoading, listAllLocations, isLocationDeleted, isLocationAdded });

const mapDispatchToProps = { getAllLocations, deleteLocation, addLocation };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllLocationsAdminPage);
