import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import AddWorkerToGroupPopup from '../../components/Popups/AddWorkerToGroupPopup';
import clsx from 'clsx';
import Spinner from '../../components/Spinner';
import Worker from './Worker';
import EditImg from '../../assets/svgs/edit.svg';
import DeleteImg from '../../assets/svgs/delete.svg';
import EditPEnImg from '../../assets/svgs/edit-pen.svg';
import WorkerCardImg from '../../assets/svgs/worker.svg';
import ChangeAssignedPopup from '../../components/Popups/ChangeAssignedPopup';
import LocationsTab from './LocationsTab';
import IconFavLocation from '../../assets/svgs/icon-fav-location.svg';
import Timer from '../../components/Timer';
import ChangeSelectedTasksPopup from '../../components/Popups/ChangeSelectedTasksPopup';
import AddGroupPopup from '../../components/Popups/AddGroupPopup';

const groupTasksByPlace = (tasks) =>
  tasks.reduce((acc, task) => {
    const { taskPlaceId } = task;
    if (!acc[taskPlaceId]) {
      acc[taskPlaceId] = {
        place: task.place,
        tasks: [],
      };
    }

    acc[taskPlaceId].tasks.push(task);
    return acc;
  }, {});

const groupByPlace = (places) =>
  places.reduce((acc, place) => {
    const { id } = place;
    if (!acc[id]) {
      acc[id] = {
        place: place,
      };
    }

    return acc;
  }, {});

class GroupPage extends React.Component {
  state = {
    addWorkersPopup: false,
    locationsPopup: false,
    tasksPopup: false,
    locationItems: [],
    placeTasks: { winter: {}, summer: {} },
    addGroupPopup: false,
    group: null,
  };
  componentDidMount() {
    const {
      getUsers,
      getWorkersGroupById,
      getAllLocations,
      getTaskPlace,
      match: { params },
    } = this.props;
    getWorkersGroupById(params.id);
    getUsers();
    getAllLocations();
    getTaskPlace();
    this.myRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      workerGroup,
      getActiveJob,
      isLoadingDeleted,
      getWorkersGroupById,
      listTasks,
      getLocationsTasks,
      tasksCategories,
      match: { params },
      isLoadingTasks,
    } = this.props;
    const { placeTasks } = this.state;

    if (
      prevProps.isLoadingDeleted !== isLoadingDeleted ||
      prevProps.isLoadingTasks !== isLoadingTasks
    ) {
      getWorkersGroupById(params.id);
      this.setState({ tasksPopup: false });
    }

    if (
      prevProps.workerGroup !== workerGroup &&
      workerGroup &&
      !workerGroup.msg
    ) {
      const lead = workerGroup.users.find(
        (user) =>
          user.WorkersGroupUserAssign?.userRole?.toUpperCase() === 'CREW LEAD',
      );
      if (lead) {
        getActiveJob(lead.id);
      }
      this.setState({
        locationItems: workerGroup.locations,
        // showAll: userItem.seesAllLocations,
      });
    }

    if (prevProps.listTasks !== listTasks && listTasks.length) {
      this.setState({ openTaskPopup: false, task: null });
      const placeMap = groupTasksByPlace(listTasks);

      const placeTasksTemp = { ...placeTasks };

      Object.keys(placeTasksTemp.summer).map((id) => {
        placeTasksTemp.summer[id].tasks = placeMap[id]
          ? placeMap[id].tasks
          : [];
      });

      Object.keys(placeTasksTemp.winter).map((id) => {
        placeTasksTemp.winter[id].tasks = placeMap[id]
          ? placeMap[id].tasks
          : [];
      });

      this.setState({
        placeTasks: placeTasksTemp,
      });
    }

    if (prevProps.tasksCategories !== tasksCategories) {
      const winter = tasksCategories.filter(
        ({ season }) => season.seasonId === 1,
      );
      const summer = tasksCategories.filter(
        ({ season }) => season.seasonId === 2,
      );

      const placeMapWinter = groupByPlace(winter);

      const placeMapSummer = groupByPlace(summer);
      getLocationsTasks();
      this.setState({
        placeTasks: { winter: placeMapWinter, summer: placeMapSummer },
      });
    }
  }

  handleAddGroupPopup = () => {
    this.setState({
      addGroupPopup: !this.state.addGroupPopup,
    });
  };

  componentWillUnmount() {}

  handleAddWorkersPopup = () => {
    this.setState({ addWorkersPopup: !this.state.addWorkersPopup });
  };

  handleLocationsPopup = () => {
    this.setState({ locationsPopup: !this.state.locationsPopup });
  };

  handleTasksPopup = () => {
    this.setState({ tasksPopup: !this.state.tasksPopup });
  };

  onDeleteUser = () => {};

  getLocationItems = () => {
    return this.state.locationItems;
  };

  render() {
    const {
      history,
      workerGroup,
      assignWorkerToGroup,
      removeWorkerFromGroup,
      updateWorkerOnGroup,
      listUsers,
      getWorkersGroupById,
      activeJob,
      listAllLocations,
      assignTaskToGroup,
      removeTaskFromGroup,
      tasksCategories,
      match: {
        params: { id },
      },
      deleteWorkerGroup,
      updateWorkerGroup,
    } = this.props;
    const {
      tasksPopup,
      addWorkersPopup,
      locationsPopup,
      placeTasks: { summer },
      addGroupPopup,
    } = this.state;

    const lead = workerGroup?.users.find(
      (user) =>
        user.WorkersGroupUserAssign?.userRole?.toUpperCase() === 'CREW LEAD',
    );
    const taskMap = groupTasksByPlace(workerGroup?.task || []);

    const summer1 = tasksCategories.filter(
      ({ season }) => season.seasonId === 2,
    );

    const placeMapSummer = groupByPlace(summer1);

    Object.keys(placeMapSummer).map((id) => {
      placeMapSummer[id].tasks = taskMap[id] ? taskMap[id].tasks : [];
    });

    const assignedTasksIds = workerGroup?.task.map((task) => task.id) || [];

    return (
      <Wrapper>
        <div className="container">
          {!workerGroup ? (
            <Spinner />
          ) : (
            <>
              <HeaderDiv>
                <Name>{workerGroup.name}</Name>
                <IconWrap>
                  <button
                    id="edit-location"
                    className="btn-icon"
                    onClick={this.handleAddGroupPopup}
                  >
                    <ReactSVG src={EditImg} />
                  </button>
                  <button
                    id="delete-location"
                    className="btn-icon delete"
                    onClick={() => {
                      deleteWorkerGroup(workerGroup.workersGroupId);
                      history.push('/group-workers');
                    }}
                  >
                    <ReactSVG src={DeleteImg} />
                  </button>
                </IconWrap>
              </HeaderDiv>
              <div className="col-row">
                <div className={clsx('main-col', {})}>
                  <div className="card flex-column">
                    <AllWrap className="card-title">
                      <Workers>
                        <ReactSVG src={WorkerCardImg} />
                        <span>Workers</span>
                      </Workers>
                      <ReactSVG
                        src={EditPEnImg}
                        onClick={this.handleAddWorkersPopup}
                      />
                    </AllWrap>
                    {workerGroup.users.map((user) => (
                      <Worker
                        lead={lead}
                        id={id}
                        key={user.id}
                        user={user}
                        assignWorkerToGroup={assignWorkerToGroup}
                        removeWorkerFromGroup={removeWorkerFromGroup}
                        updateWorkerOnGroup={updateWorkerOnGroup}
                      />
                    ))}
                  </div>
                </div>
                <div className={clsx('sidebar', {})}>
                  <div className="card">
                    <AllWrap className="card-title">
                      <Workers>
                        <ReactSVG src={WorkerCardImg} />
                        <span>Assigned Locations</span>
                      </Workers>
                      <ReactSVG
                        src={EditPEnImg}
                        onClick={this.handleLocationsPopup}
                      />
                    </AllWrap>
                    <div className="locations-list">
                      {workerGroup.locations?.map((assignedLocation) => (
                        <div
                          key={assignedLocation.id}
                          className={clsx('location', {
                            active:
                              assignedLocation.id === activeJob?.locationId,
                          })}
                        >
                          <img src={IconFavLocation} />
                          <p className="heading">
                            <span>{assignedLocation.name}</span>
                            {assignedLocation.address}
                            {assignedLocation.city ? ',' : ''}{' '}
                            {assignedLocation.city}
                            {assignedLocation.province ? ',' : ''}{' '}
                            {assignedLocation.province}
                            {assignedLocation.postalCode}
                          </p>
                          {assignedLocation.id === activeJob?.locationId && (
                            <Timer
                              activeJob={activeJob}
                              listAllLocations={listAllLocations}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="card">
                    <AllWrap className="card-title">
                      <Workers>
                        <ReactSVG src={IconFavLocation} />
                        <span>Assigned Tasks</span>
                      </Workers>
                      <ReactSVG
                        src={EditPEnImg}
                        onClick={this.handleTasksPopup}
                      />
                    </AllWrap>
                    <div>
                      {Object.keys(placeMapSummer).map((placeId) => {
                        const display = placeMapSummer[placeId].tasks.length;
                        return (
                          <Fragment key={placeId}>
                            {display > 0 && (
                              <WarpIn>
                                <Category>
                                  {placeMapSummer[placeId].place.name}
                                </Category>
                                <div className="wrap-tasks">
                                  {placeMapSummer[placeId].tasks?.map(
                                    (task) => (
                                      <span key={task.id}>{task.name}</span>
                                    ),
                                  )}
                                </div>{' '}
                              </WarpIn>
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {addWorkersPopup && (
          <AddWorkerToGroupPopup
            id={id}
            getWorkersGroupById={getWorkersGroupById}
            assignWorkerToGroup={assignWorkerToGroup}
            removeWorkerFromGroup={removeWorkerFromGroup}
            workerGroup={workerGroup}
            listUsers={listUsers}
            onClose={this.handleAddWorkersPopup}
          />
        )}
        <ChangeAssignedPopup
          open={locationsPopup}
          onClose={this.handleLocationsPopup}
          content={
            <LocationsTab
              id={id}
              getWorkersGroupById={getWorkersGroupById}
              myRef={this.myRef}
              getLocationItems={this.getLocationItems}
              {...this.props}
            />
          }
        />
        <ChangeSelectedTasksPopup
          assignedTasksIds={assignedTasksIds}
          idGroup={id}
          assignTaskToGroup={assignTaskToGroup}
          removeTaskFromGroup={removeTaskFromGroup}
          tasks={summer}
          open={tasksPopup}
          onClose={this.handleTasksPopup}
        />
        {addGroupPopup && (
          <AddGroupPopup
            updateWorkerGroup={updateWorkerGroup}
            addWorkerGroup={() => {}}
            group={workerGroup}
            onClose={this.handleAddGroupPopup}
            autoClose={this.handleAddGroupPopup}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .card {
    padding: 20px;
    margin-bottom: 30px;
  }
  p.heading,
  p.heading span {
    max-width: calc(100% - 30px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    width: 100%;
    color: var(--grey-text);
    width: calc(100% - 100px);
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.heading span {
    max-width: 100%;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 3px;
    color: var(--black-text);
  }
  .locations-list .location:not(:last-child) {
    margin-bottom: 20px;
  }
  .location {
    display: flex;
    align-items: center;
    position: relative;
  }
  .location.active p,
  .location.active img {
    z-index: 1;
  }
  .location.active::after {
    content: '';
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    left: -10px;
    border-radius: 8px;
    background: #f7f7f7;
    position: absolute;
    z-index: 0;
  }
  .location.active .timer {
    font-size: 16px;
    color: var(--secondary-color);
    position: flex;
    align-items: center;
    z-index: 1;
    margin-left: auto;
    font-weight: 500;
  }
  @media (min-width: 960px) {
    .col-row {
      display: flex;
      justify-content: space-between;
    }
    .main-col {
      width: calc(100% - 420px);
    }
    .sidebar {
      width: 390px;
    }
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrap = styled.div`
  display: flex;
`;

const Name = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  color: #333333;
`;
const Workers = styled.div`
  display: flex;
  svg {
    margin-right: 16.5px;
  }
`;

const AllWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Category = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-right: 34px;
  width: 133px;
`;

const WarpIn = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span:after {
    content: ', ';
  }

  span:last-child:after {
    content: '';
  }
  .wrap-tasks {
    width: 400px;
  }
`;

export default withRouter(GroupPage);
