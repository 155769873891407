import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';

const AddTaskPopup = ({ onClose, task, updateTask, addTask, location }) => {
  const [taskName, setTaskName] = useState(task?.name || '');
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <Wrapper id="add-location-modal" className="modal">
          <div className="modal-header">
            <div className="modal-title">{task ? 'Edit' : 'Add'} Task</div>
            <div className="modal-close" onClick={onClose}>
              <ReactSVG src={CloseSVG} />
            </div>
          </div>
          <div className="modal-body left-align">
            <div className="form-row flex">
              <label htmlFor="category">Task Name</label>
              <input
                defaultValue={taskName}
                id="category"
                type="category"
                onInput={(e) => setTaskName(e.target.value)}
              />
            </div>
          </div>
          <div className="cta-bar">
            <button
              type="submit"
              className="btn"
              onClick={() =>
                task
                  ? updateTask(task.id, {
                      name: taskName,
                      taskPlaceId: task.taskPlaceId,
                    })
                  : addTask({
                      name: taskName,
                      taskPlaceId: location,
                    })
              }
            >
              {task ? 'Save' : 'Add'}
            </button>
          </div>
        </Wrapper>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div``;

export default AddTaskPopup;
