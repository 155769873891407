import React, { Fragment } from 'react';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';
import AddCategoryPopup from '../../components/Popups/AddCategoryPopup';
import AddTaskPopup from '../../components/Popups/AddTaskPopup';
import WorkPlace from './WorkPlace';

const groupTasksByPlace = (tasks) =>
  tasks.reduce((acc, task) => {
    const { taskPlaceId } = task;
    if (!acc[taskPlaceId]) {
      acc[taskPlaceId] = {
        place: task.place,
        tasks: [],
      };
    }

    acc[taskPlaceId].tasks.push(task);
    return acc;
  }, {});

const groupByPlace = (places) =>
  places.reduce((acc, place) => {
    const { id } = place;
    if (!acc[id]) {
      acc[id] = {
        place: place,
      };
    }

    return acc;
  }, {});
class TasksPage extends React.Component {
  state = {
    openCategoryPopup: false,
    openTaskPopup: false,
    placeTasks: { winter: {}, summer: {} },
    task: null,
    category: null,
    location: 1,
  };

  componentDidMount() {
    const { getTaskPlace } = this.props;
    getTaskPlace();
  }

  componentDidUpdate(prevProps) {
    const {
      listTasks,
      tasksCategories,
      getLocationsTasks,
      isLoadingDeleted,
      getTaskPlace,
    } = this.props;
    const { placeTasks } = this.state;

    if (prevProps.isLoadingDeleted !== isLoadingDeleted && !isLoadingDeleted) {
      getTaskPlace();
    }

    if (prevProps.listTasks !== listTasks && listTasks.length) {
      this.setState({ openTaskPopup: false, task: null });
      const placeMap = groupTasksByPlace(listTasks);

      const placeTasksTemp = { ...placeTasks };

      Object.keys(placeTasksTemp.summer).map((id) => {
        placeTasksTemp.summer[id].tasks = placeMap[id]
          ? placeMap[id].tasks
          : [];
      });

      Object.keys(placeTasksTemp.winter).map((id) => {
        placeTasksTemp.winter[id].tasks = placeMap[id]
          ? placeMap[id].tasks
          : [];
      });
      this.setState({
        placeTasks: placeTasksTemp,
        //   openCategoryPopup: false,
        //   openTaskPopup: false,
      });
    }
    if (prevProps.tasksCategories !== tasksCategories) {
      const winter = tasksCategories.filter(
        ({ season }) => season.seasonId === 1,
      );
      const summer = tasksCategories.filter(
        ({ season }) => season.seasonId === 2,
      );

      const placeMapWinter = groupByPlace(winter);

      const placeMapSummer = groupByPlace(summer);
      getLocationsTasks();
      this.setState({
        placeTasks: { winter: placeMapWinter, summer: placeMapSummer },
        openCategoryPopup: false,
        openTaskPopup: false,
      });
    }
  }

  handleOpenCategoryPopup = (e, category) => {
    this.setState(({ openCategoryPopup }) => ({
      openCategoryPopup: !openCategoryPopup,
      category: category || null,
    }));
  };

  handleOpenTaskPopup = (e, task, placeId) => {
    this.setState(({ openTaskPopup }) => ({
      openTaskPopup: !openTaskPopup,
      task: task || null,
      location: placeId,
    }));
  };

  addCategory = (payload) => {
    const { addTaskCategory } = this.props;
    addTaskCategory(payload);
  };

  render() {
    const {
      isLoadingMain,
      deleteTaskPlace,
      updateTask,
      deleteTask,
      addTask,
      updateTaskPlace,
    } = this.props;
    const {
      openCategoryPopup,
      openTaskPopup,
      task,
      placeTasks,
      category,
      location,
    } = this.state;

    const winter = placeTasks?.winter;
    const summer = placeTasks?.summer;

    return (
      <Wrapper>
        <div className="title-bar">
          <h1>Tasks</h1>
          <button
            id="add-location"
            className="btn"
            onClick={this.handleOpenCategoryPopup}
          >
            ADD TASK CATEGORY
          </button>
        </div>
        <div className="container">
          {isLoadingMain ? (
            <Spinner />
          ) : (
            <>
              <div>
                <Season>Winter</Season>

                {Object.keys(winter).map((placeId) => {
                  winter[placeId]?.tasks?.sort(
                    (taskOne, taskTwo) => taskOne.id - taskTwo.id,
                  );
                  return (
                    <Fragment key={placeId}>
                      <WorkPlace
                        deleteTask={deleteTask}
                        deleteTaskPlace={deleteTaskPlace}
                        season={winter}
                        placeId={placeId}
                        handleOpenCategoryPopup={this.handleOpenCategoryPopup}
                        handleOpenTaskPopup={this.handleOpenTaskPopup}
                      />
                      <Add
                        onClick={() =>
                          this.handleOpenTaskPopup(null, null, placeId)
                        }
                      >
                        + Add Task
                      </Add>
                    </Fragment>
                  );
                })}
              </div>

              <div>
                <Season>Summer</Season>

                {Object.keys(summer).map((placeId) => {
                  summer[placeId]?.tasks?.sort(
                    (taskOne, taskTwo) => taskOne.id - taskTwo.id,
                  );
                  return (
                    <Fragment key={placeId}>
                      <WorkPlace
                        deleteTask={deleteTask}
                        deleteTaskPlace={deleteTaskPlace}
                        season={summer}
                        placeId={placeId}
                        handleOpenCategoryPopup={this.handleOpenCategoryPopup}
                        handleOpenTaskPopup={this.handleOpenTaskPopup}
                      />

                      <Add
                        onClick={() =>
                          this.handleOpenTaskPopup(null, null, placeId)
                        }
                      >
                        + Add Task
                      </Add>
                    </Fragment>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {openCategoryPopup && (
          <AddCategoryPopup
            updateTaskPlace={updateTaskPlace}
            category={category}
            addCategory={this.addCategory}
            onClose={this.handleOpenCategoryPopup}
          />
        )}
        {openTaskPopup && (
          <AddTaskPopup
            location={location}
            addTask={addTask}
            updateTask={updateTask}
            task={task}
            onClose={this.handleOpenTaskPopup}
          />
        )}
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  h2 {
    color: #777777;
  }
`;

const Add = styled.div`
  font-weight: bold;
  color: #61933a;
  margin-top: 15px;
  margin-bottom: 30px;
`;

const Season = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;

  color: #333333 !important;
`;

export default TasksPage;
