import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import LocationActiveImg from '../../assets/svgs/location-active.svg';
import LocationInactiveImg from '../../assets/svgs/location-inactive.svg';
import TimerActiveImg from '../../assets/svgs/timer-active.svg';
import TimerInactiveImg from '../../assets/svgs/timer-inactive.svg';
import PersonActiveImg from '../../assets/svgs/preson-active.svg';
import PersonInactiveImg from '../../assets/svgs/preson-inactive.svg';
import GraphicInactiveImg from '../../assets/svgs/graphic.svg';
import GraphicActiveImg from '../../assets/svgs/graphic-active.svg';
import CitiesInactiveImg from '../../assets/svgs/cities.svg';
import CitiesActiveImg from '../../assets/svgs/cities-active.svg';
import LogoImg from '../../assets/images/logo.svg';
import DashboardImg from '../../assets/svgs/dashboard.svg';
import DashboardActiveImg from '../../assets/svgs/dashboard-active.svg';
import SettingImg from '../../assets/svgs/settings-menu.svg';
import SettingImgActive from '../../assets/svgs/settings-menu-active.svg';
import UserGroup from '../../assets/svgs/users-group.svg';
import UserGroupActive from '../../assets/svgs/users-group-active.svg';
import TruckActive from '../../assets/svgs/truckActive.svg';
import TruckInactive from '../../assets/svgs/truckInactive.svg';

class FooterMenu extends PureComponent {
  componentDidMount() {
    document.body.classList.add('with-nav');
  }

  render() {
    const {
      history,
      location: { pathname },
    } = this.props;
    return (
      <Wrapper>
        <div className="logo hidden-mobile">
          <img src={LogoImg} alt="" />
        </div>
        <ul>
          <li
            className={pathname === '/' && 'active'}
            onClick={() => history.push('/')}
          >
            <Link to="/">
              {pathname === '/' ? (
                <ReactSVG src={DashboardActiveImg} />
              ) : (
                <ReactSVG src={DashboardImg} />
              )}
              {/*<span className="hidden-mobile">Time Logs</span>*/}
            </Link>
          </li>

          <li
            className={pathname === '/jobs-list' && 'active'}
            onClick={() => history.push('/jobs-list')}
          >
            <Link to="/jobs-list">
              {pathname === '/jobs-list' ? (
                <ReactSVG src={TimerActiveImg} />
              ) : (
                <ReactSVG src={TimerInactiveImg} />
              )}
              {/*<span className="hidden-mobile">Time Logs</span>*/}
            </Link>
          </li>
          <li
            className={pathname === '/locations' && 'active'}
            onClick={() => history.push('/locations')}
          >
            <Link to="/locations" border>
              {pathname === '/locations' ? (
                <ReactSVG src={LocationActiveImg} />
              ) : (
                <ReactSVG src={LocationInactiveImg} />
              )}
              {/*<span className="hidden-mobile">Locations</span>*/}
            </Link>
          </li>
          <li
            // className={pathname === '/equipment' && 'active'}
            onClick={() => history.push('/equipment')}
          >
            <Link to="/equipment" border>
              {pathname === '/equipment' ? (
                <ReactSVG src={TruckActive} />
              ) : (
                <ReactSVG src={TruckInactive} />
              )}
              {/*<span className="hidden-mobile">Locations</span>*/}
            </Link>
          </li>
          <li
            className={pathname === '/users' && 'active'}
            onClick={() => history.push('/users')}
          >
            <Link to="/users">
              {pathname === '/users' ? (
                <ReactSVG src={PersonActiveImg} />
              ) : (
                <ReactSVG src={PersonInactiveImg} />
              )}
              {/*<span className="hidden-mobile">Workers</span>*/}
            </Link>
          </li>
          <li
            className={pathname === '/reports' && 'active'}
            onClick={() => history.push('/reports')}
          >
            <Link to="/reports">
              {pathname === '/reports' ? (
                <ReactSVG src={GraphicActiveImg} />
              ) : (
                <ReactSVG src={GraphicInactiveImg} />
              )}
              {/*<span className="hidden-mobile">Reports</span>*/}
            </Link>
          </li>
          <li
            className={pathname === '/cities' && 'active'}
            onClick={() => history.push('/cities')}
          >
            <Link to="/cities">
              {pathname === '/cities' ? (
                <ReactSVG src={CitiesActiveImg} />
              ) : (
                <ReactSVG src={CitiesInactiveImg} />
              )}
              {/*<span className="hidden-mobile">Cities</span>*/}
            </Link>
          </li>
          {/* <li
            className={pathname === '/group-workers'}
            onClick={() => history.push('/group-workers')}
          >
            <Link to="/group-workers">
              {pathname === '/group-workers' ? (
                <ReactSVG src={UserGroupActive} />
              ) : (
                <ReactSVG src={UserGroup} />
              )}
            </Link>
          </li> */}

          <li
            className={pathname === '/tasks'}
            onClick={() => history.push('/tasks')}
          >
            <Link to="/tasks">
              {pathname === '/tasks' ? (
                <ReactSVG src={SettingImgActive} />
              ) : (
                <ReactSVG src={SettingImg} />
              )}
              {/*<span className="hidden-mobile">Reports</span>*/}
            </Link>
          </li>
        </ul>
      </Wrapper>
    );
  }
}

const Wrapper = styled.nav`
  li {
    width: 25%;
    display: flex;
    justify-content: center;
    height: 72%;
    align-items: center;
    cursor: pointer;
  }
  a {
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
  li.active::before {
    content: '';
    width: 3px;
    height: 40px;
    background: #61933a;
    position: absolute;
    left: -30px;
    top: 15px;
  }
  @media (min-width: 960px) {
    li {
      width: auto;
      height: 70px;
      justify-content: start;
    }
    li:last-child {
      position: fixed;
      bottom: 0px;
      margin: 0 auto;
      width: 80px;
    }
  }
`;

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FooterMenu),
);
