import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';
import { getOptionsTeamMembers } from '../../../utils/getOptionsTeamMembers';
import { isEqual } from 'lodash';
import Button from '@atlaskit/button';
import { getInitials } from '../../../utils/initials';
import AddIconGrey from '../../../assets/svgs/add-icon-grey.svg';
import StyledMultiSelect from '../../StyledMultiSelect';
const Menu = (props) => {
  return <div {...props} />;
};
const Blanket = (props) => <div {...props} />;
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
export default class AddWorkerToGroupPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      assignedUsers: [],
      options: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { workerGroup } = this.props;
    if (workerGroup) {
      this.setState({
        assignedUsers: getOptionsTeamMembers(workerGroup.users),
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { listUsers, handleSetUsers } = this.props;
    const { assignedUsers } = this.state;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
      if (!isEqual(listUsers, assignedUsers)) {
        const usersIds = assignedUsers.map(({ value }) => value);
        handleSetUsers(usersIds);
      }
    }
  }

  toggleOpen = () => {
    const { listUsers } = this.props;
    const { assignedUsers } = this.state;
    // const usersIds = assignedUsers.map(({ value }) => value);
    // if (!this.state.isOpen && !listUsers.length) getTeamMembers({});
    // else
    // if (this.state.isOpen) updateEventAssignees({ eventNumber, usersIds });
    // else
    if (listUsers.length) {
      let onlyValuesAssign = assignedUsers.map(({ value }) => value);

      this.setState({
        options: getOptionsTeamMembers(
          listUsers.filter(
            (user) => user.active !== 'inactive' && user.role !== 'mechanic',
          ),
        ).sort(function (a, b) {
          return (
            onlyValuesAssign.indexOf(b.value) -
            onlyValuesAssign.indexOf(a.value)
          );
        }),
      });
    }

    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  difference(a1, a2) {
    let a2Set = new Set(a2);
    return a1.filter(function (x) {
      return !a2Set.has(x);
    });
  }

  onSelectChange = (value) => {
    const { assignWorkerToGroup, id } = this.props;
    const onlyValues = value.map((value) => value);

    const valuesWithRoles = value.map(({ value, role }) => {
      return { userId: value, userRole: role?.toUpperCase() || 'WORKER' };
    });
    assignWorkerToGroup(id, {
      users: valuesWithRoles,
    });

    this.setState({
      assignedUsers: onlyValues,
    });
  };

  render() {
    const { listUsers, onClose } = this.props;
    const { assignedUsers, isOpen, options } = this.state;
    return (
      <Modal>
        <div className="modal-blur" />
        <div className="modal-overlay">
          <Wrapper id="add-location-modal" className="modal">
            <div className="modal-header">
              <div className="modal-title">Workers</div>
              <div className="modal-close" onClick={onClose}>
                <ReactSVG src={CloseSVG} />
              </div>
            </div>
            <div
              style={{ overflow: 'visible' }}
              className="modal-body left-align"
            >
              <div ref={this.setWrapperRef}>
                <Dropdown
                  isOpen={isOpen}
                  onClose={this.toggleOpen}
                  target={
                    <Button
                      className="button"
                      style={{
                        backgroundColor: '#fff',
                        height: 'auto',
                        padding: 0,
                      }}
                      onClick={this.toggleOpen}
                      isSelected={isOpen}
                    >
                      <div>
                        {assignedUsers.map((user, index) => {
                          const userItem = listUsers.find(
                            (item) => item.id === user.value,
                          );

                          return (
                            <div className="worker" key={index}>
                              <div
                                className="avatar"
                                style={{ background: user.color }}
                                key={user.value}
                              >
                                {userItem &&
                                  getInitials({
                                    name:
                                      userItem.name + ' ' + userItem.lastName,
                                  })}
                              </div>
                              <div>
                                {(user && user.name) ||
                                  (userItem &&
                                    userItem.name + ' ' + userItem.lastName)}
                              </div>
                            </div>
                          );
                        })}
                        <div className="worker">
                          <div className="avatar">
                            <ReactSVG src={AddIconGrey} />
                          </div>
                          <div>Add worker</div>
                        </div>
                      </div>
                    </Button>
                  }
                >
                  <StyledMultiSelect
                    // assignedUsers={assignedUsers}
                    noPaint={true}
                    id="eventTypeName"
                    name="eventTypeName"
                    onChange={this.onSelectChange}
                    data={options}
                    value={assignedUsers}
                  />
                </Dropdown>
              </div>
            </div>
          </Wrapper>
        </div>
      </Modal>
    );
  }
}
const Wrapper = styled.div`
  .modal-body .worker > div:nth-child(2) {
    color: #333 !important;
  }
`;
