import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

function MachineryForm(props) {
  const { equipment, handleSubmit } = props;
  return (
    <Formik
      initialValues={{
        year: equipment ? equipment.year || '' : '',
        make: equipment ? equipment.make || '' : '',
        model: equipment ? equipment.model || '' : '',
        equipmentNumber: equipment ? equipment.equipmentNumber || '' : '',
        vinSerialNumber: equipment ? equipment.vinSerialNumber || '' : '',
        engineMake: equipment ? equipment.engineMake || '' : '',
        engineModel: equipment ? equipment.engineModel || '' : '',
        engineSerialNumber: equipment ? equipment.engineSerialNumber || '' : '',
        nextServiceDate: equipment
          ? moment(equipment.nextServiceDate).format('YYYY-MM-DD') || ''
          : '',
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        year: Yup.string().nullable(),
        make: Yup.string().nullable(),
        model: Yup.string().nullable(),
        equipmentNumber: Yup.string().required('is required'),
        vinSerialNumber: Yup.string().nullable(),
        engineMake: Yup.string().nullable(),
        engineModel: Yup.string().nullable(),
        engineSerialNumber: Yup.string().nullable(),
        nextServiceDate: Yup.string().nullable(),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <form id="machinery-form" noValidate="" onSubmit={handleSubmit}>
            <div className="form-row flex">
              <ShortWrap>
                <label htmlFor="year">Year</label>
                <input
                  id="year"
                  value={values.year}
                  type="number"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </ShortWrap>
              <MediumWrap>
                <label htmlFor="make">Make</label>
                <input
                  id="make"
                  value={values.make}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </MediumWrap>
              <MediumWrap>
                <label htmlFor="model">Model</label>
                <input
                  id="model"
                  value={values.model}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </MediumWrap>
            </div>
            <div className="form-row flex">
              <ShortWrap>
                <label htmlFor="equipmentNumber">Equipment Number</label>
                <input
                  id="equipmentNumber"
                  value={values.equipmentNumber}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.equipmentNumber && touched.equipmentNumber && (
                  <Error className="form-error-message">
                    Equipment Number {errors.equipmentNumber}
                  </Error>
                )}
              </ShortWrap>
              <LongWrap>
                <label htmlFor="vinSerialNumber">Serial number</label>
                <input
                  id="vinSerialNumber"
                  value={values.vinSerialNumber}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </LongWrap>
            </div>

            <div className="form-row halfs">
              <div>
                <label htmlFor="engineMake">Engine Make</label>
                <input
                  id="engineMake"
                  type="text"
                  value={values.engineMake}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <label htmlFor="engineModel">Engine Model</label>
                <input
                  id="engineModel"
                  type="text"
                  value={values.engineModel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="form-row halfs">
              <div>
                <label htmlFor="engineSerialNumber">Engine Serial Number</label>
                <input
                  id="engineSerialNumber"
                  type="text"
                  value={values.engineSerialNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <label htmlFor="nextServiceDate">Next Service Date</label>
                <input
                  id="nextServiceDate"
                  type="date"
                  value={values.nextServiceDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

const Error = styled.div`
  color: var(--alert);
`;

const ShortWrap = styled.div`
  width: 20%;
`;

const MediumWrap = styled.div`
  width: 35%;
`;

const LongWrap = styled.div`
  width: 75%;
`;

export default MachineryForm;
