import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';
import Form from './Form';
import { ResetDialog } from './ResetDialog';
import LogoImg from '../../assets/images/logo.png';

class ResetPasswordPage extends React.Component {
  state = {
    passwordUpdated: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.passwordChanged !== this.props.passwordChanged) {
      this.setState({ passwordUpdated: true });
    }
  }

  render() {
    const {
      isLoading,
      location,
      resetPassword,
      history: { goBack },
    } = this.props;

    const token = location.search.split('=')[1];
    const decoded = jwt_decode(token);
    const nowDate = new Date();
    const expDate = new Date(decoded.exp * 1000);

    const linkExpired = expDate < nowDate;
    const email = decoded.email;

    const { passwordUpdated } = this.state;
    return (
      <div className="container">
        <Logo className="logo">
          <img src={LogoImg} />
        </Logo>
        <Wrap className="card login">
          {linkExpired ? (
            <Expire className="expire" style={{ textAlign: 'center' }}>
              <h1>Link is expired</h1>
              <p className="links">
                <Link to={'/login'}>Sign In</Link>
              </p>
            </Expire>
          ) : (
            <Fragment>
              <p className="card-title">Create new password</p>
              {passwordUpdated ? (
                <ResetDialog />
              ) : (
                <Form
                  email={email}
                  location={location}
                  isLoading={isLoading}
                  goBack={goBack}
                  resetPassword={resetPassword}
                />
              )}
            </Fragment>
          )}
        </Wrap>
      </div>
    );
  }
}
const Wrap = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-top: 80px;
  .card-title {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    width: unset;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    margin-block-end: 1em;
  }
`;

const Expire = styled.div`
  .links {
    margin-top: 20px;
  }
`;

const Logo = styled.div`
  img {
    width: 120px;
  }
  text-align: center;
  margin: 20px 0;
`;

export default ResetPasswordPage;
