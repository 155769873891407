import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseSVG from '../../../assets/images/close.svg';
import Modal from '../../Modal';

const AddGroupPopup = ({
  onClose,
  group,
  updateWorkerGroup,
  addWorkerGroup,
  autoClose,
}) => {
  const [groupName, setGroupName] = useState(group?.name || '');
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <Wrapper id="add-location-modal" className="modal">
          <div className="modal-header">
            <div className="modal-title">{group ? 'Edit' : 'Add'} Team</div>
            <div className="modal-close" onClick={onClose}>
              <ReactSVG src={CloseSVG} />
            </div>
          </div>
          <div className="modal-body left-align">
            <div className="form-row flex">
              <label htmlFor="category">Team Name</label>
              <input
                defaultValue={groupName}
                id="category"
                type="category"
                onInput={(e) => setGroupName(e.target.value)}
              />
            </div>
          </div>
          <div className="cta-bar">
            <button
              className="btn"
              onClick={() => {
                autoClose && autoClose();
                group
                  ? updateWorkerGroup(group.workersGroupId, { name: groupName })
                  : addWorkerGroup({ name: groupName });
              }}
            >
              {group ? 'Save' : 'Add'}
            </button>
          </div>
        </Wrapper>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div``;

export default AddGroupPopup;
