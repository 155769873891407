import React from 'react';
import ReactDOM from 'react-dom';

export default class Modal extends React.Component {
  root = document.getElementById('modal');
  element = document.createElement('div');

  componentDidMount() {
    this.root.appendChild(this.element);
  }

  componentWillUnmount() {
    this.root.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}
