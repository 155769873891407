import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.GET_LIST_CITIES_REQUEST, handleRequest),
  takeEvery(types.GET_WEATHER_REPORT_BY_DATES_REQUEST, handleRequest),
  takeEvery(types.GET_JOB_REPORT_REQUEST, handleRequest),
  takeEvery(types.GET_WORKING_DAY_REPORT_REQUEST, handleRequest),
]);
