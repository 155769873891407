import React from 'react';
import styled from 'styled-components';
import Modal from '../../Modal';
import { ReactSVG } from 'react-svg';
import CloseSVG from '../../../assets/images/close.svg';

const COVIDUnlockPopup = ({ user, onClose }) => {
  return (
    <Modal>
      <div className="modal-blur" />
      <div className="modal-overlay">
        <div className="modal">
          <Wrapper>
            <div className="modal-header">
              <div className="modal-title">Account unlocked</div>
              <div className="modal-close" onClick={onClose}>
                <ReactSVG src={CloseSVG} />
              </div>
            </div>
            <div className="modal-body">
              <p>
                {`${user.name}  ${user.lastName}'s account is now unlocked!`}
              </p>
            </div>

            <div className="cta-bar" onClick={onClose}>
              <button className="btn">Close</button>
            </div>
          </Wrapper>
        </div>
      </div>
    </Modal>
  );
};
const Wrapper = styled.div`
  .modal-body {
    margin: 0 auto;
  }

  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

export default COVIDUnlockPopup;
