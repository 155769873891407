import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  listEquipment: [],
  equipmentTypes: [],
  currentEquipment: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_EQUIPMENT_REQUEST:
      return { ...state, listEquipment: [], isLoading: true };
    case types.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        listEquipment: payload.data,
        isLoading: false,
      };
    case types.GET_EQUIPMENT_FAIL:
      return { ...state, isLoading: false, error: payload };
    case types.GET_EQUIPMENT_TYPE_REQUEST:
      return { ...state, equipmentTypes: [], isLoading: true };
    case types.GET_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
        equipmentTypes: payload.data,
        isLoading: false,
      };
    case types.GET_EQUIPMENT_TYPE_FAIL:
      return { ...state, isLoading: false, error: payload };
    case types.ADD_EQUIPMENT_REQUEST:
      return { ...state, isLoading: true };
    case types.ADD_EQUIPMENT_SUCCESS:
      return { ...state, isLoading: false, listEquipment: payload.data };
    case types.ADD_EQUIPMENT_FAIL:
      return { ...state, isLoading: false, error: payload };
    case types.DELETE_EQUIPMENT_REQUEST:
      return { ...state, isLoading: true };
    case types.DELETE_EQUIPMENT_SUCCESS:
      return { ...state, isLoading: false, listEquipment: payload.data };
    case types.DELETE_EQUIPMENT_FAIL:
      return { ...state, isLoading: false, error: payload };
    case types.UPDATE_EQUIPMENT_REQUEST:
      return { ...state, isLoading: true };
    case types.UPDATE_EQUIPMENT_SUCCESS:
      let prevList = state.listEquipment.slice(0);
      prevList.some((item) => {
        if (item.id === payload.data.id) {
          prevList.splice(prevList.indexOf(item), 1, payload.data);
        }
        return item.id === payload.data.id;
      });
      return {
        ...state,
        isLoading: false,
        listEquipment: prevList,
        currentEquipment: payload.data,
      };
    case types.UPDATE_EQUIPMENT_FAIL:
      return { ...state, isLoading: false, error: payload };
    case types.GET_EQUIPMENT_BY_ID_REQUEST:
      return { ...state, currentEquipment: null, isLoading: true };
    case types.GET_EQUIPMENT_BY_ID_SUCCESS:
      return {
        ...state,
        currentEquipment: payload.data,
        isLoading: false,
      };
    case types.GET_EQUIPMENT_BY_ID_FAIL:
      return { ...state, isLoading: false, error: payload };
    case types.RESET_CURRENT_EQUIPMENT:
      return { ...state, currentEquipment: null };
    default:
      return state;
  }
};
