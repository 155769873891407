import React, { Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../config';
import { getAccessToken } from '../../services/api/storage';
import { getCSVFile } from '../../utils/getCSVFile';
import { ReactSVG } from 'react-svg';
import WindImg from '../../assets/svgs/wind.svg';
import WindGustImg from '../../assets/svgs/wind-gust.svg';
import RainImg from '../../assets/svgs/rain.svg';
import SnowImg from '../../assets/svgs/snow.svg';
import { formatDate } from '../../utils/formatDate';
import Spinner from '../../components/Spinner';
import DownloadImg from '../../assets/svgs/download.svg';
import { degToCompass } from '../../utils/degToCompass';

const WEATHER_API_LINK = 'http://openweathermap.org/img/wn/';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const formatTime = (time) => {
  const arr = moment(time).format('hh A').slice(0, 16).split(' ');
  const number = +arr[0];

  return number + ' ' + arr[1];
};

class ReportsWeatherPage extends React.Component {
  componentDidMount() {
    const {
      getWeatherReportByDates,
      match: { params },
    } = this.props;
    getWeatherReportByDates(params.city, params.startDate, params.endDate);
  }

  downloadPDF = async () => {
    const {
      match: { params },
    } = this.props;
    let url = `${API_URL}/v1/weather/report/csv/${params.city}?startDate=${params.startDate}&endDate=${params.endDate}`;
    const token = getAccessToken();

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token ? `${token}` : '',
      },
    });

    let blob = await response.blob();

    getCSVFile(
      blob,
      `Weather Report ${params.city} ${params.startDate.slice(
        0,
        -14,
      )}-${params.endDate.slice(0, -14)}.csv`,
    );
  };

  render() {
    const {
      isLoading,
      reportWeather,
      match: { params },
    } = this.props;

    const endDate = new Date(params.endDate);
    endDate.setDate(endDate.getDate() - 1);

    return (
      <div className="container change">
        <Wrapper>
          <h2 className="underline">
            <div>
              Weather Report:{' '}
              <span>
                {params.startDate.slice(0, -14).replaceAll('-', '.')} -{' '}
                {endDate.toISOString().slice(0, -14).replaceAll('-', '.')}
              </span>
            </div>
            <DownloadButton onClick={this.downloadPDF}>
              <ReactSVG src={DownloadImg} />
            </DownloadButton>
          </h2>
          <div className="underline mb-30">
            <span className="bold">Location: </span>
            {params.city}
          </div>
          {isLoading && !reportWeather.length && <Spinner />}
          {reportWeather.map(({ date, weather }) => (
            <Fragment key={date}>
              <h2>{formatDate(date)}</h2>
              <div className="card">
                {weather.map((weatherItem) => (
                  <div className="row" key={weatherItem.time}>
                    <div className="flex vertical-center">
                      <div className="time">
                        {formatTime(weatherItem.time.slice(0, -5))}
                      </div>
                      <div className="icon">
                        <img
                          src={
                            WEATHER_API_LINK +
                            weatherItem.weatherDescription[0]?.icon +
                            '.png'
                          }
                        />
                      </div>
                    </div>
                    <div className="weather-info">
                      <div className="left-col">
                        <div className="temp">
                          {Math.floor(weatherItem.temperature)}&deg;C
                        </div>
                        <div className="feels-like">
                          Feels like{' '}
                          {Math.floor(weatherItem.fillLikeTemperature)}
                          &deg;C
                        </div>
                        <div className="description">
                          {capitalize(
                            weatherItem.weatherDescription[0]?.description,
                          )}
                        </div>
                      </div>
                      <div className="right-col grey">
                        <div className="wind">
                          {weatherItem.windSpeed !== null && (
                            <>
                              <ReactSVG src={WindImg} />
                              <span>
                                {weatherItem.windSpeed >= 1
                                  ? Math.floor(weatherItem.windSpeed)
                                  : '<1'}{' '}
                                km/h{' '}
                                {weatherItem.windDegrees &&
                                  degToCompass(weatherItem.windDegrees)}
                              </span>
                            </>
                          )}
                        </div>
                        <div className="wind-gust">
                          {weatherItem.windGustSpeed !== null && (
                            <>
                              <ReactSVG src={WindGustImg} />
                              <span>
                                {weatherItem.windGustSpeed >= 1
                                  ? Math.floor(weatherItem.windGustSpeed)
                                  : '< 1'}{' '}
                                km/h
                              </span>
                            </>
                          )}
                        </div>
                        <div className="rain">
                          {weatherItem.rainPrecipitation1h !== null && (
                            <>
                              <ReactSVG src={RainImg} />
                              <span>
                                {weatherItem.rainPrecipitation1h >= 1
                                  ? weatherItem.rainPrecipitation1h
                                  : '< 1'}{' '}
                                mm
                              </span>
                            </>
                          )}
                        </div>
                        <div className="snow">
                          {weatherItem.snowPrecipitation1h !== null && (
                            <>
                              <ReactSVG src={SnowImg} />
                              <span>
                                {weatherItem.snowPrecipitation1h >= 1
                                  ? weatherItem.snowPrecipitation1h
                                  : '< 1'}{' '}
                                cm
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          ))}
        </Wrapper>
      </div>
    );
  }
}

const Wrapper = styled.div`
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2 span {
    font-weight: 300;
  }
  .bold {
    font-weight: 700;
  }
  .underline {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
  }
  .card {
    padding: 20px 20px 10px;
    margin-bottom: 15px;
    position: relative;
  }
  .flex {
    display: flex;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .row:last-child {
    border: 0;
    margin-bottom: -10px;
  }
  .weather-info {
    width: calc(100% - 120px);
  }
  .left-col,
  .right-col {
    display: flex;
    align-items: center;
  }
  .right-col svg {
    margin-right: 10px;
  }
  .time {
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px;
    width: 50px;
  }
  .icon {
    position: relative;
    margin-top: -10px;
    margin-right: 10px;
    width: 50px;
    text-align: center;
  }
  .temp {
    font-size: 24px;
    font-weight: 700;
    margin-right: 30px;
    white-space: nowrap;
  }
  .feels-like {
    margin-right: 30px;
  }
  .feels-like,
  .description {
    font-weight: 700;
  }
  .wind,
  .wind-gust,
  .rain,
  .snow {
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 80px;
    @media (max-width: 960px) {
      width: unset;
      margin-right: 20px;
    }
  }
  .wind {
    min-width: 130px;
    @media (max-width: 960px) {
      min-width: unset;
      margin-right: 20px;
    }
  }
  .wind-gust {
    min-width: 100px;
    @media (max-width: 960px) {
      min-width: unset;
      margin-right: 20px;
    }
  }
  .rain {
    min-width: 100px;
    @media (max-width: 960px) {
      min-width: unset;
      margin-right: 20px;
    }
  }
  .grey {
    color: #777777;
  }

  @media (min-width: 960px) {
    .card {
      padding: 20px 30px 10px;
    }
    .vertical-center {
      align-items: center;
    }
    .icon {
      margin-top: 0;
    }
  }

  @media (min-width: 1280px) {
    .weather-info {
      display: flex;
    }
    .left-col {
      width: 50%;
    }
    .right-col > div:not(:first-child) {
      margin-left: 30px;
    }
    .right-col {
      justify-content: flex-end;
      @media (max-width: 960px) {
        width: 46%;
      }
    }
  }
`;

const DownloadButton = styled.button`
  width: 60px;
  height: 40px;
  outline: none;
  background: #61933a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export default withRouter(ReportsWeatherPage);
