import { takeEvery, all, put } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';
import { getCities } from './actions';

function* reFetch() {
  yield put(getCities());
}

export default all([
  takeEvery(types.GET_LIST_CITIES_REQUEST, handleRequest),
  takeEvery(types.ADD_CITY_REQUEST, handleRequest),
  takeEvery(types.ADD_CITY_SUCCESS, reFetch),
  takeEvery(types.DELETE_CITY_REQUEST, handleRequest),
  takeEvery(types.DELETE_CITY_SUCCESS, reFetch),
  takeEvery(types.DELETE_CITY_FAIL, reFetch),
]);
