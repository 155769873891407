import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import { ReactSVG } from 'react-svg';
import MarkImg from '../../assets/svgs/mark.svg';
import Spinner from '../Spinner';
import styled from 'styled-components';

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    {props.isSelected ? <ReactSVG src={MarkImg} wrapper="span" /> : <div />}
    {props.data.label}
  </Option>
);

const StyledMultiSelect = ({
  isOneOption,
  onChange,
  data,
  isLoading,
  value,
  id,
  noPaint,
  menuPortalTarget,
}) => {
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#fff'
          : isFocused
          ? '#fff'
          : null,
        color: isDisabled ? '#ccc' : isSelected ? 'black' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        width: '100%',

        ':active': {
          ...styles[':active'],
          backgroundColor: noPaint
            ? 'transparent'
            : !isDisabled && (isSelected ? '#683AB7' : '#683AB7'),
        },
      };
    },
    control: (base) => ({
      ...base,
      display: 'none',
      boxShadow: 'none',
      border: '1px solid #ccc',
      '&:hover': { borderColor: '#61933a' },
      '&:active': { borderColor: '#61933a' },
    }),
    menu: (base) => ({
      ...base,
      width: 'max-content',
    }),
    input: (styles) => ({ ...styles, height: '40px' }),
    placeholder: (styles) => ({ ...styles }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      width: 'max-content',
    }),
  };

  return (
    <Wrapper>
      {isLoading ? (
        <Menu>
          <Spinner />
        </Menu>
      ) : (
        <Select
          value={value}
          autoFocus
          menuIsOpen
          menuPortalTarget={menuPortalTarget}
          isMulti={!isOneOption}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          id={id}
          onChange={onChange}
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          options={data}
          styles={colourStyles}
          placeholder=""
          components={{ Option: IconOption }}
        />
      )}
    </Wrapper>
  );
};

const Menu = styled.div`
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  max-width: 280px;
  padding: 15px;
  position: absolute;
  .select__menu {
    width: max-content;
  }
`;

const Wrapper = styled.div`
  & > div {
    width: 100px;
  }
  .select__menu {
    max-height: 300px;
    overflow: scroll;
  }
  .select__menu-list {
    max-height: 300px;
  }
`;

export default StyledMultiSelect;
