import React from 'react';
import styled from 'styled-components';
import { getInitials } from '../../utils/initials';
import { msToHMin } from '../../utils/msToTime';
import { formatTimeLowerCase } from '../../utils/formatDate';

function ShiftBlock(props) {
  const { day } = props;
  const time =
    day.startDate && day.endDate
      ? new Date(day.endDate).getTime() - new Date(day.startDate).getTime()
      : null;

  const progressWidth = time ? time / 360000 : null;
  return (
    <Wrap>
      <LeftSection>
        <Avatar color={day.users?.color}>
          {getInitials({
            name: day.users?.name + ' ' + day.users?.lastName,
          })}
        </Avatar>
        <FullName>{day.users?.name + ' ' + day.users?.lastName}</FullName>
      </LeftSection>
      <Center>{time ? <ProgressLine theme={progressWidth} /> : null}</Center>
      <RightSection>
        <div>
          {time ? <HoursWorked>{msToHMin(time)}</HoursWorked> : null}
          <StartStop>
            {formatTimeLowerCase(day.startDate)} -{' '}
            {!day.endDate ? 'in work' : formatTimeLowerCase(day.endDate)}
          </StartStop>
        </div>
      </RightSection>
    </Wrap>
  );
}

const Wrap = styled.div`
  min-height: 56px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  padding: 0 20px;
`;

const LeftSection = styled.div`
  flex: 1;
  min-width: 200px;
  display: flex;
  align-items: center;
`;
const Center = styled.div`
  flex: 4;
  display: flex;
  align-items: center;
`;
const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const Avatar = styled.div`
  width: 21px;
  height: 20px;
  background: ${({ color }) => (color ? color : '#683ab7')};
  color: #fff;
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  border: ${({ color }) =>
    color ? `1px solid ${color}` : '1px solid #683ab7'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;
const FullName = styled.div`
  font-weight: 500;
  font-size: 16px;
`;
const HoursWorked = styled.div`
  font-weight: 600;
  text-align: end;
`;

const StartStop = styled.div`
  color: var(--grey-text);
`;

const ProgressLine = styled.div`
  height: 10px;
  width: ${(props) => props.theme}%;
  background: rgba(97, 147, 58, 0.5);
  border-radius: 50px;
`;
export default ShiftBlock;
