import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import ResetPopup from '../../components/Popups/ResetPopup';
// import LocationsTab from './LocationsTab';
// import validateEmail from '../../utils/validateEmail';
import ExistsPopup from '../../components/Popups/ExistsPopup';
import { getResponseErrors } from '../../utils/response';
import { getErrorsMessageUser } from '../../utils/template';
import StyledAlertMessage from '../../components/StyledAlertMessage';
import { ReactSVG } from 'react-svg';
import EditImg from '../../assets/svgs/edit.svg';
import DeleteImg from '../../assets/svgs/delete.svg';
import WorkHistory from './WorkHistory';
import {
  getFirstDayOnMonth,
  getLastDayOnMonth,
} from '../../utils/getFirstLastDay';
import { getInitials } from '../../utils/initials';
import { ROLE_SUPERVISOR } from '../../utils/users';
import LocationItem from './LocationItem';
import AddWorkerPopup from '../../components/Popups/AddWorkerPopup';
import DeleteWorkerPopup from '../../components/Popups/DeleteWorkerPopup';

function deleteEmptyProperties(obj) {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
}

export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class WorkerPage extends React.Component {
  state = {
    popup: false,
    popupExists: false,
    popupEditUser: false,
    locationItems: [],
    formValues: {},
    save: false,
    isOpenWarning: false,
    errorsForm: {},
    showAll: true,
    currentDate: new Date(),
    hidden: 1,
    openDeletePopup: false,
  };

  componentDidMount() {
    const {
      getUserById,
      getAllLocations,
      getJobsByGroup,
      getActiveJob,
      match: { params },
    } = this.props;
    document.body.classList.remove('with-nav');

    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    getActiveJob(params.id);

    getJobsByGroup(
      getFirstDayOnMonth(month, year),
      getLastDayOnMonth(month, year),
      params.id,
    );

    if (params.id) {
      getUserById(params.id);
    }
    getAllLocations();
    this.myRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      userItem,
      isUserDeleted,
      isUserUpdated,
      history,
      isAssignedLocation,
      getUserById,
      match: { params },
    } = this.props;
    if (!prevProps.isUserDeleted && isUserDeleted) {
      history.goBack();
    }
    if (!prevProps.isAssignedLocation && isAssignedLocation) {
      getUserById(params.id);
    }
    if (!prevProps.isUserUpdated && isUserUpdated) {
      this.setState({ popupEditUser: false });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.msg === 'USER IS ARCHIVED'
    ) {
      this.setState({ popup: true });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.msg === 'USER IS EXIST'
    ) {
      this.setState({ popupExists: true });
    }

    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.errors?.length
    ) {
      const errors = getResponseErrors(this.props.error);
      this.setState({ isLoading: false });
      StyledAlertMessage.error(getErrorsMessageUser(errors));
    }

    if (prevProps.userItem !== userItem && userItem && !userItem.msg) {
      this.setState({
        locationItems: userItem.locations,
        showAll: userItem.seesAllLocations,
      });
    }
  }

  handleLocation = (locations) => {
    const { listAllLocations } = this.props;
    const prevOrder = this.state.locationItems.map(({ id }) => id);

    this.setState(
      {
        locationItems: listAllLocations
          .filter(({ id }) => locations.includes(id))
          .sort(function (a, b) {
            return prevOrder.indexOf(a.id) - prevOrder.indexOf(b.id);
          }),
      },
      () => this.saveLocationItems(),
    );
  };

  handleShowAll = (event) => {
    this.setState(
      {
        showAll: event.target.checked,
      },
      () => this.saveUser(),
    );
  };

  removeLocation = (idDel) => {
    const locations = [...this.state.locationItems];
    const index = locations.findIndex((location) => location.id === idDel);
    locations.splice(index, 1);
    this.setState({ locationItems: locations }, () => {
      this.myRef.current.handleDeleteById(idDel);
      this.saveLocationItems();
    });
  };

  handlePopup = () => {
    this.setState({ popup: !this.state.popup });
  };

  handlePopupExists = () => {
    this.setState({ popupExists: !this.state.popupExists });
  };

  handleOpenDeletePopup = () => {
    this.setState({ openDeletePopup: !this.state.openDeletePopup });
  };

  componentWillUnmount() {
    this.props.cleanUser();
  }

  handleWarningPopup = () => {
    this.setState((prevState) => ({
      isOpenWarning: !prevState.isOpenWarning,
    }));
  };

  handlePopupEditUser = () => {
    this.setState(({ popupEditUser }) => ({ popupEditUser: !popupEditUser }));
  };

  handleSubmit = (values) => {
    this.setState((state) => ({
      formValues: { ...state.formValues, ...values },
    }));

    this.saveUser();
  };

  saveUser = () => {
    const { userItem, updateUser } = this.props;
    const { formValues, isOpenWarning, showAll } = this.state;
    if (!isOpenWarning) {
      updateUser({
        ...deleteEmptyProperties(formValues),
        id: userItem.userId ? userItem.userId : userItem.id,
        seesAllLocations: showAll,
      });
    } else {
      this.setState({ isOpenWarning: false });
    }
    this.setState({ save: false });
  };

  handleRemoveError = (name) => {
    this.setState({
      errorsForm: {
        ...this.state.errorsForm,
        [name]: null,
      },
    });
  };

  onDragEnd = (result) => {
    const { locationItems } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === 'QUESTIONS') {
      const locationItemsNew = Reorder(
        locationItems,
        result.source.index,
        result.destination.index,
      );

      this.setState(
        {
          locationItems: locationItemsNew,
        },
        () => this.saveLocationItems(),
      );
    }
  };

  getLocationItems = () => {
    return this.state.locationItems;
  };

  saveLocationItems = () => {
    const { assignLocation, userItem } = this.props;
    const locationItems = this.getLocationItems();

    assignLocation({
      locationsIds: locationItems.map(({ id }) => id),
      userId: userItem.userId ? +userItem.userId : +userItem.id,
    });
  };

  handleChangeMonth = (plus) => {
    const {
      getJobsByGroup,
      match: { params },
    } = this.props;
    const { currentDate } = this.state;
    const tempCurrentDate = currentDate;
    if (plus) {
      tempCurrentDate.setMonth(tempCurrentDate.getMonth() - 1);

      this.setState({
        currentDate: tempCurrentDate,
      });
    } else {
      tempCurrentDate.setMonth(tempCurrentDate.getMonth() + 1);

      this.setState({
        currentDate: tempCurrentDate,
      });
    }

    getJobsByGroup(
      getFirstDayOnMonth(
        tempCurrentDate.getMonth(),
        tempCurrentDate.getFullYear(),
      ),
      getLastDayOnMonth(
        tempCurrentDate.getMonth(),
        tempCurrentDate.getFullYear(),
      ),
      params.id,
      'all',
      'all',
    );
  };
  onDeleteUser = () => {
    const {
      deleteUsers,
      match: { params },
    } = this.props;

    deleteUsers(params.id);
  };
  handleChangeHidden = (hidden) => {
    this.setState({ hidden: hidden });
  };

  render() {
    const {
      userItem,
      restoreUser,
      error,
      listAllLocations,
      jobsByGroup,
      jobsWithOutWorkingDayGroup,
      activeJob,
      isLoadingHistory,
    } = this.props;
    const {
      popup,
      hidden,
      popupExists,
      showAll,
      currentDate,
      popupEditUser,
      openDeletePopup,
    } = this.state;

    return (
      <Wrapper>
        <div className="title-bar">
          {userItem && (
            <div className="worker-name">
              <div
                className="large-avatar"
                style={{ background: userItem.color }}
              >
                {getInitials({
                  name: userItem.name + ' ' + userItem.lastName,
                })}
              </div>
              <div className="name">
                <div>
                  <h2>{userItem.name + ' ' + userItem.lastName}</h2>
                  {userItem.role === ROLE_SUPERVISOR && (
                    <div className="tag">Supervisor</div>
                  )}
                </div>
                <span>{userItem.companyName}</span>
                {userItem?.workingDays?.length > 0 && (
                  <div className="status">Clocked In</div>
                )}
              </div>
            </div>
          )}
          <div className="options">
            <button
              id="edit-location"
              className="btn-icon"
              onClick={this.handlePopupEditUser}
            >
              <ReactSVG src={EditImg} />
            </button>
            <button
              id="delete-location"
              className="btn-icon delete"
              onClick={this.handleOpenDeletePopup}
            >
              <ReactSVG src={DeleteImg} />
            </button>
          </div>
        </div>

        <div className="container">
          <div className="tabs hidden-desktop">
            <div
              id="details-tab"
              className={clsx('tab', {
                active: hidden === 1,
              })}
              onClick={() => this.handleChangeHidden(1)}
            >
              Details
            </div>
            <div
              id="history-tab"
              className={clsx('tab', {
                active: hidden === 2,
              })}
              onClick={() => this.handleChangeHidden(2)}
            >
              Work History
            </div>
          </div>
          <div className="col-row">
            {isLoadingHistory ? (
              <Spinner />
            ) : (
              <WorkHistory
                hidden={hidden}
                jobsByGroup={jobsByGroup}
                jobsWithOutWorkingDayGroup={jobsWithOutWorkingDayGroup}
                currentDate={currentDate}
                handleChangeMonth={this.handleChangeMonth}
              />
            )}
            <div
              className={clsx('sidebar', {
                ['hidden-mobile']: hidden === 2,
              })}
            >
              <div className="card">
                <div className="card-title">Profile Details</div>
                {userItem && (
                  <div className="worker-info">
                    <p>
                      {userItem.address}
                      {userItem.city ? ',' : ''} {userItem.city}
                      {userItem.province ? ',' : ''} {userItem.province}
                      {userItem.postalCode}
                    </p>
                    <p>{userItem.email}</p>
                    <p>{userItem.phone}</p>
                  </div>
                )}
              </div>

              <LocationItem
                activeJob={activeJob}
                onDragEnd={this.onDragEnd}
                getLocationItems={this.getLocationItems}
                showAll={showAll}
                handleShowAll={this.handleShowAll}
                myRef={this.myRef}
                removeLocation={this.removeLocation}
                handleLocation={this.handleLocation}
                listAllLocations={listAllLocations}
                assignedLocations={userItem ? userItem.locations : []}
              />
            </div>
          </div>
        </div>
        {popup && (
          <ResetPopup
            error={error}
            createNew={this.handleSubmitMyForm}
            restoreUser={restoreUser}
            onClose={this.handlePopup}
          />
        )}

        {popupExists && <ExistsPopup onClose={this.handlePopupExists} />}

        {popupEditUser && (
          <AddWorkerPopup
            userItem={userItem}
            onClose={this.handlePopupEditUser}
            handleSubmit={this.handleSubmit}
          />
        )}

        {openDeletePopup && (
          <DeleteWorkerPopup
            onDeleteUser={this.onDeleteUser}
            onClose={this.handleOpenDeletePopup}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  h2 {
    font-size: 24px;
    margin: 0 0 5px;
    font-family: 'Roboto', sans-serif;
  }
  .options {
    display: flex;
  }
  .options button:not(:first-child) {
    margin-left: 10px;
  }
  p.heading,
  p.heading span {
    max-width: calc(100% - 30px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    width: 100%;
    color: var(--grey-text);
    width: calc(100% - 100px);
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.heading span {
    max-width: 100%;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 3px;
    color: var(--black-text);
  }
  .worker-info p:last-child {
    margin-bottom: 0;
  }
  .card-list p.heading {
    margin: 0 0 10px 0;
  }
  .locations-list .location:not(:last-child) {
    margin-bottom: 20px;
  }
  .location {
    display: flex;
    align-items: center;
    position: relative;
  }
  .location.active p,
  .location.active img {
    z-index: 1;
  }
  .location.active::after {
    content: '';
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    left: -10px;
    border-radius: 8px;
    background: #f7f7f7;
    position: absolute;
    z-index: 0;
  }
  .location.active .timer {
    font-size: 16px;
    color: var(--secondary-color);
    position: flex;
    align-items: center;
    z-index: 1;
    margin-left: auto;
    font-weight: 500;
  }

  .worker-name {
    display: flex;
    align-items: center;
  }
  .worker-name .name > div {
    display: flex;
    align-items: center;
  }
  .worker-name .name .tag {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 30px;
    color: #fff;
    background: var(--secondary-color);
    height: fit-content;
    padding: 2px 5px;
    position: relative;
    top: -2px;
    left: 20px;
  }
  .worker-name .status {
    color: #7dce3d;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 30px;
    background: #fff;
    padding: 5px 7px 5px 5px;
    margin-top: 10px;
    width: fit-content;
    box-shadow: var(--box-shadow);
  }
  .worker-name .status::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background: #7dce3d;
    margin-right: 5px;
  }
  .large-avatar {
    width: 100px;
    height: 100px;
    border-radius: 120px;
    color: #fff;
    margin-right: 20px;
    font-weight: 300;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card {
    padding: 20px;
    margin-bottom: 30px;
  }
  .card .date-select {
    background: #f7f7f7;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--grey-text);
    font-weight: 600;
  }
  .card .date-select a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #ccc;
  }
  .card .date-select a:hover {
    border-color: #777;
  }
  .card .date-select a svg {
    position: relative;
    top: 0;
    left: 10px;
  }
  .card .date-select span {
    margin: 0 20px;
  }
  .card-list .card {
    align-items: flex-start;
  }
  .card .card {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #f1f1f1;
    display: flex;
  }
  .card .card > div {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    flex-direction: column;
  }
  .card .card > div:nth-child(2) {
    align-items: flex-end;
  }
  .card .tasks-completed {
    display: flex;
    align-items: center;
  }
  .card .tasks-completed > * {
    margin-right: 5px;
  }
  .card .tag {
    display: inline-block;
    padding: 2px 5px;
    background: var(--tag-color);
    border-radius: 3px;
    margin-right: 10px;
  }
  .card .hours-worked {
    font-weight: 600;
    margin: 2px 0 5px;
  }
  .card .start-stop-time {
    color: var(--grey-text);
  }
  .date {
    font-size: 18px;
    color: var(--grey-text);
    margin: 30px 0 20px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .date .hours-worked {
    font-weight: 400;
    font-size: 16px;
  }
  .date .hours-worked span {
    font-weight: 600;
    color: var(--black-text);
    margin-right: 20px;
  }

  .task-list > div {
    display: flex;
    margin-bottom: 20px;
  }
  .task-list > div:last-child {
    margin: 0;
  }
  .task-list .category {
    font-weight: 700;
    margin-right: 10px;
    min-width: 100px;
  }

  @media (min-width: 960px) {
    .col-row {
      display: flex;
      justify-content: space-between;
    }
    .main-col {
      width: calc(100% - 420px);
    }
    .sidebar {
      width: 390px;
    }
  }
  @media (min-width: 414px) {
    .image-row .image {
      height: 104px;
    }
  }
`;

export default withRouter(WorkerPage);
